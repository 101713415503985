import React from 'react'
import { Link, useLocation } from "react-router-dom";



import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";


const MasterAdmin = () => {

  const [activeTab, setActiveTab] = React.useState(1);

  const onTabClick = (e, index) => {
    console.log(e);
    setActiveTab(index);
  };



  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title">
                    <Link to="/users_list" className="page-header-icon">
                      <i className="fa fa-arrow-left" ></i>
                    </Link>
                    <div className="hd_u_info" >
                      <img
                        src="assets/img/illustrations/profiles/profile-5.png"
                        alt=""
                        className="img-account-profile rounded-circle "
                      />
                      <span> Pardeep Yadav <small className="text-dark" > Master Admin </small> </span>
                    </div>
                  </h1>
                </div>
                <div className="col-auto">
                  <button className="btn btn-danger px-4 btn-md ">  Logout </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container-xl px-4" >
          <div className="card" >
            <div className="card-header pb-0 ps-0">
              <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                <Tab className="nav-link" > Account Details  </Tab>
                <Tab className="nav-link" > Payment Details </Tab>
                <Tab className="nav-link" > Features Management </Tab>
                <Tab className="nav-link" >   Wallets </Tab>
                <Tab className="nav-link" > Transaction History </Tab>
                <Tab className="nav-link" > Support Tickets (L3)</Tab>
                <Tab className="nav-link" > Activity Logs </Tab>
              </Tabs>

            </div>

            <div className="tab-content tab-content-card " id="cardPillContent">

              {/* personal Information */}
              <div className="tab-pane " id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                <div className="card-body py-4" >
                  <div className="profile_data  ac_body " >
                    <h5>  Account  Details </h5>
                    <div className="row g-4">
                      <div className="col-lg-6 col-xl-3">
                        <div className="label-group"><label> User ID   </label><p>   ------ </p></div>
                      </div>
                      <div className="col-lg-6 col-xl-3">
                        <div className="label-group"><label>   Full Name   </label><p>  ------ </p></div>
                      </div>
                      <div className="col-lg-6 col-xl-3">
                        <div className="label-group"><label>    Email Address </label><p>  ------ </p></div>
                      </div>
                      <div className="col-lg-6 col-xl-3">
                        <div className="label-group"><label>    Mobile Number     </label><p>  ------ </p></div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* payment details   */}
              <div className="tab-pane   " id="paymentPill" role="tabpanel" aria-labelledby="payment-pill">
                <div className="card-body py-4" >

                  <div className="profile_data  ac_body  " >
                    <div className=" " >
                      <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a href="#bankaccounts" id="bankaccounts-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Bank Accounts  </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a href="#UPIids" id="UPIids-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> UPI Id's </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-content">

                      {/* crypto Tab  */}
                      <div className="tab-pane active" id="bankaccounts" role="tabpanel" aria-labelledby="bankaccounts-tab">
                        <div className=" " >
                          <div className="profile_data  ac_body" >
                            <div className='d-flex mb-2 align-items-center justify-content-between' >
                              <h5 className="mb-0" >  Bank Accounts </h5>
                              <button className='btn btn-md btn-dark' >Add New Account</button>
                            </div>
                            <ul className="nav custom-tabs mb-4">
                              <li className="buysell-tab">
                                <a className="active">  Acctive Accounts   </a>
                              </li>
                              <li className="buysell-tab">
                                <a className=""> Deleted Accounts </a>
                              </li>
                            </ul>


                            <div className='fc_row'>
                              <div className="fc_details mb-3" >
                                <div className="d-flex gap-3 align-items-start justify-content-between mb-2" >
                                  <h5 className="mb-0" >   Bank Of Baroda-2514    </h5>
                                  <div className='d-flex gap-0 align-items-center justify-content-end ' >
                                    <span className='badge bg-dark' > Default</span>
                                    <div className="dropdown">
                                      <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                        <i className="ri-more-2-fill"></i>
                                      </button>
                                      <div className="dropdown-menu"  >
                                        <a className="dropdown-item" href="#"> Delete Account </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-1" >
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Account Holder Name   </label>
                                      <p>  ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> Account Number </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>    Account Type </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Branch Name </label>
                                      <p> ---- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Added On   </label>
                                      <p>  12 Nov 2024  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> IFSC Code    </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="fc_details mb-3" >
                                <div className="d-flex gap-3 align-items-center justify-content-between mb-2" >
                                  <h5 className="mb-0" >  Bank Of Baroda-2514 </h5>
                                  <div className='d-flex gap-0 align-items-center justify-content-end ' >
                                    <div className="dropdown">
                                      <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                        <i className="ri-more-2-fill"></i>
                                      </button>
                                      <div className="dropdown-menu"  >
                                        <a className="dropdown-item" href="#">  Set as Default </a>
                                        <a className="dropdown-item" href="#"> Delete Account </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-1" >
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Account Holder Name   </label>
                                      <p>  ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> Account Number </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>    Account Type </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Branch Name </label>
                                      <p> ---- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Added On   </label>
                                      <p>  12 Nov 2024  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> IFSC Code    </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="fc_details mb-3" >
                                <div className="d-flex gap-3 align-items-center justify-content-between mb-2" >
                                  <h5 className="mb-0" >  Bank Of Baroda-2514 </h5>
                                  <div className='d-flex gap-0 align-items-center justify-content-end ' >
                                    <div className="dropdown">
                                      <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                        <i className="ri-more-2-fill"></i>
                                      </button>
                                      <div className="dropdown-menu"  >
                                        <a className="dropdown-item" href="#">  Set as Default </a>
                                        <a className="dropdown-item" href="#"> Delete Account </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-1" >
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Account Holder Name   </label>
                                      <p>  ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> Account Number </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>    Account Type </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Branch Name </label>
                                      <p> ---- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Added On   </label>
                                      <p>  12 Nov 2024  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> IFSC Code    </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="fc_details mb-3" >
                                <div className="d-flex gap-3 align-items-center justify-content-between mb-2" >
                                  <h5 className="mb-0" >  Bank Of Baroda-2514 </h5>
                                  <div className='d-flex gap-0 align-items-center justify-content-end ' >
                                    <div className="dropdown">
                                      <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                        <i className="ri-more-2-fill"></i>
                                      </button>
                                      <div className="dropdown-menu"  >
                                        <a className="dropdown-item" href="#">  Set as Default </a>
                                        <a className="dropdown-item" href="#"> Delete Account </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-1" >
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Account Holder Name   </label>
                                      <p>  ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> Account Number </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>    Account Type </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Branch Name </label>
                                      <p> ---- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label>  Added On   </label>
                                      <p>  12 Nov 2024  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-2" >
                                    <div className="label-group" >
                                      <label> IFSC Code    </label>
                                      <p> ----  </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      {/* crypto Tab  */}
                      <div className="tab-pane" id="UPIids" role="tabpanel" aria-labelledby="UPIids-tab">
                        <div className="profile_data  ac_body" >
                          <div className='d-flex mb-2 align-items-center justify-content-between' >
                            <h5 className="mb-0" >  UPI ID </h5>
                            <button className='btn btn-md btn-dark' >Add New UPI </button>
                          </div>
                          <ul className="nav custom-tabs mb-4">
                            <li className="buysell-tab">
                              <a className="active">  Acctive UPI's   </a>
                            </li>
                            <li className="buysell-tab">
                              <a className=""> Deleted UPI's </a>
                            </li>
                          </ul>
                          <div className='fc_row row g-3 '>
                            <div className='col-lg-12' >
                              <div className="fc_details p-3 " >
                                <div className="row g-1 align-items-center" >
                                  <div className="col-lg-4" >
                                    <div className="label-group" >
                                      <label> UPI Name  </label>
                                      <p>  ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-4" >
                                    <div className="label-group" >
                                      <label> UPI ID </label>
                                      <p> 654654654654@okicic  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-3" >
                                    <div className="label-group" >
                                      <label>  Added on </label>
                                      <p> 12 Nov 2024  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-1">
                                    <div className='d-flex gap-0 align-items-center justify-content-end ' >
                                      <span className='badge bg-dark' > Default</span>
                                      <div className="dropdown">
                                        <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                          <i className="ri-more-2-fill"></i>
                                        </button>
                                        <div className="dropdown-menu"  >
                                          <a className="dropdown-item" href="#"> Delete  UPI </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className='col-lg-12' >
                              <div className="fc_details p-3 " >
                                <div className="row g-1 align-items-center" >
                                  <div className="col-lg-4" >
                                    <div className="label-group" >
                                      <label> UPI Name  </label>
                                      <p>  ----  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-4" >
                                    <div className="label-group" >
                                      <label> UPI ID </label>
                                      <p> 654654654654@okicic  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-3" >
                                    <div className="label-group" >
                                      <label>  Added on </label>
                                      <p> 12 Nov 2024  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-1">
                                    <div className='d-flex gap-0 align-items-center justify-content-end ' >
                                      <span className='badge bg-dark' > Default</span>
                                      <div className="dropdown">
                                        <button type="button" className="btn btn-blank p-1" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                          <i className="ri-more-2-fill"></i>
                                        </button>
                                        <div className="dropdown-menu"  >
                                          <a className="dropdown-item" href="#"> Delete  UPI </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              {/* Feature managment details   */}
              <div className="tab-pane  " id="Featuremanagment" role="tabpanel" aria-labelledby="Featuremanagment-pill">
                <div className="card-body py-4" >

                  <div className="profile_data " >

                    <div className="profile_data  ac_body" >
                      <div className="d-flex justify-content-between" >
                        <h5> Features Management  </h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                              <div>
                                <h5>  Spot  Trading </h5>
                                <p> Revoke user side  Spot Trading freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                              <div>
                                <h5>  Quick Buy Sell </h5>
                                <p> Buy or Sell Your Crypto with INR Directly </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-p2p-fill"></i></div>
                              <div>
                                <h5> P2P Trading  </h5>
                                <p> Revoke user side  P2P Trading freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-arrow-up-line"></i></div>
                              <div>
                                <h5> Deposit  Funds</h5>
                                <p> Revoke user side Deposit freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" >
                          <div className="per_col mb-3" >
                            <div className="d-flex align-items-center " >
                              <div className="pr_icon" ><i className="ri-arrow-down-line"></i></div>
                              <div>
                                <h5> Withdraw Funds </h5>
                                <p> Revoke user side Withdraw freture. </p>
                              </div>
                            </div>
                            <label className="switch">
                              <input type="checkbox" name="" id="forBuySell" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="profile_data  ac_body my-4 " >
                      <div className="d-flex justify-content-between" >
                        <h5> Management History </h5>
                      </div>
                      <div className="table-responsive m_table">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className=""> Date/Time </th>
                              <th scope="col" className=""> Features  Type </th>
                              <th scope="col" className=""> Updated Status </th>
                              <th scope="col" className=""> Remark </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="">
                                <span> 11-12-2024 </span>
                              </td>
                              <td className="">
                                <span> ----  </span>
                              </td>
                              <td className="">
                                <span> ----  </span>
                              </td>
                              <td className="">
                                <span> Message here......... </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>


                    </div>

                  </div>

                </div>
              </div>

              {/* walletdetails*/}
              <div className="tab-pane " id="walletdetails" role="tabpanel" aria-labelledby="walletdetails">
                <div className="card-body py-4">

                  <div className="profile_data  ac_body" >
                    <div className="">
                      <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a href="#MasterWallet" id="MasterWallet-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Master Wallet  </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a href="#UserWallets" id="UserWallets-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  User Wallets  </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-content">

                      {/* Master Wallet Tab  */}
                      <div className="tab-pane active" id="MasterWallet" role="tabpanel" aria-labelledby="MasterWallet-tab">
                        <h5> Master Wallet </h5>
                        <form className="row">
                          <div className="col-12" >
                            <div className="search-header align-items-start justify-content-between px-0 pt-0">
                              <div className="ow_row" >
                                <span>Estimated Balance</span>
                                <h5 className="" >0000.000 <small>INR</small></h5>
                                <p className="mb-0"> Locked Balance ≈ 0, &nbsp; Total Hold Balance ≈ 0, &nbsp; Total Available Balance ≈ 0 </p>
                              </div>
                              <div className='d-flex gap-2  align-items-start justify-content-between'>
                                <div className="dd_search">
                                  <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                  <i className="fas fa-search"></i>
                                </div>
                                <button className='btn btn-warning btn-md' >
                                  <b><i className="ri-add-line me-2"></i></b>  Add Funds
                                </button>
                                <button className='btn btn-success btn-md' >
                                  <b><i className="ri-arrow-up-long-line me-2"></i></b>  Withdraw
                                </button>
                                <div className="dropdown">
                                  <button className="btn btn-md btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <b><i className="ri-arrow-go-forward-line me-2"></i></b> Transfer
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#"> Self Transfer</a></li>
                                    <li><a className="dropdown-item" href="#"> User Transfer  </a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="table-responsive m_table">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Coin Name</th>
                                    <th scope="col" className="text-end">Available Balance</th>
                                    <th scope="col" className="text-end">Locked Balance</th>
                                    <th scope="col" className="text-end">Hold Balance</th>
                                    <th scope="col" className="text-end">Total Balance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="c_view">
                                        <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                        <span>USDT <br />
                                          <small>Tether</small>
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="c_view justify-content-end">
                                        <span>0.00000 <br />
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="c_view justify-content-end">
                                        <span>0.00000 <br />
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <span> 0.00000 </span>
                                    </td>
                                    <td className="text-end">
                                      <span> 0.00 </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="c_view">
                                        <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                        <span>INR <br />
                                          <small>INR</small>
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="c_view justify-content-end">
                                        <span>0.00000 <br />
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="c_view justify-content-end">
                                        <span>0.00000 <br />
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <span> 0.00000 </span>
                                    </td>
                                    <td className="text-end">
                                      <span> 0.00 </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </form>
                      </div>
                      {/* User Wallets Tab  */}
                      <div className="tab-pane" id="UserWallets" role="tabpanel" aria-labelledby="UserWallets-tab">
                        <h5> User Wallets </h5>
                        <div className="mb-4" >
                          <div className="row g-4">
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group ow_row">
                                <label>  Estimated Balance </label>
                                <h5 className=""> 0000.000 </h5>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group ow_row">
                                <label>     Total Locked Balance </label>
                                <h5 className=""> 0000.000 </h5>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group ow_row">
                                <label>     Total Hold Balance </label>
                                <h5 className=""> 0000.000 </h5>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group ow_row">
                                <label>     Total Available Balance </label>
                                <h5 className=""> 0000.000 </h5>
                              </div>
                            </div>
                          </div>
                        </div>

                        <ul className="nav custom-tabs mb-2">
                          <li className="buysell-tab">
                            <a className="active">  Overview</a>
                          </li>
                          <li className="buysell-tab">
                            <a className="">Funding</a>
                          </li>
                          <li className="buysell-tab">
                            <a className=""> Spot Wallet </a>
                          </li>
                          <li className="buysell-tab">
                            <a className=""> Future Wallet </a>
                          </li>
                        </ul>

                        <div>
                          <div className="filter_bar search-header justify-content-end px-0 g-2">
                            <div className="dd_search">
                              <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                              <i className="fas fa-search"></i>
                            </div>

                          </div>
                          <div className="table-responsive m_table">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Coin Name</th>
                                  <th scope="col" className="text-end">Available Balance</th>
                                  <th scope="col" className="text-end">Locked Balance</th>
                                  <th scope="col" className="text-end">Hold Balance</th>
                                  <th scope="col" className="text-end">Total Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="c_view">
                                      <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                      <span>USDT <br />
                                        <small>Tether</small>
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <div className="c_view justify-content-end">
                                      <span>0.00000 <br />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <div className="c_view justify-content-end">
                                      <span>0.00000 <br />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <span> 0.00000 </span>
                                  </td>
                                  <td className="text-end">
                                    <span> 0.00 </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="c_view">
                                      <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                      <span>INR <br />
                                        <small>INR</small>
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <div className="c_view justify-content-end">
                                      <span>0.00000 <br />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <div className="c_view justify-content-end">
                                      <span>0.00000 <br />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <span> 0.00000 </span>
                                  </td>
                                  <td className="text-end">
                                    <span> 0.00 </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>

              {/* Transaction History   */}
              <div className="tab-pane   " id="Transaction" role="tabpanel" aria-labelledby="Transaction-pill">
                <div className="card-body py-4">
                  <div className="profile_data  ac_body" >
                    <div className=" " >
                      <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a href="#DepositWithdrawals" id="DepositWithdrawals-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Deposit & Withdrawals  </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a href="#Transfer" id="Transfer-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Transfer History</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-content">

                      {/* crypto Tab  */}
                      <div className="tab-pane active" id="DepositWithdrawals" role="tabpanel" aria-labelledby="DepositWithdrawals-tab">
                        <div className=" " >
                          <div className="profile_data  ac_body d-flex align-items-center mb-3 justify-content-between" >
                            <h5 className="mb-0" >  Deposit & Withdrawals History  </h5>
                            <div className="custom-tab-2 custom-tab-curve">
                              <ul className="nav nav-pills justify-content-start " role="tablist">
                                <li className="nav-item" role="presentation">
                                  <a href="#Crypto" id="Crypto-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Crypto </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <a href="#INR" id="INR-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> INR </a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="tab-content">

                            {/* crypto Tab  */}
                            <div className="tab-pane active" id="Crypto" role="tabpanel" aria-labelledby="Crypto-tab">
                              <ul className="nav custom-tabs mb-0">
                                <li className="buysell-tab">
                                  <a className="active"> All   </a>
                                </li>
                                <li className="buysell-tab">
                                  <a className="">  Deposits </a>
                                </li>
                                <li className="buysell-tab">
                                  <a className="">  Withdrawals </a>
                                </li>
                              </ul>

                              <div className="filter_bar search-header px-0 g-2">
                                <form className="row align-items-center w-100 gx-2">
                                  <div className="col-2">
                                    <select className="form-control form-select cursor-pointer">
                                      <option value=""> Coins List </option>
                                      <option value="Completed"> All Coins </option>
                                      <option value="Pending"> Coin 1 </option>
                                      <option value="Pending"> Coin 2 </option>
                                    </select>
                                  </div>
                                  <div className="col-2">
                                    <select className="form-control form-select cursor-pointer">
                                      <option value=""> Order Status</option>
                                      <option value="Completed"> All  </option>
                                      <option value="Completed">Completed </option>
                                      <option value="Pending">Pending</option>
                                      <option value="Rejected">Rejected</option>
                                    </select>
                                  </div>
                                  <div className="col-2">
                                    <input
                                      type="date"
                                      className="form-control form-control-solid"
                                      data-provide="datepicker"
                                      id="litepickerRangePlugin"
                                      name="dateFrom"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      type="date"
                                      className="form-control form-control-solid"
                                      data-provide="datepicker"
                                      id="litepickerRangePlugin"
                                      name="dateTo"

                                    />
                                  </div>
                                  <div className="col-2">
                                    <div className="row align-items-center gx-2">
                                      <div className="col">
                                        <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                          Apply
                                        </button>
                                      </div>
                                      <div className="col">
                                        <button className="btn btn-light btn-block w-100" type="button" >
                                          <i className="ri-reset-left-line"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <label className="small text-dark mb-1" >  </label>
                                <div className="dd_search">
                                  <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                  <i className="fas fa-search"></i>
                                </div>

                              </div>
                              <div className="table-responsive m_table">
                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th scope="col" className=""> Date/Time </th>
                                      <th scope="col" className=""> Order No. </th>
                                      <th scope="col" className="">Currency </th>
                                      <th scope="col" className="">Amount </th>
                                      <th scope="col" className="">Chain </th>
                                      <th scope="col" className=""> Address </th>
                                      <th scope="col" className="">Fee </th>
                                      <th scope="col" className="">Txn Id </th>
                                      <th scope="col" className="">Completed Time </th>
                                      <th scope="col" className="">Action </th>
                                      <th scope="col" className="">Status </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>



                            </div>

                            {/* INR Tab  */}
                            <div className="tab-pane" id="INR" role="tabpanel" aria-labelledby="INR-tab">
                              <ul className="nav custom-tabs mb-0">
                                <li className="buysell-tab">
                                  <a className="active"> All   </a>
                                </li>
                                <li className="buysell-tab">
                                  <a className="">  Deposits </a>
                                </li>
                                <li className="buysell-tab">
                                  <a className="">  Withdrawals </a>
                                </li>
                              </ul>
                              <div className="filter_bar search-header px-0 g-2">
                                <form className="row align-items-center w-100 gx-2">
                                  <div className="col-2">
                                    <select className="form-control form-select cursor-pointer">
                                      <option value=""> Order Status</option>
                                      <option value="Completed"> All  </option>
                                      <option value="Completed">Completed </option>
                                      <option value="Pending">Pending</option>
                                      <option value="Rejected">Rejected</option>
                                    </select>
                                  </div>
                                  <div className="col-2">
                                    <input
                                      type="date"
                                      className="form-control form-control-solid"
                                      data-provide="datepicker"
                                      id="litepickerRangePlugin"
                                      name="dateFrom"
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      type="date"
                                      className="form-control form-control-solid"
                                      data-provide="datepicker"
                                      id="litepickerRangePlugin"
                                      name="dateTo"

                                    />
                                  </div>
                                  <div className="col-2">
                                    <div className="row align-items-center gx-2">
                                      <div className="col">
                                        <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                          Apply
                                        </button>
                                      </div>
                                      <div className="col">
                                        <button className="btn btn-light btn-block w-100" type="button" >
                                          <i className="ri-reset-left-line"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <label className="small text-dark mb-1" >  </label>
                                <div className="dd_search">
                                  <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                  <i className="fas fa-search"></i>
                                </div>
                              </div>
                              <div className="table-responsive m_table">
                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th scope="col" className=""> Date/Time </th>
                                      <th scope="col" className=""> Order No. </th>
                                      <th scope="col" className="">Currency </th>
                                      <th scope="col" className=""> Payment Method </th>
                                      <th scope="col" className="">Amount </th>
                                      <th scope="col" className="">Fee </th>
                                      <th scope="col" className="">  Bank Details   </th>
                                      <th scope="col" className="">UTR No. </th>
                                      <th scope="col" className="">Completed Time </th>
                                      <th scope="col" className="">Action </th>
                                      <th scope="col" className="">Status </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                      <td className="">
                                        <span> ---- </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>



                            </div>


                          </div>

                        </div>
                      </div>

                      {/* crypto Tab  */}
                      <div className="tab-pane" id="Transfer" role="tabpanel" aria-labelledby="Transfer-tab">
                        <div className=" " >
                          <div className="profile_data  ac_body" >
                            <h5 className="" >  Transfer History </h5>
                          </div>

                          {/* crypto Tab  */}
                          <div>
                            <div className="filter_bar search-header px-0 g-2">
                              <form className="row align-items-center w-100 gx-2">
                                <div className="col-2">
                                  <select className="form-control form-select cursor-pointer">
                                    <option hidden> Select Coin </option>
                                    <option value="Completed"> All Coins </option>
                                    <option value="Pending"> Coin 1 </option>
                                    <option value="Pending"> Coin 2 </option>
                                  </select>
                                </div>
                                <div className="col-2">
                                  <select className="form-control form-select cursor-pointer">
                                    <option hidden > Select From  </option>
                                    <option value="Rejected"> Funding Wallet </option>
                                    <option value="Completed"> Spot Wallet </option>
                                    <option value="Pending"> Future Wallet </option>
                                  </select>
                                </div>
                                <div className="col-2">
                                  <select className="form-control form-select cursor-pointer">
                                    <option hidden > Select  To  </option>
                                    <option value="Rejected"> Funding Wallet </option>
                                    <option value="Completed"> Spot Wallet </option>
                                    <option value="Pending"> Future Wallet </option>
                                  </select>
                                </div>
                                <div className="col-2">
                                  <input
                                    type="date"
                                    className="form-control form-control-solid"
                                    data-provide="datepicker"
                                    id="litepickerRangePlugin"
                                    name="dateFrom"
                                    placeholder="Date"
                                  />
                                </div>
                                <div className="col-2">
                                  <input
                                    type="date"
                                    className="form-control form-control-solid"
                                    data-provide="datepicker"
                                    id="litepickerRangePlugin"
                                    name="dateTo"

                                  />
                                </div>
                                <div className="col-2">
                                  <div className="row align-items-center gx-2">
                                    <div className="col">
                                      <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                        Apply
                                      </button>
                                    </div>
                                    <div className="col">
                                      <button className="btn btn-light btn-block w-100" type="button" >
                                        <i className="ri-reset-left-line"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <label className="small text-dark mb-1" >  </label>
                              <div className="dd_search">
                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                <i className="fas fa-search"></i>
                              </div>

                            </div>
                            <div className="table-responsive m_table">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col" className=""> Date/Time </th>
                                    <th scope="col" className=""> Coin </th>
                                    <th scope="col" className="">Amount </th>
                                    <th scope="col" className="">From/To </th>
                                    <th scope="col" className="">Fee </th>
                                    <th scope="col" className="">Status </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>

                      {/* crypto Tab  */}
                      <div className="tab-pane" id="Pay" role="tabpanel" aria-labelledby="Pay-tab">
                        <div className=" " >
                          <div className="profile_data  ac_body" >
                            <h5>  Pay History </h5>
                            <ul className="nav custom-tabs mb-0">
                              <li className="buysell-tab">
                                <a className="active">   All  </a>
                              </li>
                              <li className="buysell-tab">
                                <a className="">  Paid   </a>
                              </li>
                              <li className="buysell-tab">
                                <a className="">  Received </a>
                              </li>
                            </ul>
                          </div>

                          {/* crypto Tab  */}
                          <div>
                            <div className="filter_bar search-header px-0 g-2">
                              <form className="row align-items-center w-100 gx-2">
                                <div className="col-2">
                                  <select className="form-control form-select cursor-pointer">
                                    <option hidden> Select Coin </option>
                                    <option value="Completed"> All Coins </option>
                                    <option value="Pending"> Coin 1 </option>
                                    <option value="Pending"> Coin 2 </option>
                                  </select>
                                </div>
                                <div className="col-2">
                                  <input
                                    type="date"
                                    className="form-control form-control-solid"
                                    data-provide="datepicker"
                                    id="litepickerRangePlugin"
                                    name="dateFrom"
                                    placeholder="Date"
                                  />
                                </div>
                                <div className="col-2">
                                  <input
                                    type="date"
                                    className="form-control form-control-solid"
                                    data-provide="datepicker"
                                    id="litepickerRangePlugin"
                                    name="dateTo"

                                  />
                                </div>
                                <div className="col-2">
                                  <div className="row align-items-center gx-2">
                                    <div className="col">
                                      <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                        Apply
                                      </button>
                                    </div>
                                    <div className="col">
                                      <button className="btn btn-light btn-block w-100" type="button" >
                                        <i className="ri-reset-left-line"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <label className="small text-dark mb-1" >  </label>
                              <div className="dd_search">
                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                <i className="fas fa-search"></i>
                              </div>

                            </div>
                            <div className="table-responsive m_table">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col" className=""> Date/Time </th>
                                    <th scope="col" className=""> Coin </th>
                                    <th scope="col" className=""> Type </th>
                                    <th scope="col" className="">Amount </th>
                                    <th scope="col" className="">From/To </th>
                                    <th scope="col" className="">Fee </th>
                                    <th scope="col" className="">Status </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                    <td className="">
                                      <span> ---- </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>





                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div> 

              {/* User support   */}
              <div className="tab-pane active" id="Usersupport" role="tabpanel" aria-labelledby="Usersupport-pill">
                <div className="card-body py-4" >
                  <div className="profile_data  ac_body  " >
                    <div className=" " >
                      <div className="custom-tab-2">
                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a href="#OpenTickets" id="OpenTickets-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Open Tickets </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a href="#ClosedTickets" id="ClosedTickets-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  Closed Tickets </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-content">
                      {/* crypto Tab  */}
                      <div className="tab-pane active" id="OpenTickets" role="tabpanel" aria-labelledby="OpenTickets-tab">


                        <div className="ticket_list" >
                          <div className="d-flex justify-content-between" >
                            <h5> Support Tickets (12) </h5>
                          </div>
                          <div className="table-responsive m_table">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th scope="col" className=""> Date/Time </th>
                                  <th scope="col" className=""> Ticket ID </th>
                                  <th scope="col" className=""> Subject </th>
                                  <th scope="col" className=""> Message  </th>
                                  <th scope="col" className=""> Image </th>
                                  <th scope="col" className=""> Status  </th>
                                  <th scope="col" className=""> Action </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="">
                                    <span> 11-12-2024 </span>
                                  </td>
                                  <td className="">
                                    <span> ----  </span>
                                  </td>
                                  <td className="">
                                    <span> ----  </span>
                                  </td>
                                  <td className="">
                                    <span> ----  </span>
                                  </td>
                                  <td className="">
                                    <span> ----  </span>
                                  </td>
                                  <td className="">
                                    <span> ----  </span>
                                  </td>
                                  <td className="">
                                    <div className="d-flex">
                                      <a href="/support" className="btn btn-dark shadow btn-xs sharp me-1"  >
                                        <i className="ri-eye-line"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              {/* User Logs   */}
              <div className="tab-pane    " id="userlogs" role="tabpanel" aria-labelledby="userlogs-pill">
                <div className="card-body py-4" >

                  <div className="profile_data  ac_body  " >
                    <div className="d-flex justify-content-between" >
                      <h5> User Logs </h5>

                    </div>
                  </div>
                </div>
              </div>






            </div>

          </div>
        </div>

      </main>
    </div>
  )
}

export default MasterAdmin