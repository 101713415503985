import React, { useState, useEffect } from "react";
import LoaderHelper from '../../CustomComponent/LoaderHelper/LoaderHelper'
import { alertErrorMessage, alertSuccessMessage } from "../../CustomComponent/CustomAlert";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";

const Notification = () => {
    const [notificationTitle, setNotificationTitle] = useState('');
    const [description, setDescription] = useState("");
    const [notificationList, setNotificationList] = useState([]);

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "notificationTitle":
                setNotificationTitle(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
            default:
        }
    }

    const resetInputChange = () => {
        setNotificationTitle("");
        setDescription("");
    }

    const handleNotify = async (notificationTitle, notification) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.addNotify(notificationTitle, notification).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message);
                    resetInputChange();
                    handleNotification();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    }
    const linkFollow = (row) => {
        return (
            <button className="btn btn-danger btn-sm" type="button" onClick={() => DeleteNotification(row?._id)}>Delete</button>
        );
    };

    const columns = [
        { name: "Notification Title", shrink: true, wrap: true, selector: row => row?.title, },
        { name: "Description", shrink: true, wrap: true, selector: row => row?.description },
        { name: "Action", selector: linkFollow, },
    ];


    useEffect(() => {
        handleNotification()
    }, []);

    const handleNotification = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getNotificationList().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setNotificationList(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("Something Went Wrong");
            }
        });
    }
    const DeleteNotification = async (id) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.deleteNotify(id).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result?.message)
                    handleNotification()
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("Something Went Wrong");
            }
        });
    }


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-bell"></i></div>
                                        Notifications
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="row" >
                        <div className="col-xl-4">
                            <div className="card mb-4 mb-xl-0">
                                <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                    <div className="d-flex align-items-center justify-content-start mb-4 ">
                                        <h5 className="mb-0" >Send Notification</h5>
                                    </div>
                                    <form>
                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1"> Notification Title </label>
                                            <input className="form-control form-control-solid" type="text" placeholder="Enter Title" name="notificationTitle" value={notificationTitle} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1"> Description </label>
                                            <div className="row mb-3">
                                                <div className="col-xl-12">
                                                    <textarea className="form-control form-control-solid" rows={3} name="description" value={description} onChange={handleInputChange}></textarea>
                                                </div>

                                            </div>
                                        </div>
                                        <button className="btn btn-indigo btn-block w-100 mt-2" type="button" onClick={() => handleNotify(notificationTitle, description,)} disabled={!notificationTitle} > Send Notification </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8" >
                            <div className="card">
                                <div className="card-header">Notification List
                                    {notificationList.length === 0 ? "" :
                                        <div className="dropdown">
                                            <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                            <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                <CSVLink data={notificationList} className="dropdown-item">Export as CSV</CSVLink>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="card-body" >
                                    <form className="row" >
                                        <div className="col-12" >
                                            <div className="table-responsive" >
                                                <DataTable columns={columns} data={notificationList} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Notification;