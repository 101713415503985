// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centerbox {
    display: flex; 
    height: 100vh; 
    width: 100%; 
    align-items: center;
    justify-content: center;
    align-self: center;
    background: rgba(0, 0, 0, 0.5); 
    position: fixed;
    z-index: 999999;
    top: 0;
  }
    
  .centerbox img{
    max-width:80px; 
  }`, "",{"version":3,"sources":["webpack://./src/Components/CustomComponent/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,8BAA8B;IAC9B,eAAe;IACf,eAAe;IACf,MAAM;EACR;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".centerbox {\n    display: flex; \n    height: 100vh; \n    width: 100%; \n    align-items: center;\n    justify-content: center;\n    align-self: center;\n    background: rgba(0, 0, 0, 0.5); \n    position: fixed;\n    z-index: 999999;\n    top: 0;\n  }\n    \n  .centerbox img{\n    max-width:80px; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
