import React, { useEffect, useState } from "react";
import LoaderHelper from '../../CustomComponent/LoaderHelper/LoaderHelper'
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";

const TradingReport = () => {
    const [tradingReport, setTradingReport] = useState([]);
    const [allData, setAllData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);




    const columns = [
        { name: "Date/Time", selector: row => moment(row?.updatedAt).format("DD/MM/YYYY"), },
        { name: "Order Id", wrap: true, selector: row => row?.order_id, },
        { name: "Currency", selector: row => row?.main_currency, },
        { name: "Order Type", selector: row => row?.order_type, },
        { name: "Fee", wrap: true, selector: row => parseFloat(row?.fee?.toFixed(8)) },
        { name: "Price", wrap: true, selector: row => row?.price, },
        { name: "Quantity", wrap: true, selector: row => parseFloat(row?.quantity?.toFixed(8)), },
        { name: "Side", selector: row => row?.side, },
        { name: "TDS", wrap: true, selector: row => row?.tds, },
    ];
    function handleSearch(e) {
        const keysToSearch = ["order_id", "main_currency", "order_type", "fee", "price", "quantity"];
        const searchTerm = e.target.value?.toLowerCase();
        const matchingObjects = allData?.reverse().filter(obj => { return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)) });
        setTradingReport(matchingObjects);
    };

    <div className="d-flex justify-content-center gap-2">
        <button type="btn" className="btn badge bg-primary text-white rounded-pill" disabled={skip === 0} onClick={() => { handleTradingReport(skip - 10, limit - 10) }}>{"<<Previous"}</button>
        <button type="btn" className="btn badge bg-primary text-white rounded-pill" onClick={() => { handleTradingReport(skip + 10, limit + 10) }}>{"Next>>"}</button>
    </div>

    useEffect(() => {
        handleTradingReport(0, 10)
    }, []);

    const handleTradingReport = async (skip, limit) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.tradeHistory(skip, limit).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setSkip(skip)
                    setLimit(limit)
                    setTradingReport(result?.data.reverse());
                    setAllData(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header ">
                        <div className="container-xl px-4">
                            <div className="page-header-content">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="far fa-user"></i></div>
                                            Trading History
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4">
                        <div className="card mb-4">
                            <div className="card-header">

                                <div className="col-5">
                                    <input className="form-control " id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={handleSearch} />
                                </div>
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export{" "}
                                    </button>
                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={tradingReport} className="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive" width="100%">
                                <DataTable columns={columns} data={tradingReport} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default TradingReport;