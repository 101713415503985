import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Support from "../Support";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";
import LoaderHelper from "../../CustomComponent/LoaderHelper/LoaderHelper";

const Supportmessage = (props) => {
  const { id, email, description } = props;
  const [activeScreen, setActiveScreen] = useState('supportmessage');
  const [message, setMessage] = useState('');
  const [messageQuery, setMessageQuery] = useState([]);
  const [isRotating, setRotating] = useState(false);
  const chatEndRef = useRef(null);

  const handleMessageQuery = async (message, id) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.replyTicket(message, id);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setMessage("");
        handleRefresh();
      } else {
        alertErrorMessage(result.msg);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    }
  };

  const handleRefresh = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getMessageById(id);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        setMessageQuery(result?.data?.chat);
        scrollToBottom();
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    } finally {
      setRotating(false);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 100);  // Add a slight delay to ensure the element is in the DOM
  };

  useEffect(() => {
    handleRefresh();
  }, [id]);

  return (
    activeScreen === 'supportmessage' ? (
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header ">
            <div className="container-xl px-4">
              <div className="page-header-content">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <h1 className="page-header-title">
                      <Link to="" className="page-header-icon" onClick={() => setActiveScreen('support')} >
                        <i className="fa fa-arrow-left" ></i>
                      </Link>
                      <div className="hd_u_info" >
                        <img
                          src="assets/img/illustrations/profiles/profile-5.png"
                          alt=""
                          className="img-account-profile rounded-circle "
                        />
                        <span> Unknown User  <small className="text-dark" > +91 0000000000 | test@gmail.com </small> </span>
                      </div>
                    </h1>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="container-xl px-4">
            <div className="row">
              <div className="col-xl-4">
                <h6>Ticket Details</h6>
                <div className="card mb-4 mt-2">
                  <div className="card-body py-4" >
                    <div className="tk_card mb-3" >
                      <small>Ticket Closed Date</small>
                      <h5> 04 November 2024 </h5>
                    </div>
                    <div className="tk_card mb-3" >
                      <small>Ticket Subject</small>
                      <h5> No Subject Available </h5>
                    </div>
                    <div className="tk_card mb-3" >
                      <small> Description </small>
                      {description?.length === 0 ? (
                        <p>
                          No Description Available
                        </p>
                      ) : (
                        <p>
                          {description}
                        </p>
                      )}
                    </div>
                    <div className="tk_card mb-3" >
                      <small> Support Agents </small>
                      <h5> Unknown Agent, Unknown Agent, Unknown Agent </h5>
                    </div>
                  </div>




                </div>

                <h6> All Tickets</h6>
                <div className="card mt-2">
                  <div className="card-body p-0 overflow-hidden pe-1" >
                    <div className="dz-scroll dz_row" >
                      <div className="tk_col active " >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-success">Open</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                      <div className="tk_col  "  >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-success">Open</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                      <div className="tk_col " >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-danger">Closed</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                      <div className="tk_col " >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-danger">Closed</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                      <div className="tk_col " >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-danger">Closed</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                      <div className="tk_col " >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-danger">Closed</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                      <div className="tk_col " >
                        <h5>    Ticket ID: #6769   <small>  <span className="text-danger">Closed</span> | 22 Nov 2024, 12:12PM </small>   </h5>
                        <span><i className="ri-arrow-right-long-line"></i></span>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="col-xl-8">
                <div className="card mb-4">
                  <div className="card-header">
                    <span className="text-dark" > Ticket ID: #6769  <small className="tk_status" >  <span className="text-danger">Closed</span> | 22 Nov 2024, 12:12PM </small> </span>
                    <div className="d-flex align-items-center gap-2" >
                      <span className={` chat_close_icon cursor-pointer ${isRotating ? 'rotating' : ''}`} onClick={() => { handleRefresh(); setRotating(true); }}><i className="ri-refresh-line"></i></span>
                      <button
                        className={`btn btn-md btn-dark mx-1 ${isRotating ? 'rotating' : ''}`}
                        onClick={() => { handleRefresh(); setRotating(true); }}
                        type="button"
                      >
                        Mark As Resolved
                      </button>
                    </div>
                  </div>
                  <div className="card-body mt-3 pb-3">
                    <div className="messages">
                      <div className="ag_status" >
                        <p>You are currently chating with  - Unknown Agent</p>
                      </div>
                      <>
                        {messageQuery?.length > 0 ? (
                          messageQuery.map((item, index) => (
                            <div key={index} className={`clip ${item?.message_by === 1 ? 'received' : 'sent'}`}>
                              <div className="text">{item?.message}</div>
                            </div>
                          ))
                        ) : null}
                        <div ref={chatEndRef} />
                      </>
                    </div>

                  </div>
                  <div className="card-footer " >
                    <div className="bottom">
                      <div className="cup">
                        <input
                          type="text"
                          id="message"
                          placeholder="Message..."
                          name="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <div className="chat_action" >
                          <button
                            className="btn_attach  btn-icon"
                          >
                            <input type="file" />
                            <span className="ac_alert" ></span>
                            <i className="ri-link"></i>
                          </button>
                          <button
                            className="btn btn-primary send"
                            onClick={() => handleMessageQuery(message, id)}
                          >
                            <i className="ri-send-plane-fill ri-xl me-2"></i>
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    ) : (
      <Support />
    )
  );
};

export default Supportmessage;
