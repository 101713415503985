import React from "react";

const WithdrawalRequest = () => {

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-12">
                  <div className="d-flex justify-content-between" >
                    <h1 className="page-header-title">
                      <div className="page-header-icon"><i className=" fa fa-user-slash"></i></div>
                      WithdrawalRequest
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

      </main>
    </div>

  );
};

export default WithdrawalRequest;
