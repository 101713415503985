import React, { useState } from "react";
import { Link } from "react-router-dom";


const ForgetpasswordPage = () => {
    const [email, setEmail] = useState("");

    return (

        <div className="login-card" >
            <div className="container-xl px-4">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                        <div className="card my-5">
                            <div className="card-body p-r text-center">
                                <img src="/assets/img/logo.svg" className="img-fluid" alt="" width='200' />
                            </div>
                            <div className="card-body p-3 p-md-5 pt-md-0">
                                <div className="h2 text-center mb-2"> <strong>Forgot Password</strong></div>
                                <div className="text-center small text-muted mb-4">Enter your email address below and we will send your password on your Email.</div>
                                <div className="mb-3">
                                    <label className="text-gray-600 small" htmlFor="emailExample">Email Address</label>
                                    <input className="form-control form-control-solid" type="email" aria-label="Email Address" aria-describedby="emailExample" name="email" onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                {/* <div>
                                    <Link className="btn-link text-decoration-none text-black" to="/forgotpassword">Forgot your password..?</Link>
                                </div> */}
                                <div className="text-center py-3 mt-2">
                                    <button type="button" className="btn btn-block w-100 btn-xl btn btn-indigo btn_admin">
                                        FORGOT PASSWORD
                                    </button>
                                </div>

                                <div className="card-body ">
                                    <div className="small text-center">
                                        <Link className="btn-link text-decoration-none text-black" to="/">Back to - Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgetpasswordPage;