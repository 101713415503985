import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import LoaderHelper from "../../../CustomComponent/LoaderHelper/LoaderHelper";
import { $ } from "react-jquery-plugin";
import EditModal from "./EditModal";

import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const TraderDetails = (props) => {


    const [activeTab, setActiveTab] = React.useState(1);

    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };



    const [userDetail, setUserDetail] = useState([]);
    const [walletdetails, setwalletdetails] = useState([]);
    const [status, setstatus] = useState();
    const [makerFee, setMakerFee] = useState();
    const [takerFee, setTakerFee] = useState();
    const location = useLocation();
    const userTradeData = location.state?._id

    const [formData, setFormData] = useState([{
        firstName: userTradeData?.firstName,
        lastName: userTradeData?.lastName,
        profilePicture: "",
        status: userTradeData?.status,
        _id: userTradeData?._id,
    }])

    const [modalFields, setModalFields] = useState([]);
    const [modalTitle, setModalTitle] = useState('');

    const handleUserData = async () => {
        try {
            const result = await AuthService.getuserDetails(location.state?._id);
            if (result?.success) {
                setUserDetail(result.data);
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage("An error occurred while fetching user details.");
        }
    };

    const submitEditedDetails = () => {
        // document.getElementById('editModal').classList.remove('show');
        $('#edit_trade_modal').modal('hide');
        console.log('submittedData', formData)

        return

        LoaderHelper.loaderStatus(true);
        try {
            const result = AuthService.api(formData);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                // $('#edit_trade_modal').modal('hide');
            } else {
                alertErrorMessage(result?.message);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false) };
    }

    const PriceFormat = (row) => {
        return row?.fee && parseFloat(row?.fee?.toFixed(5));
    };

    const columns = [
        { name: "Date/Time", selector: row => moment(row?.updatedAt).format("MMM Do YYYY"), },
        { name: "Currency", selector: row => row.currency, },
        { name: "Fee", selector: PriceFormat },
        { name: "Type", selector: row => row.order_type, },
        { name: "Price", selector: row => row.price, },
        { name: "Quantity", selector: row => row.quantity, },
        { name: "Side", selector: row => row.side, },
        { name: "TDS", selector: row => row.tds, },
    ]

    // ******** Wallet History Table ************//
    const walletdetailscolumns = [
        { name: "Assets", selector: row => row.short_name, },
        { name: "Balance", selector: row => row.balance, },
        { name: "Locked Balance", selector: row => row.locked_balance, },
    ]

    const handleTransferhistory = async () => {
        await AuthService.transferhistory(location.state?._id).then(async result => {
            if (result?.success) {
                try {
                    // setuserTradeDetails(result?.data);
                } catch (error) {
                    // console.log('error', `${error}`);
                }
            } else {
                // alertErrorMessage(result.message)
            }
        });
    }
    const userWallet = async () => {
        await AuthService.userWallet(location.state?._id).then(async result => {
            if (result?.success) {
                try {
                    setwalletdetails(result?.data);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                // alertErrorMessage(result.message)
            }
        });
    }

    useEffect(() => {
        userWallet();
    }, [])



    const HandleStatus = (Status) => {
        setstatus(Status);
        if (!Status) {
            setMakerFee(0)
            setTakerFee(0)
        }
    };
    useEffect(() => {
        if (makerFee > 0 || takerFee > 0) {
            setstatus(true)
        } else {
            setMakerFee(0);
            setTakerFee(0);
            setstatus(false)
        }

    }, [makerFee, takerFee]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const openModal = (fields, title) => {
        setModalFields(fields);
        setModalTitle(title);
        $('#editModal').modal('show');
    };

    const personalFields = [
        { label: 'First Name', name: 'firstName', type: 'text', placeholder: 'Enter First Name', id: 'inputFirstName' },
        { label: 'Last Name', name: 'lastName', type: 'text', placeholder: 'Enter Last Name', id: 'inputLastName' },
        { label: 'Email', name: 'email', type: 'email', placeholder: 'Enter Email', id: 'inputEmail' },
        { label: 'Mobile Number', name: 'phone', type: 'text', placeholder: 'Enter Phone', id: 'inputPhone' },
        { label: 'Referral Code', name: 'Referral Code', type: 'text', placeholder: 'Enter Referral Code', id: 'inputReferralCode' },
        { label: 'Status', name: 'status', type: 'text', placeholder: 'Enter Status', id: 'inputStatus' },
        { label: 'Kyc Type', name: 'kycType', type: 'text', placeholder: 'Enter Kyc Type', id: 'inputKycType' },
        { label: 'Date of Birth', name: 'dob', type: 'text', placeholder: 'Enter Date of Birth', id: 'inputDob' },
        { label: 'Address', name: 'address', type: 'text', placeholder: 'Enter Address', id: 'inputAddress' },
        { label: 'City Name', name: 'city', type: 'text', placeholder: 'Enter City Name', id: 'inputCity' },
        { label: 'Zip Code', name: 'zipCode', type: 'text', placeholder: 'Enter Zip Code', id: 'inputZipCode' },
        { label: 'State Name', name: 'state', type: 'text', placeholder: 'Enter State Name', id: 'inputState' },
        { label: 'Country Name', name: 'country', type: 'text', placeholder: 'Enter Country Name', id: 'inputCountry' },
    ];

    const bankAccountFields = [
        { label: 'Bank Name', name: 'bankName', type: 'text', placeholder: 'Enter Bank Name', id: 'inputBankName' },
        { label: 'Branch Name', name: 'branchName', type: 'text', placeholder: 'Enter Branch Name', id: 'inputBranchName' },
        { label: 'Account Holder Name', name: 'accountHolderName', type: 'text', placeholder: 'Enter Account Holder Name', id: 'inputAccountHolderName' },
        { label: 'Account Type', name: 'accountType', type: 'text', placeholder: 'Enter Account Type', id: 'inputAccountType' },
        { label: 'Bank Account Number', name: 'bankAccountNumber', type: 'text', placeholder: 'Enter Bank Account Number', id: 'inputBankAccountNumber' },
        { label: 'IFSC Code', name: 'ifscCode', type: 'text', placeholder: 'Enter IFSC Code', id: 'inputIFSCCode' },
    ];

    const upiAccountFields = [
        { label: 'Name', name: 'upiName', type: 'text', placeholder: 'Enter Name', id: 'inputUPIName' },
        { label: 'UPI VPA ID', name: 'upiVpaId', type: 'text', placeholder: 'Enter UPI VPA ID', id: 'inputUPIVpaId' },
    ];

    const nomineeFields = [
        { label: ' Name', name: 'nomineeName', type: 'text', placeholder: 'Enter Name', id: 'inputNomineeName' },
        { label: 'Relation', name: 'relation', type: 'text', placeholder: 'Enter Relation', id: 'inputRelation' },
        { label: 'Date of Birth', name: 'nomineeDob', type: 'text', placeholder: 'Enter Date of Birth', id: 'inputnomineeDob' },
        { label: 'Aadhaar Number', name: 'nomineeAadhaar', type: 'text', placeholder: 'Enter Aadhaar Number', id: 'inputnomineeAadhaar' },
    ]




    useEffect(() => {
        handleUserData();
    }, [])



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <form className="form-data" >
                        <header className="page-header ">
                            <div className="container-xl px-4">
                                <div className="page-header-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h1 className="page-header-title">
                                                <Link to="/users_list" className="page-header-icon">
                                                    <i className="fa fa-arrow-left" ></i>
                                                </Link>
                                                <div className="hd_u_info" >
                                                    <img
                                                        src={userDetail?.document_front_image ? `${ApiConfig.appUrl}${userDetail?.document_front_image}` : "assets/img/illustrations/profiles/profile-5.png"}
                                                        alt=""
                                                        className="img-account-profile rounded-circle "
                                                        onError={(e) => e.target.src = "assets/img/illustrations/404-error.svg"}
                                                    />
                                                    <span>{userDetail?.kyc?.first_name || 'Unknown User'} {userDetail?.kyc?.last_name} <small className="text-success" > <i className="ri-verified-badge-fill "></i> Verified</small> </span>
                                                </div>
                                            </h1>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-danger px-4 btn-md "> Block User</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-xl px-4" >
                            <div className="card" >
                                <div className="card-header pb-0 ps-0">
                                    <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                        <Tab className="nav-link" > Account Details  </Tab>
                                        <Tab className="nav-link" ><a href="/kyc_detail_personal" className="text-dark" >KYC Details </a></Tab>
                                        <Tab className="nav-link" > Payment Details </Tab>
                                        <Tab className="nav-link" > Security </Tab>
                                        <Tab className="nav-link" > Permission </Tab>
                                        <Tab className="nav-link" > User Wallets </Tab>
                                        <Tab className="nav-link" > Transaction History </Tab>
                                        <Tab className="nav-link" > Trade History </Tab>
                                        <Tab className="nav-link" > Support Ticket</Tab>
                                        <Tab className="nav-link" > Activity Logs </Tab>
                                    </Tabs>
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <i className="fa fa-download me-3"></i>Export
                                        </button>
                                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                                            {/* <CSVLink  className="dropdown-item">
                                                Export as CSV
                                            </CSVLink> */}
                                            <div className="dropdown-item" >Export as CSV</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content tab-content-card " id="cardPillContent">

                                    {/* personal Information */}
                                    <div className="tab-pane" id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                                        <div className="card-body py-4" >
                                            <div className="profile_data  ac_body " >
                                                <h5>  Account Information </h5>
                                                <div className="row g-4">
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Joined Date   </label><p> 12th Dec 2024 </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label> User Id   </label><p>   {userDetail?.user?.uin || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  KYC Type   </label><p>  {userDetail?.kyc?.sub_kyc_type || '------'} </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Account Status   </label><p className="text-success" >   {userDetail?.user?.status || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Referral Code   </label><p>  {userDetail?.user?.referral_code || '------'}   </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group d-flex align-items-center justify-content-start">
                                                            <div>
                                                                <label>  Mobile Number  </label>
                                                                <p className="d-flex align-items-center justify-content-start" >  {userDetail?.kyc?.emailAddress || '------'}
                                                                </p>
                                                            </div>
                                                            <a href="#" className="link-badge badge bg-primary ms-4 btn-sm"> Update </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group d-flex align-items-center justify-content-start">
                                                            <div>
                                                                <label> Email Address </label>
                                                                <p className="d-flex align-items-center justify-content-start" >  {userDetail?.kyc?.mobileNumber || '------'}
                                                                </p>
                                                            </div>
                                                            <a href="#" className="link-badge badge bg-primary ms-4 btn-sm"> Update </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label> First Name  </label><p>   {userDetail?.kyc?.first_name || '------'} </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Middle Name  </label><p>  {userDetail?.kyc?.middle || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Last Name  </label><p>  {userDetail?.kyc?.last || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Date of Birth   </label><p>  {userDetail?.kyc?.dob || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Address   </label><p>  {userDetail?.kyc?.address || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  City   </label><p>  {userDetail?.kyc?.city || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  State   </label><p>  {userDetail?.kyc?.state || '------'} </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>  Country   </label><p>  {userDetail?.kyc?.country || '------'}  </p></div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-3">
                                                        <div className="label-group"><label>   Zip Code  </label><p>  {userDetail?.kyc?.zip_code || '------'}  </p></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="profile_data  ac_body  " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5>  Update Requests </h5>
                                                </div>
                                                <div className="table-responsive m_table">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className=""> Date/Time </th>
                                                                <th scope="col" className=""> Current Email/Mobile </th>
                                                                <th scope="col" className=""> Updated Email/Mobile  </th>
                                                                <th scope="col" className=""> Updated By </th>
                                                                <th scope="col" className=""> Remark  </th>
                                                                <th scope="col" className="">Status </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="">
                                                                    <span> 11-12-2024 </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> +91 1234567890 </span>
                                                                </td>
                                                                <td className="">
                                                                    <span>  +91 0987454321  </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> Uknown Admin </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> Message here......... </span>
                                                                </td>
                                                                <td className="text-warning">
                                                                    <span>  Pending </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                            <hr className="my-4" />
                                            <div className="profile_data  ac_body  " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5>  Nominee Information </h5>
                                                    <button
                                                        type="button" className="btn btn-dark px-4 btn-md " onClick={() => openModal(nomineeFields, 'Edit Nominee Details')}  >
                                                        Edit
                                                    </button>
                                                </div>
                                                <div className="row g-4" >

                                                    <div className="col-lg-6 col-xl-4"><div className="label-group"><label>  Name     </label>
                                                        <p>  {userDetail?.nominee_details?.bank_name || "----"} </p> </div></div>
                                                    <div className="col-lg-6 col-xl-4"><div className="label-group"><label>  Relation     </label>
                                                        <p>   {userDetail?.nominee_details?.branch_name || "----"} </p> </div></div>
                                                    <div className="col-lg-6 col-xl-4"><div className="label-group"><label>  Date of Birth:     </label>
                                                        <p>  {userDetail?.nominee_details?.account_holder_name || "----"}</p> </div></div>
                                                    <div className="col-lg-6 col-xl-4"><div className="label-group"><label>  Aadhaar Number:     </label>
                                                        <p>  {userDetail?.nominee_details?.account_type || "----"}</p> </div></div>

                                                </div>

                                            </div>
                                            <div className="profile_data  ac_body my-4 " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5>  Previous Nominee </h5>
                                                </div>
                                                <div className="table-responsive m_table">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className=""> Date/Time </th>
                                                                <th scope="col" className=""> Name </th>
                                                                <th scope="col" className="">  Relation  </th>
                                                                <th scope="col" className="">  DOB </th>
                                                                <th scope="col" className="">   Aadhar Number  </th>
                                                                <th scope="col" className="">Updated by </th>
                                                                <th scope="col" className=""> Remark </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="">
                                                                    <span> 11-12-2024 </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> Unknown Name </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> ----  </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> ----  </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> ----  </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> ----  </span>
                                                                </td>
                                                                <td className="">
                                                                    <span> Message here......... </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    {/* payment details   */}
                                    <div className="tab-pane  " id="paymentPill" role="tabpanel" aria-labelledby="payment-pill">
                                        <div className="card-body py-4" >

                                            <div className="profile_data  ac_body  " >
                                                <div className=" " >
                                                    <div className="custom-tab-2">
                                                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#bankaccounts" id="bankaccounts-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Bank Accounts  </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#UPIids" id="UPIids-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> UPI Id's </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-content">

                                                    {/* crypto Tab  */}
                                                    <div className="tab-pane active" id="bankaccounts" role="tabpanel" aria-labelledby="bankaccounts-tab">
                                                        <div className=" " >


                                                            <div className="profile_data  ac_body" >
                                                                <h5 className="mb-4" >  Bank Accounts </h5>
                                                                <ul className="nav custom-tabs mb-4  ">
                                                                    <li className="buysell-tab">
                                                                        <a className="active">  Verified Accounts  </a>
                                                                    </li>
                                                                    <li className="buysell-tab">
                                                                        <a className="">  Pending Accounts  </a>
                                                                    </li>
                                                                    <li className="buysell-tab">
                                                                        <a className="">   Inactive Accounts </a>
                                                                    </li>
                                                                </ul>
                                                                <div className="ac_lists" >
                                                                    <div className="ac_card" >
                                                                        <div className="row g-4 align-items-end">
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK <span className="badge bg-dark" >Default</span> </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Verification Date </label><p>  11 Nov. 2024    </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div className="ac_card" >
                                                                        <div className="row g-4 align-items-end">
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Verification Date </label><p>  11 Nov. 2024    </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Status </label><p>   <span className="badge bg-success" > Verified </span>    </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div className="ac_card" >
                                                                        <div className="row g-4 align-items-end">
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Applied Date </label><p> ----   </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Status </label><p>   <span className="badge bg-warning" > Pending </span>    </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div className="ac_card" >
                                                                        <div className="row g-4 align-items-end">
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Bank Name   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  Account Number   </label><p>  HDFC BANK  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  IFSC Code </label><p> DFGV2FD31G </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Deleted Date </label><p> ----   </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Status </label><p>   <span className="badge bg-danger" > Deleted </span>    </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-primary" >View</Link> </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* crypto Tab  */}
                                                    <div className="tab-pane" id="UPIids" role="tabpanel" aria-labelledby="UPIids-tab">
                                                        <div className="">
                                                            <div className="profile_data  ac_body" >
                                                                <h5 className="mb-4" >  UPI ID </h5>
                                                                <ul className="nav custom-tabs mb-4  ">
                                                                    <li className="buysell-tab">
                                                                        <a className="active">  Verified UPI's  </a>
                                                                    </li>
                                                                    <li className="buysell-tab">
                                                                        <a className="">   Deleted UPI's </a>
                                                                    </li>
                                                                </ul>
                                                                <div className="ac_lists" >
                                                                    <div className="ac_card" >
                                                                        <div className="row g-4 align-items-end">
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  KYC User Name     </label><p>  Unknown username  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  UPI ID   </label><p>  asdasd@ybl <span className="badge bg-dark" >Default</span> </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>    UPI ID Status  </label><p className="text-success" > <small><i className="ri-shield-check-line"></i>  Valid</small>   </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  UPI Name </label><p> asdasdsad </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Name Match Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-danger" >Delete</Link> </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div className="ac_card" >
                                                                        <div className="row g-4 align-items-end">
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  KYC User Name     </label><p>  Unknown username  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  UPI ID   </label><p>  asdasd@ybl <span className="badge bg-dark" >Default</span> </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>    UPI ID Status  </label><p className="text-danger" > <small><i className="ri-spam-2-line"></i>  Invalid </small>   </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label>  UPI Name </label><p> asdasdsad </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"><label> Name Match Status </label><p>   <span className="badge bg-success" > Verified </span> </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-2">
                                                                                <div className="label-group"> <Link to="/user_bank_details" className="btn btn-sm btn-danger" >Delete</Link> </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/*  Security Details */}
                                    <div className="tab-pane " id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">

                                        <div className="card-body py-4" >

                                            <div className="profile_data " >
                                                <h4 className="mb-4" >  Two Factor Authentication   </h4>

                                                <div className="  row justify-content-center" >

                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i className="ri-arrow-up-down-line"></i></div>
                                                                <div>
                                                                    <h5>Authenticator App :</h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                                                                <div>
                                                                    <h5>  Email OTP Verification </h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i className="ri-p2p-fill"></i></div>
                                                                <div>
                                                                    <h5>   Mobile OTP Verification  </h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7" >
                                                        <div className="per_col mb-3" >
                                                            <div className="d-flex align-items-center " >
                                                                <div className="pr_icon" ><i className="ri-arrow-up-line"></i></div>
                                                                <div>
                                                                    <h5> None  </h5>
                                                                </div>
                                                            </div>
                                                            <label className="switch">
                                                                <input type="checkbox" name="" id="forBuySell" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    {/* Permission Details */}
                                    <div className="tab-pane  active" id="permissionPill" role="tabpanel" aria-labelledby="permission-pill">
                                        <div className="card-body py-4" >
                                            <div className="profile_data " >

                                                <div className=" " >
                                                    <div className="custom-tab-2">
                                                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#Permissions" id="Permissions-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">  Permissions  </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#Limits" id="Limits-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Limits </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="tab-content">

                                                    {/* Permissions Tab  */}
                                                    <div className="tab-pane active" id="Permissions" role="tabpanel" aria-labelledby="Permissions-tab">
                                                        <div className="profile_data  ac_body" >
                                                            <div className="d-flex justify-content-between" >
                                                                <h5> Permissitions  </h5>
                                                            </div>
                                                            <div className="  row" >

                                                                <div className="col-lg-6" >
                                                                    <div className="per_col mb-3" >
                                                                        <div className="d-flex align-items-center " >
                                                                            <div className="pr_icon" ><i className="ri-arrow-up-down-line"></i></div>
                                                                            <div>
                                                                                <h5>Buy & Sell</h5>
                                                                                <p> Revoke user side buy sell access. </p>
                                                                            </div>
                                                                        </div>
                                                                        <label className="switch">
                                                                            <input type="checkbox" name="" id="forBuySell" />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" >
                                                                    <div className="per_col mb-3" >
                                                                        <div className="d-flex align-items-center " >
                                                                            <div className="pr_icon" ><i className="ri-line-chart-fill"></i></div>
                                                                            <div>
                                                                                <h5>  Spot  Trading </h5>
                                                                                <p> Revoke user side  Spot Trading freture. </p>
                                                                            </div>
                                                                        </div>
                                                                        <label className="switch">
                                                                            <input type="checkbox" name="" id="forBuySell" />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" >
                                                                    <div className="per_col mb-3" >
                                                                        <div className="d-flex align-items-center " >
                                                                            <div className="pr_icon" ><i className="ri-p2p-fill"></i></div>
                                                                            <div>
                                                                                <h5> P2P Trading  </h5>
                                                                                <p> Revoke user side  P2P Trading freture. </p>
                                                                            </div>
                                                                        </div>
                                                                        <label className="switch">
                                                                            <input type="checkbox" name="" id="forBuySell" />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" >
                                                                    <div className="per_col mb-3" >
                                                                        <div className="d-flex align-items-center " >
                                                                            <div className="pr_icon" ><i className="ri-arrow-up-line"></i></div>
                                                                            <div>
                                                                                <h5> Deposit  Funds</h5>
                                                                                <p> Revoke user side Deposit freture. </p>
                                                                            </div>
                                                                        </div>
                                                                        <label className="switch">
                                                                            <input type="checkbox" name="" id="forBuySell" />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" >
                                                                    <div className="per_col mb-3" >
                                                                        <div className="d-flex align-items-center " >
                                                                            <div className="pr_icon" ><i className="ri-arrow-down-line"></i></div>
                                                                            <div>
                                                                                <h5> Withdraw Funds </h5>
                                                                                <p> Revoke user side Withdraw freture. </p>
                                                                            </div>
                                                                        </div>
                                                                        <label className="switch">
                                                                            <input type="checkbox" name="" id="forBuySell" />
                                                                            <span className="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" >
                                                                    <div className="per_col mb-3" >
                                                                        <div className="d-flex align-items-center " >
                                                                            <div className="pr_icon" ><i class="ri-shield-user-fill"></i></div>
                                                                            <div>
                                                                                <h5>  Level 2 KYC </h5>
                                                                                <p>  If user Trusted then you can enable Level 2 KYC </p>
                                                                            </div>
                                                                        </div>
                                                                        <button className="btn btn-primary btn-md" >
                                                                            Enable L2 KYC
                                                                        </button>
                                                                        {/* <button className="btn btn-muted btn-md" >
                                                                            Enabled 
                                                                        </button> */}
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div className="profile_data  ac_body my-4 " >
                                                            <div className="d-flex justify-content-between" >
                                                                <h5> Updated History </h5>
                                                            </div>
                                                            <div className="table-responsive m_table">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className=""> Date/Time </th>
                                                                            <th scope="col" className=""> Permissition Type </th>
                                                                            <th scope="col" className=""> Prev. Status </th>
                                                                            <th scope="col" className=""> Updated Status </th>
                                                                            <th scope="col" className=""> Remark By </th>
                                                                            <th scope="col" className=""> Remark </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="">
                                                                                <span> 11-12-2024 </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> Unknown Name </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> Message here......... </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* Limits Tab  */}
                                                    <div className="tab-pane" id="Limits" role="tabpanel" aria-labelledby="Limits-tab">
                                                        <div className="profile_data  ac_body" >
                                                            <div className="d-flex justify-content-between" >
                                                                <h5> Limits  </h5>
                                                            </div>
                                                            <div className="row g-4" >
                                                                <div className=" col-lg-7" >
                                                                    <div className="profile_data fc_details  ac_body " >
                                                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                                                            <h5 className="mb-0" > Account Withdrawal Limit (1FY)  </h5>
                                                                            <button className="btn btn-warning btn-sm"> Update Max Limit  </button>
                                                                        </div>
                                                                        <div className="row g-4">
                                                                            {/* editable in modal */}
                                                                            <div className="col-lg-4 col-xl-4">
                                                                                <div className="label-group"><label>  Account  Withdrawal Limit     </label><p> --------₹  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-xl-4">
                                                                                <div className="label-group"><label>    Max. INR Withdrawal Limit     </label><p>  --------₹  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-xl-4">
                                                                                <div className="label-group"><label>   Max. Crypto Withdrawal Limit     </label><p>   --------₹  </p></div>
                                                                            </div>

                                                                            <div className="col-lg-4 col-xl-4">
                                                                                <div className="label-group"><label>   Account Remaning Withdrawal Limit    </label><p>  --------₹ </p></div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-xl-4">
                                                                                <div className="label-group"><label>  Remaning INR Withdrawal Limit     </label><p>   --------₹  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-4 col-xl-4">
                                                                                <div className="label-group"><label> Remaning Crypto Withdrawal Limit   </label><p>  --------₹ </p></div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className=" col-lg-5" >
                                                                    <div className="profile_data fc_details  ac_body " >
                                                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                                                            <h5 className="mb-0" > INR Withdrawal Limits  (24h)  </h5>
                                                                            <button className="btn btn-warning btn-sm"> Update Max Limit  </button>
                                                                        </div>
                                                                        <div className="row g-4">

                                                                            {/* editable in modal */}
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label>    Max. Current Withdrawal Limit    </label><p>  --------₹  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label> Remaning Withdrawal Limit      </label><p>   --------₹ </p></div>
                                                                            </div>

                                                                            {/* editable in modal */}
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label>   Max. Current Withdrawal Orders    </label><p>   ------  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label>  Remaning Withdrawal  Orders    </label><p>  ------ </p></div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className=" col-lg-5" >
                                                                    <div className="profile_data fc_details ac_body " >
                                                                        <div className="d-flex align-items-center justify-content-between mb-4" >
                                                                            <h5 className="mb-0" >  Quick Buy Sell Limits <small>(24hrs.) </small></h5>
                                                                            <button className="btn btn-warning btn-sm"> Update Max Limit  </button>
                                                                        </div>
                                                                        <div className="row g-4">
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label>  Max. Current Buying Limit (INR)    </label><p> --------  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label>   Remaning Buying Limit (INR)   </label><p>  --------  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label> Max. Current Orders    </label><p>   --------  </p></div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6">
                                                                                <div className="label-group"><label>  Remaning  Orders   </label><p>  -------- </p></div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="profile_data  ac_body my-4 " >
                                                            <div className="d-flex justify-content-between" >
                                                                <h5> Updated History </h5>
                                                            </div>
                                                            <div className="table-responsive m_table">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className=""> Date/Time </th>
                                                                            <th scope="col" className=""> Limit Type </th>
                                                                            <th scope="col" className="">   Prev. Limit  </th>
                                                                            <th scope="col" className="">   New Limit </th>
                                                                            <th scope="col" className="">  Prev. No of Orders </th>
                                                                            <th scope="col" className="">  New No of Orders   </th>
                                                                            <th scope="col" className=""> Remark By </th>
                                                                            <th scope="col" className=""> Remark </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="">
                                                                                <span> 11-12-2024 </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> Unknown Name </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> Message here......... </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* walletdetails*/}
                                    <div className="tab-pane " id="walletdetails" role="tabpanel" aria-labelledby="walletdetails">
                                        <div className="card-body py-4">

                                            <div className="profile_data  ac_body" >
                                                <h5>User Wallet</h5>
                                                <br />
                                                <ul className="nav custom-tabs mb-0">
                                                    <li className="buysell-tab">
                                                        <a className="active"> Overview   </a>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <a className="">  Funding </a>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <a className="">  Spot Wallet   </a>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <a className="">   Future Wallet   </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <form className="row">
                                                <div className="col-12" >
                                                    {/* <table className="" width="100%" >
                                                        <DataTable columns={walletdetailscolumns} data={walletdetails} />
                                                    </table> */}
                                                    <div className="" >

                                                    </div>
                                                    <div className="search-header align-items-start justify-content-between px-0">
                                                        <div className="ow_row" >
                                                            <span>Estimated Balance</span>
                                                            <h5 className="" >0000.000 <small>INR</small></h5>
                                                            <p className="mb-0"> Locked Balance ≈ 0, &nbsp; Total Hold Balance ≈ 0, &nbsp; Total Available Balance ≈ 0 </p>
                                                        </div>
                                                        <div className="dd_search">
                                                            <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                            <i className="fas fa-search"></i>
                                                        </div>
                                                    </div>

                                                    <div className="table-responsive m_table">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Coin Name</th>
                                                                    <th scope="col" className="text-end">Available Balance</th>
                                                                    <th scope="col" className="text-end">Locked Balance</th>
                                                                    <th scope="col" className="text-end">Hold Balance</th>
                                                                    <th scope="col" className="text-end">Total Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="c_view">
                                                                            <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                                            <span>USDT <br />
                                                                                <small>Tether</small>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>0.00000 <br />
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>0.00000 <br />
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> 0.00000 </span>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> 0.00 </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="c_view">
                                                                            <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                                            <span>INR <br />
                                                                                <small>INR</small>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>0.00000 <br />
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>0.00000 <br />
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> 0.00000 </span>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> 0.00 </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* Transaction History   */}
                                    <div className="tab-pane   " id="Transaction" role="tabpanel" aria-labelledby="Transaction-pill">
                                        <div className="card-body py-4" >

                                            <div className="profile_data  ac_body  " >
                                                <div className=" " >
                                                    <div className="custom-tab-2">
                                                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#DepositWithdrawals" id="DepositWithdrawals-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Deposit & Withdrawals  </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#Transfer" id="Transfer-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Transfer </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#Pay" id="Pay-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Pay </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-content">

                                                    {/* crypto Tab  */}
                                                    <div className="tab-pane active" id="DepositWithdrawals" role="tabpanel" aria-labelledby="DepositWithdrawals-tab">
                                                        <div className=" " >
                                                            <div className="profile_data  ac_body d-flex align-items-center mb-3 justify-content-between" >
                                                                <h5 className="mb-0" >  Deposit & Withdrawals  </h5>
                                                                <div className="custom-tab-2 custom-tab-curve">
                                                                    <ul className="nav nav-pills justify-content-start " role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <a href="#Crypto" id="Crypto-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Crypto </a>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <a href="#INR" id="INR-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> INR </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="tab-content">

                                                                {/* crypto Tab  */}
                                                                <div className="tab-pane active" id="Crypto" role="tabpanel" aria-labelledby="Crypto-tab">
                                                                    <ul className="nav custom-tabs mb-0">
                                                                        <li className="buysell-tab">
                                                                            <a className="active"> All   </a>
                                                                        </li>
                                                                        <li className="buysell-tab">
                                                                            <a className="">  Deposits </a>
                                                                        </li>
                                                                        <li className="buysell-tab">
                                                                            <a className="">  Withdrawals </a>
                                                                        </li>
                                                                    </ul>

                                                                    <div className="filter_bar search-header px-0 g-2">
                                                                        <form className="row align-items-center w-100 gx-2">
                                                                            <div className="col-2">
                                                                                <select className="form-control form-select cursor-pointer">
                                                                                    <option value=""> Coins List </option>
                                                                                    <option value="Completed"> All Coins </option>
                                                                                    <option value="Pending"> Coin 1 </option>
                                                                                    <option value="Pending"> Coin 2 </option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <select className="form-control form-select cursor-pointer">
                                                                                    <option value=""> Order Status</option>
                                                                                    <option value="Completed"> All  </option>
                                                                                    <option value="Completed">Completed </option>
                                                                                    <option value="Pending">Pending</option>
                                                                                    <option value="Rejected">Rejected</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control form-control-solid"
                                                                                    data-provide="datepicker"
                                                                                    id="litepickerRangePlugin"
                                                                                    name="dateFrom"
                                                                                />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control form-control-solid"
                                                                                    data-provide="datepicker"
                                                                                    id="litepickerRangePlugin"
                                                                                    name="dateTo"

                                                                                />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="row align-items-center gx-2">
                                                                                    <div className="col">
                                                                                        <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                            Apply
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <button className="btn btn-light btn-block w-100" type="button" >
                                                                                            <i className="ri-reset-left-line"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        <label className="small text-dark mb-1" >  </label>
                                                                        <div className="dd_search">
                                                                            <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                            <i className="fas fa-search"></i>
                                                                        </div>

                                                                    </div>
                                                                    <div className="table-responsive m_table">
                                                                        <table className="table table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" className=""> Date/Time </th>
                                                                                    <th scope="col" className=""> Order No. </th>
                                                                                    <th scope="col" className="">Currency </th>
                                                                                    <th scope="col" className="">Amount </th>
                                                                                    <th scope="col" className="">Chain </th>
                                                                                    <th scope="col" className=""> Address </th>
                                                                                    <th scope="col" className="">Fee </th>
                                                                                    <th scope="col" className="">Txn Id </th>
                                                                                    <th scope="col" className="">Completed Time </th>
                                                                                    <th scope="col" className="">Action </th>
                                                                                    <th scope="col" className="">Status </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>



                                                                </div>

                                                                {/* INR Tab  */}
                                                                <div className="tab-pane" id="INR" role="tabpanel" aria-labelledby="INR-tab">
                                                                    <ul className="nav custom-tabs mb-0">
                                                                        <li className="buysell-tab">
                                                                            <a className="active"> All   </a>
                                                                        </li>
                                                                        <li className="buysell-tab">
                                                                            <a className="">  Deposits </a>
                                                                        </li>
                                                                        <li className="buysell-tab">
                                                                            <a className="">  Withdrawals </a>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="filter_bar search-header px-0 g-2">
                                                                        <form className="row align-items-center w-100 gx-2">
                                                                            <div className="col-2">
                                                                                <select className="form-control form-select cursor-pointer">
                                                                                    <option value=""> Order Status</option>
                                                                                    <option value="Completed"> All  </option>
                                                                                    <option value="Completed">Completed </option>
                                                                                    <option value="Pending">Pending</option>
                                                                                    <option value="Rejected">Rejected</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control form-control-solid"
                                                                                    data-provide="datepicker"
                                                                                    id="litepickerRangePlugin"
                                                                                    name="dateFrom"
                                                                                />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control form-control-solid"
                                                                                    data-provide="datepicker"
                                                                                    id="litepickerRangePlugin"
                                                                                    name="dateTo"

                                                                                />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="row align-items-center gx-2">
                                                                                    <div className="col">
                                                                                        <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                            Apply
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <button className="btn btn-light btn-block w-100" type="button" >
                                                                                            <i className="ri-reset-left-line"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        <label className="small text-dark mb-1" >  </label>
                                                                        <div className="dd_search">
                                                                            <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                            <i className="fas fa-search"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="table-responsive m_table">
                                                                        <table className="table table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" className=""> Date/Time </th>
                                                                                    <th scope="col" className=""> Order No. </th>
                                                                                    <th scope="col" className="">Currency </th>
                                                                                    <th scope="col" className=""> Payment Method </th>
                                                                                    <th scope="col" className="">Amount </th>
                                                                                    <th scope="col" className="">Fee </th>
                                                                                    <th scope="col" className="">  Bank Details   </th>
                                                                                    <th scope="col" className="">UTR No. </th>
                                                                                    <th scope="col" className="">Completed Time </th>
                                                                                    <th scope="col" className="">Action </th>
                                                                                    <th scope="col" className="">Status </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span> ---- </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>



                                                                </div>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    {/* crypto Tab  */}
                                                    <div className="tab-pane" id="Transfer" role="tabpanel" aria-labelledby="Transfer-tab">
                                                        <div className=" " >
                                                            <div className="profile_data  ac_body" >
                                                                <h5 className="" >  Transfer History </h5>
                                                            </div>

                                                            {/* crypto Tab  */}
                                                            <div>
                                                                <div className="filter_bar search-header px-0 g-2">
                                                                    <form className="row align-items-center w-100 gx-2">
                                                                        <div className="col-2">
                                                                            <select className="form-control form-select cursor-pointer">
                                                                                <option hidden> Select Coin </option>
                                                                                <option value="Completed"> All Coins </option>
                                                                                <option value="Pending"> Coin 1 </option>
                                                                                <option value="Pending"> Coin 2 </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <select className="form-control form-select cursor-pointer">
                                                                                <option hidden > Select From  </option>
                                                                                <option value="Rejected"> Funding Wallet </option>
                                                                                <option value="Completed"> Spot Wallet </option>
                                                                                <option value="Pending"> Future Wallet </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <select className="form-control form-select cursor-pointer">
                                                                                <option hidden > Select  To  </option>
                                                                                <option value="Rejected"> Funding Wallet </option>
                                                                                <option value="Completed"> Spot Wallet </option>
                                                                                <option value="Pending"> Future Wallet </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control form-control-solid"
                                                                                data-provide="datepicker"
                                                                                id="litepickerRangePlugin"
                                                                                name="dateFrom"
                                                                                placeholder="Date"
                                                                            />
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control form-control-solid"
                                                                                data-provide="datepicker"
                                                                                id="litepickerRangePlugin"
                                                                                name="dateTo"

                                                                            />
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="row align-items-center gx-2">
                                                                                <div className="col">
                                                                                    <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                        Apply
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <button className="btn btn-light btn-block w-100" type="button" >
                                                                                        <i className="ri-reset-left-line"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <label className="small text-dark mb-1" >  </label>
                                                                    <div className="dd_search">
                                                                        <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                        <i className="fas fa-search"></i>
                                                                    </div>

                                                                </div>
                                                                <div className="table-responsive m_table">
                                                                    <table className="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className=""> Date/Time </th>
                                                                                <th scope="col" className=""> Coin </th>
                                                                                <th scope="col" className="">Amount </th>
                                                                                <th scope="col" className="">From/To </th>
                                                                                <th scope="col" className="">Fee </th>
                                                                                <th scope="col" className="">Status </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {/* crypto Tab  */}
                                                    <div className="tab-pane" id="Pay" role="tabpanel" aria-labelledby="Pay-tab">
                                                        <div className=" " >
                                                            <div className="profile_data  ac_body" >
                                                                <h5>  Pay History </h5>
                                                                <ul className="nav custom-tabs mb-0">
                                                                    <li className="buysell-tab">
                                                                        <a className="active">   All  </a>
                                                                    </li>
                                                                    <li className="buysell-tab">
                                                                        <a className="">  Paid   </a>
                                                                    </li>
                                                                    <li className="buysell-tab">
                                                                        <a className="">  Received </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            {/* crypto Tab  */}
                                                            <div>
                                                                <div className="filter_bar search-header px-0 g-2">
                                                                    <form className="row align-items-center w-100 gx-2">
                                                                        <div className="col-2">
                                                                            <select className="form-control form-select cursor-pointer">
                                                                                <option hidden> Select Coin </option>
                                                                                <option value="Completed"> All Coins </option>
                                                                                <option value="Pending"> Coin 1 </option>
                                                                                <option value="Pending"> Coin 2 </option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control form-control-solid"
                                                                                data-provide="datepicker"
                                                                                id="litepickerRangePlugin"
                                                                                name="dateFrom"
                                                                                placeholder="Date"
                                                                            />
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control form-control-solid"
                                                                                data-provide="datepicker"
                                                                                id="litepickerRangePlugin"
                                                                                name="dateTo"

                                                                            />
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="row align-items-center gx-2">
                                                                                <div className="col">
                                                                                    <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                        Apply
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <button className="btn btn-light btn-block w-100" type="button" >
                                                                                        <i className="ri-reset-left-line"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <label className="small text-dark mb-1" >  </label>
                                                                    <div className="dd_search">
                                                                        <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                        <i className="fas fa-search"></i>
                                                                    </div>

                                                                </div>
                                                                <div className="table-responsive m_table">
                                                                    <table className="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className=""> Date/Time </th>
                                                                                <th scope="col" className=""> Coin </th>
                                                                                <th scope="col" className=""> Type </th>
                                                                                <th scope="col" className="">Amount </th>
                                                                                <th scope="col" className="">From/To </th>
                                                                                <th scope="col" className="">Fee </th>
                                                                                <th scope="col" className="">Status </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                                <td className="">
                                                                                    <span> ---- </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>





                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* User Trade Details */}
                                    <div className="tab-pane    " id="TradePill" role="tabpanel" aria-labelledby="Trade-pill">

                                        <div className="card-body py-4" >
                                            <div className="profile_data  ac_body" >
                                                <div className=" " >
                                                    <div className="custom-tab-2">
                                                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#QuickBuySell" id="QuickBuySell-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true"> Quick Buy Sell  </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#SpotTrades" id="SpotTrades-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Spot Trades  </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#FutureTrades" id="FutureTrades-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Future Trades </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#PTwoPTrades" id="PTwoPTrades-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  P2P Trades  </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#Invest" id="Invest-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  Invest </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-content">

                                                    {/* Quick Buy Sell orders history tab  */}
                                                    <div className="tab-pane active" id="QuickBuySell" role="tabpanel" aria-labelledby="QuickBuySell-tab">
                                                        <ul className="nav custom-tabs mb-4">
                                                            <li className="buysell-tab">
                                                                <a className="">  Ongoing Orders   </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className="active"> All Orders </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className="">     Buy Orders   </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className=""> Sell Orders </a>
                                                            </li>
                                                        </ul>
                                                        <div className="filter_bar search-header px-0 g-2">
                                                            <form className="row align-items-center w-100 gx-2">
                                                                <div className="col-2">
                                                                    <select className="form-control form-select cursor-pointer">
                                                                        <option value="">  Order Status  </option>
                                                                        <option value="Completed"> All Status  </option>
                                                                        <option value="Pending"> Completed </option>
                                                                        <option value="Pending"> Pending </option>
                                                                        <option value="Pending"> Rejected </option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-solid"
                                                                        data-provide="datepicker"
                                                                        id="litepickerRangePlugin"
                                                                        name="dateFrom"
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-solid"
                                                                        data-provide="datepicker"
                                                                        id="litepickerRangePlugin"
                                                                        name="dateTo"

                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="row align-items-center gx-2">
                                                                        <div className="col">
                                                                            <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                Apply
                                                                            </button>
                                                                        </div>
                                                                        <div className="col">
                                                                            <button className="btn btn-light btn-block w-100" type="button" >
                                                                                <i className="ri-reset-left-line"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <label className="small text-dark mb-1" >  </label>
                                                            <div className="dd_search">
                                                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                <i className="fas fa-search"></i>
                                                            </div>

                                                        </div>
                                                        <div className="table-responsive m_table">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Coin Name</th>
                                                                        <th scope="col" className="text-end">Available Balance</th>
                                                                        <th scope="col" className="text-end">Locked Balance</th>
                                                                        <th scope="col" className="text-end">Hold Balance</th>
                                                                        <th scope="col" className="text-end">Total Balance</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                                                <span>USDT <br />
                                                                                    <small>Tether</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00000 </span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00 </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                                                <span>INR <br />
                                                                                    <small>INR</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00000 </span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00 </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    {/*  Spot Trades history tab  */}
                                                    <div className="tab-pane" id="SpotTrades" role="tabpanel" aria-labelledby="SpotTrades-tab">
                                                        <ul className="nav custom-tabs mb-4">
                                                            <li className="buysell-tab">
                                                                <a className=""> Open Orders   </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className="active"> Order History </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className="">  Trade History   </a>
                                                            </li> 
                                                        </ul>
                                                        <div className="filter_bar search-header px-0 g-2">
                                                            <form className="row align-items-center w-100 gx-2">
                                                                <div className="col-2">
                                                                    <select className="form-control form-select cursor-pointer">
                                                                        <option value="">  Order Side  </option>
                                                                        <option value="Completed"> All Status  </option>
                                                                        <option value="Pending"> Buy Orders </option>
                                                                        <option value="Pending">  Sell Orders </option> 
                                                                    </select>
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-solid"
                                                                        data-provide="datepicker"
                                                                        id="litepickerRangePlugin"
                                                                        name="dateFrom"
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-solid"
                                                                        data-provide="datepicker"
                                                                        id="litepickerRangePlugin"
                                                                        name="dateTo"

                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="row align-items-center gx-2">
                                                                        <div className="col">
                                                                            <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                Apply
                                                                            </button>
                                                                        </div>
                                                                        <div className="col">
                                                                            <button className="btn btn-light btn-block w-100" type="button" >
                                                                                <i className="ri-reset-left-line"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <label className="small text-dark mb-1" >  </label>
                                                            <div className="dd_search">
                                                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                <i className="fas fa-search"></i>
                                                            </div>

                                                        </div>
                                                        <div className="table-responsive m_table">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Coin Name</th>
                                                                        <th scope="col" className="text-end">Available Balance</th>
                                                                        <th scope="col" className="text-end">Locked Balance</th>
                                                                        <th scope="col" className="text-end">Hold Balance</th>
                                                                        <th scope="col" className="text-end">Total Balance</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                                                <span>USDT <br />
                                                                                    <small>Tether</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00000 </span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00 </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                                                <span>INR <br />
                                                                                    <small>INR</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00000 </span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00 </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> 

                                                    {/*  Sport orders history tab  */}
                                                    <div className="tab-pane" id="FutureTrades" role="tabpanel" aria-labelledby="FutureTrades-tab">
                                                        <ul className="nav custom-tabs mb-4">
                                                            <li className="buysell-tab">
                                                                <a className=""> Open Orders   </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className="active"> Order History </a>
                                                            </li>
                                                            <li className="buysell-tab">
                                                                <a className="">  Trade History   </a>
                                                            </li> 
                                                        </ul>
                                                        <div className="filter_bar search-header px-0 g-2">
                                                            <form className="row align-items-center w-100 gx-2">
                                                                <div className="col-2">
                                                                    <select className="form-control form-select cursor-pointer">
                                                                        <option value="">  Order Side  </option>
                                                                        <option value="Completed"> All Status  </option>
                                                                        <option value="Pending"> Buy Orders </option>
                                                                        <option value="Pending">  Sell Orders </option> 
                                                                    </select>
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-solid"
                                                                        data-provide="datepicker"
                                                                        id="litepickerRangePlugin"
                                                                        name="dateFrom"
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-solid"
                                                                        data-provide="datepicker"
                                                                        id="litepickerRangePlugin"
                                                                        name="dateTo"

                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="row align-items-center gx-2">
                                                                        <div className="col">
                                                                            <button className="btn btn-black btn-block w-100 px-4" type="button" >
                                                                                Apply
                                                                            </button>
                                                                        </div>
                                                                        <div className="col">
                                                                            <button className="btn btn-light btn-block w-100" type="button" >
                                                                                <i className="ri-reset-left-line"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <label className="small text-dark mb-1" >  </label>
                                                            <div className="dd_search">
                                                                <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                                <i className="fas fa-search"></i>
                                                            </div> 
                                                        </div>
                                                        <div className="table-responsive m_table">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Coin Name</th>
                                                                        <th scope="col" className="text-end">Available Balance</th>
                                                                        <th scope="col" className="text-end">Locked Balance</th>
                                                                        <th scope="col" className="text-end">Hold Balance</th>
                                                                        <th scope="col" className="text-end">Total Balance</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                                                <span>USDT <br />
                                                                                    <small>Tether</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00000 </span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00 </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                                                <span>INR <br />
                                                                                    <small>INR</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>0.00000 <br />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00000 </span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span> 0.00 </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                     {/*  Spot Trades history tab  */}
                                                     <div className="tab-pane" id="PTwoPTrades" role="tabpanel" aria-labelledby="PTwoPTrades-tab">
                                                        <h5> Data Not Available</h5>
                                                    </div>

                                                     {/*  Spot Trades history tab  */}
                                                     <div className="tab-pane" id="Invest" role="tabpanel" aria-labelledby="Invest-tab">
                                                        <h5 className="mx-auto w-100 text-denter d-block" > Data Not Available</h5>
                                                    </div> 

                                                </div> 
                                            </div> 


                                        </div>

                                    </div>

                                    {/* User support   */}
                                    <div className="tab-pane show active   " id="Usersupport" role="tabpanel" aria-labelledby="Usersupport-pill">
                                        <div className="card-body py-4" >
                                            <div className="profile_data  ac_body  " >
                                                <div className=" " >
                                                    <div className="custom-tab-2">
                                                        <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#OpenTickets" id="OpenTickets-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Open Tickets </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a href="#ClosedTickets" id="ClosedTickets-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">  Closed Tickets </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-content">
                                                    {/* crypto Tab  */}
                                                    <div className="tab-pane active" id="OpenTickets" role="tabpanel" aria-labelledby="OpenTickets-tab">


                                                        <div className="ticket_list" >
                                                            <div className="d-flex justify-content-between" >
                                                                <h5> Support Tickets (12) </h5>
                                                            </div>
                                                            <div className="table-responsive m_table">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className=""> Date/Time </th>
                                                                            <th scope="col" className=""> Ticket ID </th>
                                                                            <th scope="col" className=""> Subject </th>
                                                                            <th scope="col" className=""> Message  </th>
                                                                            <th scope="col" className=""> Image </th>
                                                                            <th scope="col" className=""> Status  </th>
                                                                            <th scope="col" className=""> Action </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="">
                                                                                <span> 11-12-2024 </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <span> ----  </span>
                                                                            </td>
                                                                            <td className="">
                                                                                <div className="d-flex">
                                                                                    <a href="/support" className="btn btn-dark shadow btn-xs sharp me-1"  >
                                                                                        <i className="ri-eye-line"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* User Logs   */}
                                    <div className="tab-pane    " id="userlogs" role="tabpanel" aria-labelledby="userlogs-pill">
                                        <div className="card-body py-4" >

                                            <div className="profile_data  ac_body  " >
                                                <div className="d-flex justify-content-between" >
                                                    <h5> User Logs </h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </form>
                </main>
            </div>





            {/* Edit Information Model Box */}

            <EditModal
                title={modalTitle}
                fields={modalFields}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={submitEditedDetails}
                modalId="editModal"
            />


        </>

    )


}

export default TraderDetails;