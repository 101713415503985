import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../ProfileContext";
import Swal from "sweetalert2";
import { alertErrorMessage } from "../CustomAlert";

const Header = () => {
    const navigate = useNavigate();

    const [, updateProfileState] = useContext(ProfileContext);
    const emailId = sessionStorage.getItem('emailId');


    const showAlert = () => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are about to log out`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e0bc28",
            confirmButtonText: "Yes Logout!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleLogout()
            }
        });
    };

    const handleLogout = () => {
        alertErrorMessage('You are logged out..!!');
        updateProfileState({});
        sessionStorage.clear();
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <nav className="topnav navbar navbar-expand justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
            <div className="header_left">
                <button className="hamburger" id="sidebarToggle" onClick={() => document.body.classList.toggle('sidenav-toggled')}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </button>
                <span className="navbar-brand pe-3 ps-4 ps-lg-3">
                    <img src="/assets/img/logo.svg" className="img-fluid" alt="logo" />
                </span>
            </div>
            <div className="h_bar">
                <div className="h_left" >
                    <div className="dd_search">
                        <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                        <i className="fas fa-search"></i>
                    </div>
                </div>
                <ul className="navbar-nav align-items-center navbar-nav header-right main-notification">
                    <li className="nav-item dropdown notification_dropdown">
                        <a className="nav-link ai-icon" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="ri-notification-3-line bell-icon ri-xl"></i>
                            <div className="pulse-css"></div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            <div id="dlab_W_Notification1" className="widget-media dz-scroll p-3 height380">
                                <ul className="timeline">
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="media me-2">KG</div>
                                            <div className="media-body">
                                                <h6 className="mb-1">Dr sultads Send you Photo</h6><small className="d-block">29 July 2020 - 02:26 PM</small></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="media me-2 media-info">KG</div>
                                            <div className="media-body">
                                                <h6 className="mb-1">Resport created successfully</h6><small className="d-block">29 July 2020 - 02:26 PM</small></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="media me-2 media-success"><i className="fa fa-home"></i></div>
                                            <div className="media-body">
                                                <h6 className="mb-1">Reminder : Treatment Time!</h6><small className="d-block">29 July 2020 - 02:26 PM</small></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="media me-2">KG</div>
                                            <div className="media-body">
                                                <h6 className="mb-1">Dr sultads Send you Photo</h6><small className="d-block">29 July 2020 - 02:26 PM</small></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="media me-2 media-danger">KG</div>
                                            <div className="media-body">
                                                <h6 className="mb-1">Resport created successfully</h6><small className="d-block">29 July 2020 - 02:26 PM</small></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="media me-2 media-primary"><i className="fa fa-home"></i></div>
                                            <div className="media-body">
                                                <h6 className="mb-1">Reminder : Treatment Time!</h6><small className="d-block">29 July 2020 - 02:26 PM</small></div>
                                        </div>
                                    </li>
                                </ul>
                            </div><span className="all-notification">See all notifications <i className="ri-arrow-right-line"></i></span></div>
                    </li>
                    <li className="nav-item dropdown header-profile">
                        <a className="nav-link" id="navbarDropdownUserImage" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="img-fluid" src="/assets/img/dummy.png" alt="" />
                            <div className="header-info">
                                <span>  Pardeep Yadav</span>
                                <small> Master Admin </small>
                            </div> 

                        </a>

                        <div className="dropdown-menu logg_dropdown dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                            
                            <a aria-current="page" href="#" className="router-link-active router-link-exact-active dropdown-item ai-icon">  <span className="ms-2">  <i className="ri-history-line"></i>   Activity logs </span></a>
                            
                            <div className="logg_col" >
                                <button type="button" className=" logout_row " onClick={showAlert} style={{ justifycontent: "center" }}>
                                    logout
                                    <div className="dropdown-item-icon ms-2"><i className="fa fa-power-off"></i></div>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
export default Header;