import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import { SlideshowLightbox } from 'lightbox.js-react'
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import copy from "copy-to-clipboard";


const KycDetailsPersonalAdvanced = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { row, kycStatus } = location.state || {};
  const [kycStep, setKycStep] = useState("step1");
  const [userKycDetails, setUserKycDetails] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectRemarks, setRejectRemarks] = useState("");
  const [isShowLiveAtt, setIsShowLiveAtt] = useState(false);
  const [isShowFaceAtt, setIsShowFaceAtt] = useState(false);
  const [isShowRejectedHist, setIsShowRejectedHist] = useState(false);
  const [rejectedSteps, setRejectedSteps] = useState([]);
  const [editingStep, setEditingStep] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [mainRejectModel, setMainRejectModel] = useState(false);
  const [reasonMessage, setReasonMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [verificationRemark, setVerificationRemark] = useState("");
  const [approvedRemark, setApprovedremark] = useState([]);
  const [showModelApprove, setShowModelApprove] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeTabOtherDeclarattion, setActiveTabOtherDeclarattion] = useState(0);
  const [activeTabLivenessRejected, setActiveTabLivenessRejected] = useState(0);
  const [activeTabLiveness, setActiveTabLiveness] = useState(0);
  const [activeTabEducation, setActiveTabEducation] = useState(0);
  const [activeTabFaceMatch, setActiveTabFaceMatch] = useState(0);

  const [userKycStatus, setUserKycStatus] = useState([]);



  const [activeTabPanAttemp, setActiveTabPanAttempt] = useState(0);
  const [activeTabRejectedPan, setActiveTabRejectedPan] = useState(0);


  const [panAttemps, setPanAttemps] = useState(null);
  const [rejectedPan, setRejectedPan] = useState();
  const [rejectedAadhaar, setRejectedAadhaar] = useState();
  const [livenessAttemps, setLivenessAttemps] = useState(null);
  const [facematchAttemps, setFacematchAttemps] = useState(null);

  const [rejectedLiveness, setRejectedLiveness] = useState();
  const [rejectedEducation, setRejectedEducation] = useState();
  const [rejectedDeclaration, setRejectedDeclaration] = useState();




  const handleTabClickPanAttemp = (index, data) => {
    setActiveTabPanAttempt(index);
    setPanAttemps(data);
  };

  const handleTabClickRejectedPan = (index, data) => {
    setActiveTabRejectedPan(index);
    setRejectedAadhaar(data)
  };

  const handleTabClickLiveness = (index, data) => {
    setActiveTabLiveness(index);
    setLivenessAttemps(data);
  };

  const handleTabClickFaceMatch = (index, data) => {
    setActiveTabFaceMatch(index);
    setFacematchAttemps(data);
  };

  const handleTabClickRejectedLiveness = (index, data) => {
    setActiveTabLivenessRejected(index);
    setRejectedLiveness(data);
  };

  useEffect(() => {
    setPanAttemps(userKycDetails?.otherPan?.[activeTabPanAttemp])
    setRejectedPan(userKycDetails?.otherPan?.[activeTabRejectedPan])
    setRejectedAadhaar(userKycDetails?.otherAadhar?.[activeTabRejectedPan])
    setLivenessAttemps(userKycDetails?.livenessAttempts?.[activeTabLiveness])
    setFacematchAttemps(userKycDetails?.faceMatchAttempts?.[activeTabFaceMatch])
    setRejectedLiveness(userKycDetails?.rejectedHistory?.[activeTabLivenessRejected])
  }, [userKycDetails])




  const fetchUserDetails = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getStepUserData(row?._id, kycStep).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserKycDetails(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    }
    );
  };


  const handleuserKycStatus = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCurrentKycStatus(row?._id).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserKycStatus(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    }
    );
  };


  const handleKycApproveRemark = async (verificationRemark) => {
    if (!verificationRemark) {
      setErrorMessage("Please Enter Approval Remark");
      return;
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.kycVerifyRemark(verificationRemark, row?._id).then(
      async (result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          try {
            alertSuccessMessage(result?.message);
            setShowModelApprove(false);
            navigate("/personal_kyc", { state: { kycStatus: kycStatus } });
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleGetApprovedRemark = async () => {
    await AuthService.getVerifyRemark(row?._id).then(
      async (result) => {
        if (result?.success) {
          try {
            setApprovedremark(result?.data);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleRejectKyc = async (selectedOption, reason) => {
    if (selectedOption === "reject_all") {
      if (!reason) {
        setErrorMessage("Please enter a reason");
        return;
      }
    }
    setLoading(true);
    await AuthService.rejectUserKycStep(selectedOption, reason, rejectedSteps, row?._id, reasonMessage).then(async (result) => {
      setLoading(false);
      if (result.success) {
        setShowModel(false);
        try {
          fetchUserDetails();
          setReasonMessage("");
          setRejectedSteps([]);
          setMainRejectModel(false);
          navigate("/personal_kyc", { state: { kycStatus: kycStatus } });
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result);
      }
    })
      .catch((error) => {
        setLoading(false);
        alertErrorMessage(error);
      });
  };

  useEffect(() => {
    if (rejectedSteps?.length >= 1) {
      setSelectedOption("partial_reject");
    } else {
      setSelectedOption("reject_all");
    }
  }, [rejectedSteps]);







  const handleDownloadAmlDetails = () => {
    if (userKycDetails?.otherAmlData?.length > 0 || userKycDetails?.latestAmlData) {
      const otherAmlData = (userKycDetails?.otherAmlData || []).map(item => ({
        ...item,
        Name: "Other Aml Data"
      }));
      const latestAmlData = userKycDetails?.latestAmlData
        ? [{ ...userKycDetails.latestAmlData, Name: "Latest Aml Data" }]
        : [];
      const amlDetails = {
        LatestAmlData: latestAmlData,
        OtherAmlData: otherAmlData,
      };
      const amlDataString = JSON.stringify(amlDetails, null, 2);
      const blob = new Blob([amlDataString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "aml_details.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      alertErrorMessage("No Data Available");
    }
  };



  // const handleDownloadAmlDetails = async () => {
  //   LoaderHelper.loaderStatus(true);
  //   try {
  //     const result = await AuthService.getuserAmlCheck(row?._id);
  //     LoaderHelper.loaderStatus(false);

  //     if (result?.success) {
  //       const amlData = result?.data;
  //       const amlDataString =
  //         typeof amlData === "string" ? amlData : JSON.stringify(amlData, null, 2);
  //       const blob = new Blob([amlDataString], { type: "text/plain" });
  //       const url = URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = "aml_details.txt";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     } else {
  //       alertErrorMessage(result.message);
  //     }
  //   } catch (error) {
  //     LoaderHelper.loaderStatus(false);
  //     alertErrorMessage(error.message || "An error occurred while downloading AML details.");
  //   }
  // };


  useEffect(() => {
    fetchUserDetails();
    handleuserKycStatus();
  }, [row?._id, kycStep])









  const rejectedPans = userKycDetails?.otherPan?.[activeTabRejectedPan];


  const rejectedDataEducation = userKycDetails?.otherEducationDetails?.[activeTabEducation]
  const handleTabClickRejectEduction = (index) => {
    setActiveTabEducation(index);
  };








  const selectedDataDeclaration = userKycDetails?.otherDeclarationData?.[activeTabOtherDeclarattion]
  const handleOtherDeclarattion = (index) => {
    setActiveTabOtherDeclarattion(index);
  };



  const stepNames = {
    step1: "Proof of Identification",
    step2: "Check Liveness",
    step3: "Education & Occupation",
  };

  const storeStepRejectedReason = (rejectRemarks) => {
    if (!rejectRemarks) {
      setErrorMessage("Please enter a reason")
      return;
    }
    const newRemark = {
      kycStep,
      stepName: stepNames[kycStep],
      reason: rejectRemarks,
    };
    setRejectedSteps((prev) => [...prev, newRemark]);
    setShowModel(false);
    setRejectRemarks("");
  };


  const filterSteps = rejectedSteps?.filter(step => step.kycStep == kycStep)[0];

  const handleEditRemark = (stepData) => {
    setMainRejectModel(false);
    setEditingStep(stepData);
    setShowEditModal(true);
  };

  const saveEditedRemark = (updatedStep) => {
    const updatedSteps = rejectedSteps.map((step) =>
      step.kycStep === updatedStep.kycStep ? updatedStep : step
    );
    setRejectedSteps(updatedSteps);
    setShowEditModal(false);
    setMainRejectModel(true);
  };


  const handleClickStep = (step) => {
    setKycStep(step);
    if (step === "step4") {
      handleGetApprovedRemark();
    }
  };

  const handleCopy = (item) => {
    copy(item);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header ">
              <div className="container-xl px-4">
                <div className="page-header-content pb-2">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <span className="page-header-icon cursor-pointer" onClick={() => navigate('/personal_kyc', { state: { kycStatus: kycStatus } })}>
                          <i className="fa fa-arrow-left" ></i>
                        </span>
                        <div className="hd_u_info" >
                          <img
                            src={!row?.user_selfie ? "assets/img/illustrations/profiles/profile-5.png" : ApiConfig?.appUrl + row?.user_selfie} alt="" className="img-account-profile rounded-circle"
                            onError={(e) => {
                              e.target.src = "assets/img/illustrations/profiles/profile-5.png";
                            }}
                          />
                          <span>  {row?.name || "-------"}
                            <small>
                              User ID: {row?.uin || "-------"}
                              <i
                                className={`ms-1 link cursor-pointer ${copied ? "ri-check-fill text-success" : "ri-file-copy-fill"
                                  }`}
                                onClick={() => handleCopy(row?.uin)}
                              ></i>
                            </small>
                          </span>
                        </div>
                      </h1>
                    </div>
                    <div className="col-auto">
                      <div className="d-flex">

                        {
                          kycStatus !== "not-submitted" && kycStatus !== "rejected" && kycStatus !== "re-kyc" &&
                          <>
                            {
                              kycStep === "step2" &&
                              <>
                                <button className="btn btn-danger btn-block btn-md" type="button" onClick={() => setMainRejectModel(true)}>Reject KYC</button>
                              </>
                            }
                            {
                              kycStatus !== "approved" && kycStep === "step2" &&
                              <button className="btn mx-2 btn-success btn-block btn-md" type="button" onClick={() => setShowModelApprove(true)}>
                                Approve
                              </button>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="custom-tab-2 d-flex justify-content-start ">
                    <ul className="nav nav-pills justify-content-end mt-4 mb-0" role="tablist">
                      <li className="nav-item" role="presentation">
                        <Link to="/kyc_detail_personal" className="nav-link ">  Level 1 KYC </Link>
                      </li>
                      <li className="nav-item" role="presentation">
                        <Link to="/kyc_detail_personal_advanced" className="nav-link active">  Level 2 KYC    </Link>
                      </li>
                    </ul>
                  </div>


                </div>
              </div>
            </header>

            <div className="container-xl px-4">

              <div className="row">
                <div className="col-xl-12 mb-4">
                  <ul id="progressbar" >
                    <li className={`cursor-pointer ${kycStep === "step1" ? "active" : ""}  ${userKycStatus?.[0]?.status === "complete" ? "success" : userKycStatus?.[0]?.status === "pending" ? "warning" : userKycStatus?.[0]?.status === "rejected" ? "danger" : userKycStatus?.[0]?.status === "not-submitted" ? "current" : ""}`} id="step1" onClick={() => handleClickStep("step1")}>
                      <strong> Documents  </strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step2" ? "active" : ""} ${userKycStatus?.[3]?.status === "complete" ? "success" : userKycStatus?.[1]?.status === "pending" ? "warning" : userKycStatus?.[2]?.status === "rejected" ? "danger" : userKycStatus?.[2]?.status === "not-submitted" ? "current" : ""}`} id="step2" onClick={() => handleClickStep("step2")}>
                      <strong>  </strong>
                    </li>
                    <li className={`cursor-pointer ${kycStep === "step3" ? "active" : ""} ${userKycStatus?.[3]?.status === "complete" ? "success" : userKycStatus?.[3]?.status === "pending" ? "warning" : userKycStatus?.[3]?.status === "rejected" ? "danger" : userKycStatus?.[3]?.status === "not-submitted" ? "current" : ""}`} id="step3" onClick={() => handleClickStep("step3")}>
                      <strong>Declaration and Submission</strong>
                    </li>
                  </ul>
                  <div className="card">
                    <div className="card-body py-4">
                      <div className="">
                        <div className="profile_data w-100  ac_body px-4 pt-4 ">
                          {/* step 1 */} 
                          <div className={`step_1 ${kycStep === "step1" ? "" : "d-none"}`} >
                            <div className="row g-4 justify-content-center">
                              <div className="col-xl-8">
                                <div className="d-flex align-items-center justify-content-between" >
                                  <h5> Documents List  </h5>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" > ITR Declaration </h5>
                                  <button className="btn btn-primary btn-md" > <i class="ri-eye-line me-2"></i> View Document </button>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Bank Statment (6 Months) </h5>
                                  <button className="btn btn-primary btn-md" > <i class="ri-eye-line me-2"></i> View Document </button>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >    Salary Slip (6 Months) </h5>
                                  <button className="btn btn-primary btn-md" > <i class="ri-eye-line me-2"></i> View Document </button>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >   Video KYC with PAN </h5>
                                  <button className="btn btn-primary btn-md" > <i class="ri-eye-line me-2"></i> Watch Video </button>
                                </div> 
                              </div>
                            </div>


                            <div className="att_row mt-5">
                              <h5>Rejected History</h5>
                              <div className="att_card">
                                {/* Tabs */}
                                <div className="nav nav-tabs nav-tabs-custom px-2">
                                  <Tabs
                                    className="nav nav-tabs nav-tabs-custom px-2"
                                    activeTab={activeTabRejectedPan}
                                  >
                                    {userKycDetails?.otherAadhar?.map((attempt, index) => (
                                      <Tab
                                        key={attempt?._id}
                                        className={`nav-link ${activeTabRejectedPan === index ? "active" : ""}`}
                                        onTabClick={() => handleTabClickRejectedPan(index, attempt)}
                                      >
                                        {attempt.admin_rejected_date
                                          ? moment(attempt.admin_rejected_date).format('Do MM YYYY, h:mm:ss a')
                                          : "-------"
                                        }
                                      </Tab>
                                    ))}
                                  </Tabs>

                                </div>

                                {/* Selected Tab Details */}
                                <div className="att_col">
                                  <>
                                    <div className="row g-3">
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> ITR Declaration </label>
                                          <p className="mt-2" >
                                            <button className=" btn btn-outline-dark  btn-xs" > View Document </button>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Bank Statment (6 Months)  </label>
                                          <p className="mt-2" >
                                            <button className=" btn btn-outline-dark  btn-xs" > View Document </button>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>Salary Slip (6 Months)</label>
                                          <p className="mt-2" >
                                            <button className=" btn btn-outline-dark  btn-xs" > View Document </button>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Video KYC with PAN </label>
                                          <p className="mt-2" >
                                            <button className=" btn btn-outline-dark  btn-xs" > View Document </button>
                                          </p>
                                        </div>
                                      </div>  
                                    </div>
                                  </>

                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step2")}>
                                Next
                              </button>
                              {
                                kycStatus !== "not-submitted" &&
                                kycStatus !== "rejected" &&
                                kycStatus !== "re-kyc" &&
                                filterSteps?.kycStep !== kycStep &&
                                (
                                  <button
                                    className="btn btn-md btn-danger px-4"
                                    type="button"
                                    onClick={() => setShowModel(true)}>
                                    Reject Reamrk
                                  </button>
                                )
                              }
                            </div>
                          </div> 
                          
                          {/* step 1 */} 
                          <div className={`step_2 ${kycStep === "step2" ? "" : "d-none"}`} >
                            <div className="row g-4 justify-content-center">
                              <div className="col-xl-8">
                                <div className="d-flex align-items-center justify-content-between" >
                                  <h5> Documents List  </h5>
                                </div> 
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Desired Total (FY) Account Withdrawal Limit (INR)   </h5>
                                  <p className="mb-0 text-dark" > <b>50,000,000 INR</b> </p>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >  Desired Daily Withdrawal Limit (INR)   </h5>
                                  <p className="mb-0 text-dark" > <b>50,000,000 INR</b> </p>
                                </div>
                                <div className="fc_details mb-2 py-3 d-flex align-items-center justify-content-between " >
                                  <h5 className="mb-0" >Desired  Daily  Quick Buy Limit (INR) </h5>
                                  <p className="mb-0 text-dark" > <b>50,000,000 INR</b> </p>
                                </div>
                                <div className="fc_details mb-2 py-3  " >
                                  <h5 className="mb-2 " >  Reason to limit Enhancement </h5>
                                  <p className="mb-0" > <small>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</small></p>
                                </div>
                              </div>
                            </div>


                            <div className="att_row mt-5">
                              <h5>Rejected History</h5>
                              <div className="att_card">
                                {/* Tabs */}
                                <div className="nav nav-tabs nav-tabs-custom px-2">
                                  <Tabs
                                    className="nav nav-tabs nav-tabs-custom px-2"
                                    activeTab={activeTabRejectedPan}
                                  >
                                    {userKycDetails?.otherAadhar?.map((attempt, index) => (
                                      <Tab
                                        key={attempt?._id}
                                        className={`nav-link ${activeTabRejectedPan === index ? "active" : ""}`}
                                        onTabClick={() => handleTabClickRejectedPan(index, attempt)}
                                      >
                                        {attempt.admin_rejected_date
                                          ? moment(attempt.admin_rejected_date).format('Do MM YYYY, h:mm:ss a')
                                          : "-------"
                                        }
                                      </Tab>
                                    ))}
                                  </Tabs>

                                </div>

                                {/* Selected Tab Details */}
                                <div className="att_col">
                                  <>
                                    <div className="row g-3"> 
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>  Desired Total (FY) Account Withdrawal Limit (INR) </label>
                                          <p className="mt-2" >
                                            -----
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>  Desired Daily Withdrawal Limit </label>
                                          <p className="mt-2" >
                                            -----
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Desired Daily Quick Buy Limit (INR) </label>
                                          <p className="mt-2" >
                                            -----
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-xl-12">
                                        <div className="label-group">
                                          <label> Reason to limit Enhancement</label>
                                          <p className="fw-normal" >
                                            <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>

                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step2")}>
                                Next
                              </button>
                              {
                                kycStatus !== "not-submitted" &&
                                kycStatus !== "rejected" &&
                                kycStatus !== "re-kyc" &&
                                filterSteps?.kycStep !== kycStep &&
                                (
                                  <button
                                    className="btn btn-md btn-danger px-4"
                                    type="button"
                                    onClick={() => setShowModel(true)}>
                                    Reject Reamrk
                                  </button>
                                )
                              }
                            </div>
                          </div>

                          {/* step 2*/}
                          <div className={`step_3 ${kycStep === "step3" ? "" : "d-none"}`}>
                            <div className=" mb-4" >
                              <h4 className=" " > <small>Declaration of Source of Funds </small>  </h4>
                              <ul className="desc mb-0">
                                <li className="mb-2 d-block">
                                  <b>[{userKycDetails?.latestDeclarationData?.name || "-------"}]</b>, a citizen of India, holding Aadhar Number <b>[{userKycDetails?.latestDeclarationData?.aadhar || "-------"}]</b> and PAN Card number <b>[{userKycDetails?.latestDeclarationData?.pan || "-------"}]</b>,  hereby declare and affirm that all funds used in my financial transactions, with CRYPTO INDIA (Incryp Blockchain Private Limited) in INR and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                </li>
                                <li className="mb-2">We further declare that:</li>
                                <li className="mb-2 d-block">   1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.</li>
                                <li className="mb-2 d-block"> 2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities</li>
                                <li className="mb-2 d-block">   3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.</li>
                                <li className="mb-2 d-block">4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.</li>
                                <li className="mb-2 d-block"> 5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.</li>
                              </ul>

                              <div className="row g-3 mt-3" >
                                <div className="col-lg-6" >
                                  <p className="fc_details p-3 d-flex justify-content-center align-items-center" >
                                    <small> Level 2 T&C Declared on: &nbsp;
                                      <b className="text-dark" >
                                        {userKycDetails?.latestDeclarationData?.createdAt
                                          ? moment(userKycDetails?.latestDeclarationData?.createdAt).format('Do MMM YYYY,h:mm:ss a')
                                          : "-------"
                                        }
                                      </b>
                                      {userKycDetails?.latestDeclarationData?.createdAt &&
                                        <i className="ri-checkbox-circle-fill text-success ms-2"></i>
                                      }
                                    </small>
                                  </p>
                                </div>
                                <div className="col-lg-6" >
                                  <p className="fc_details p-3 d-flex justify-content-center align-items-center" >
                                    <small>Level 2 KYC Approved on: &nbsp;
                                      {
                                        userKycDetails?.latestDeclarationData?.auto_approved_date ?
                                          <>
                                            <b className="text-dark" >
                                              {userKycDetails?.latestDeclarationData?.auto_approved_date
                                                ? moment(userKycDetails?.latestDeclarationData?.auto_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                : "-------"
                                              }
                                            </b>&nbsp;
                                            {userKycDetails?.latestDeclarationData?.auto_approved_date &&
                                              <i className="ri-checkbox-circle-fill text-success"></i>
                                            }
                                          </>
                                          :
                                          <>
                                            <b className="text-dark" >
                                              {userKycDetails?.latestDeclarationData?.declaration_approved_date
                                                ? moment(userKycDetails?.latestDeclarationData?.declaration_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                : "-------"
                                              }
                                            </b>&nbsp;
                                            {userKycDetails?.latestDeclarationData?.declaration_approved_date &&
                                              <i className="ri-checkbox-circle-fill text-success"></i>
                                            }
                                          </>
                                      }
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row hns_row_two  mt-5" >
                              <div className="col-lg-6">
                                <div className="att_row mt-0" >
                                  <h5 className="mb-2" > <small>Rejected History</small></h5>
                                  <div className="att_card" >

                                    <div className="nav nav-tabs nav-tabs-custom px-2">
                                      {userKycDetails?.otherDeclarationData?.map((attempt, index) => (
                                        <button
                                          key={index}
                                          className={`nav-link ${activeTabOtherDeclarattion === index ? 'active' : ''}`}
                                          onClick={() => handleOtherDeclarattion(index)}
                                          type="button"
                                        >
                                          {attempt.declaration_rejected_date
                                            ? moment(attempt.declaration_rejected_date).format('Do MM YYYY, h:mm:ss a')
                                            : "-------"
                                          }
                                        </button>
                                      ))}
                                    </div>

                                    <div className="att_col" >
                                      {selectedDataDeclaration ? (
                                        <div className="row g-4 align-items-center">
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Declared Date & Time </label>
                                              <p>
                                                <small>
                                                  {selectedDataDeclaration.createdAt
                                                    ? moment(selectedDataDeclaration.createdAt).format('Do MM YYYY, h:mm:ss a')
                                                    : "-------"
                                                  }
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Full Name </label> <p> <small> {selectedDataDeclaration.name || "-------"}  </small> </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Aadhar Number </label> <p> <small> {selectedDataDeclaration.aadhar || "-------"}  </small> </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xl-6">
                                            <div className="label-group">
                                              <label> Pan Number </label> <p> <small> {selectedDataDeclaration.pan || "-------"}  </small> </p>
                                            </div>
                                          </div>


                                          <small>Approved on: &nbsp;
                                            {
                                              selectedDataDeclaration?.auto_approved_date ?
                                                <>
                                                  <b className="text-dark" >
                                                    {selectedDataDeclaration?.auto_approved_date
                                                      ? moment(selectedDataDeclaration?.auto_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                      : "-------"
                                                    }
                                                  </b>&nbsp;
                                                  {selectedDataDeclaration?.auto_approved_date &&
                                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                                  }
                                                </>
                                                :
                                                <>
                                                  <b className="text-dark" >
                                                    {userKycDetails?.latestDeclarationData?.declaration_approved_date
                                                      ? moment(userKycDetails?.latestDeclarationData?.declaration_approved_date).format('Do MMM YYYY,h:mm:ss a')
                                                      : "-------"
                                                    }
                                                  </b>&nbsp;
                                                  {userKycDetails?.latestDeclarationData?.declaration_approved_date &&
                                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                                  }
                                                </>
                                            }
                                          </small>



                                        </div>
                                      ) :
                                        <div className="fc_details d-flex justify-content-center align-items-center">
                                          <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="col-lg-3" >
                                {/* Rejection Remark */}
                                <h5 className="mb-2" > <small>Rejection Remark </small></h5>
                                <div className="re_row" >
                                  {
                                    userKycDetails?.stepRejectedData ? (
                                      userKycDetails?.stepRejectedData?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row">
                                            <div className="col-6">
                                              <h5>
                                                <span>{item?.admin_name}</span>
                                                <small>{item?.admin_id}</small>
                                              </h5>
                                            </div>
                                            <div className="col-6">
                                              <h5 className="text-end">
                                                <small>Date & Time</small>

                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.reason?.remarks || item?.remark}
                                          </p>
                                        </div>
                                      ))
                                    ) : null
                                  }
                                </div>

                              </div>

                              <div className="col-lg-3" >
                                <h5 className="mb-2" > <small>Approved Remark</small> </h5>
                                <div className="re_row ap_row" >
                                  {
                                    approvedRemark ? (
                                      approvedRemark?.map((item, index) => (
                                        <div className="rm_col" key={index}>
                                          <div className="row" >
                                            <div className="col-6" >
                                              <h5> <span> {item?.admin_name === "null" ? "Crypto India" : item?.admin_name} <small>
                                                {item?.admin_id === "null" ? "Crypto India System" : item?.admin_id}</small> </span> </h5>
                                            </div>
                                            <div className="col-6" >
                                              <h5 className="text-end" ><small>Date & Time</small>
                                                {item?.timestamp
                                                  ? (
                                                    <>
                                                      {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                      <br />
                                                      {moment(item?.timestamp).format('h:mm:ss a')}
                                                    </>
                                                  )
                                                  : "-------"}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            <b>Remark : </b>
                                            {item?.remark || item?.reason || "-------"}
                                          </p>
                                        </div>
                                      ))
                                    ) : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="puk_row puk_row_fixed" >
                              <button className="btn btn-md btn-gray px-4" type="button" onClick={() => handleClickStep("step1")}>
                                Prev.
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>


      {showModel && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" > Are you sure you want to reject the {kycStep} details?</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Enter Reason/Message </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={rejectRemarks}
                      onChange={(e) => {
                        setRejectRemarks(e.target.value);
                        setErrorMessage("");
                      }}>
                    </textarea>
                    {errorMessage && (
                      <div className="text-danger mt-2 small">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <button className="btn btn-danger  btn-block w-100" type="button" onClick={() => storeStepRejectedReason(rejectRemarks)}>
                  <span> Submit </span>
                </button>
                <button className="btn btn-secondry  btn-block w-100 mt-1" type="button" onClick={() => setShowModel(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}

      {
        mainRejectModel && (
          <>
            <div className="modal show d-block">
              <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title"><>{row?.first_name}</> Are You Sure?</h5>
                    <button className="btn-close" type="button" onClick={() => setMainRejectModel(false)}></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group mb-4" >
                        <label className="small">
                          Select Steps
                        </label>
                        <select
                          className="form-control form-select"
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                        /* disabled={rejectedSteps?.length >= 1} */
                        >
                          <option value="reject_all">All</option>
                          <option value="partial_reject">Partial Rejection</option>
                          <option value="rekyc">Re-KYC</option>
                        </select>
                      </div>
                      {
                        selectedOption !== "reject_all" && selectedOption !== "rekyc" &&
                        <div className="form-group pc_row" >
                          {
                            rejectedSteps?.length > 0 ? (
                              rejectedSteps?.map((item, index) => (
                                <div className="pc_col" key={index}>
                                  <div className="d-flex aliogn-items-center justify-content-between mb-3" >
                                    <h6 className="mb-0" >  <b>{item?.kycStep} </b>-  {item?.stepName} </h6>
                                    <button className="btn btn-xs btn-danger" type="button" onClick={() => handleEditRemark(item)}> Edit Remark </button>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    {item?.reason}
                                  </p>
                                </div>
                              ))
                            ) : null
                          }
                        </div>
                      }


                      {/*  {
                        selectedOption !== "partial_reject" && */}
                      <div className="form-group mb-4 ">
                        <label className="small mb-1"> Enter Reason/Message </label>
                        <textarea className="form-control py-3" rows="7" placeholder="Enter your message.." value={reasonMessage}
                          onChange={(e) => {
                            setReasonMessage(e.target.value);
                            setErrorMessage("");
                          }}
                        ></textarea>
                        {errorMessage && (
                          <div className="text-danger mt-2 small">{errorMessage}</div>
                        )}
                      </div>
                      {/*   } */}
                      {
                        rejectedSteps?.length == 0 && selectedOption === "partial_reject" ?
                          <button className="btn btn-danger btn-block w-100" type="button" onClick={() => { setMainRejectModel(false); handleClickStep("step1") }}>
                            Add Remark
                          </button>
                          :
                          <button className="btn btn-danger btn-block w-100" type="button" onClick={() => handleRejectKyc(selectedOption, reasonMessage)}>
                            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              : <span> Continue </span>}
                          </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        )}

      {showEditModal && editingStep && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" > Are you sure you ?</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Edit Remark for {editingStep.kycStep} </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={editingStep.reason} onChange={(e) => setEditingStep({ ...editingStep, reason: e.target.value })}></textarea>
                  </div>
                </div>
                <div>
                  <button className="btn btn-danger  btn-block w-100" type="button"
                    onClick={() => saveEditedRemark(editingStep)}
                  >
                    Save
                  </button>
                  <button className="btn btn-secondry  btn-block w-100 mt-1" type="button" onClick={() => { setShowEditModal(false); setMainRejectModel(true) }}>
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}

      {showModelApprove && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" >You are about to approve the KYC.</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Enter Kyc Approval Remark </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your Remark.." name="verificationRemark" value={verificationRemark}
                      onChange={(e) => {
                        setVerificationRemark(e.target.value);
                        setErrorMessage("");
                      }}>
                    </textarea>
                    {errorMessage && (
                      <div className="text-danger mt-2 small">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <button className="btn btn-success  btn-block w-100" type="button" onClick={() => handleKycApproveRemark(verificationRemark)}>
                  {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <span> Submit </span>}
                </button>
                <button className="btn btn-secondry  btn-block w-100 mt-1" type="button" onClick={() => setShowModelApprove(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}


    </>

  );
};

export default KycDetailsPersonalAdvanced;
