import React from 'react';

const EditModal = ({ title, fields, formData, handleChange, handleSubmit, modalId }) => {
  return (
    <div className="modal" id={modalId} tabIndex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row gx-3 mb-3">
                {fields.map((field, index) => (
                  <div className="col-md-12 mb-3" key={index}>
                    <label className="small mb-1" htmlFor={field.id}>{field.label}</label>
                    <input
                      className="form-control  "
                      id={field.id}
                      type={field.type}
                      placeholder={field.placeholder}
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={handleChange}
                    />
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center w-100 mt-3">
                <button className="btn btn-dark  w-100 btn-lg btn-block" type="button" onClick={handleSubmit}>
                  Update Details
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;