import React from "react";
import { Link } from "react-router-dom";

const Permissions = () => {




    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <form className="form-data" >
                        <header className="page-header ">
                            <div className="container-xl px-4">
                                <div className="page-header-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h1 className="page-header-title">
                                            Permissions                               
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                       
                    </form>
                </main>
            </div>
        </>

    )


}

export default Permissions;