import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { alertErrorMessage, alertSuccessMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import moment from "moment";

const NewBuysell = () => {

    const [buySellSide, setBuySellSide] = useState('');
    const [baseCurrency, setBaseCurrency] = useState('');
    const [quoteCurrency, setQuoteCurrency] = useState('');
    const [price, setPrice] = useState('');
    const [minimumPrice, setMinimumPrice] = useState('');
    const [maximumPrice, setMaximumPrice] = useState('');
    const [baseCurrencyId, setBaseCurrencyId] = useState('');
    const [quoteCurrencyId, setQuoteCurrencyId] = useState('');
    const [activeTab, setActiveTab] = useState('BUY');
    const [coinList, setCoinList] = useState([]);
    const [buySellData, setBuySellData] = useState([]);
    const [coinImageBase, setCoinImageBase] = useState();
    const [coinImageQuote, setCoinImageQuote] = useState();



    const handleCoinList = async () => {
        await AuthService.getCoinList().then(async (result) => {
            if (result.success) {
                try {
                    setCoinList(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    useEffect(() => {
        if (baseCurrency) {
            let filterItem = coinList?.filter((item) => {
                return item?.short_name === baseCurrency
            })
            setBaseCurrencyId(filterItem[0]?._id)
        }

        if (quoteCurrency) {
            let filterItem = coinList?.filter((item) => {
                return item?.short_name === quoteCurrency
            })
            setQuoteCurrencyId(filterItem[0]?._id)
        }

    }, [baseCurrency, quoteCurrency]);


    const handleAddBuySell = async (buySellSide, baseCurrency, quoteCurrency, price, minimumPrice, maximumPrice, coinImageBase, coinImageQuote) => {
        if (!buySellSide || !baseCurrency || !quoteCurrency || !price || !minimumPrice || !maximumPrice || !coinImageBase || !coinImageQuote) {
            alertErrorMessage("Please fill all the required fields.");
            return;
        }
        var formData = new FormData();
        formData.append('side', buySellSide);
        formData.append('base_currency', baseCurrency);
        formData.append('quote_currency', quoteCurrency);
        formData.append('base_currency_id', baseCurrencyId);
        formData.append('quote_currency_id', quoteCurrencyId);
        formData.append('price', price);
        formData.append('quote_min', minimumPrice);
        formData.append('quote_max', maximumPrice);
        formData.append('base_currency_image', coinImageBase);
        formData.append('quote_currency_image', coinImageQuote);
        await AuthService.AddQuickyBuySell(formData).then(async (result) => {
            if (result.success) {
                try {
                    alertSuccessMessage(result?.message);
                    handleReset();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        });
    };


    const handleBuysellList = async (activeTab) => {
        await AuthService.getQbsList(activeTab || "BUY").then(async (result) => {
            if (result.success) {
                try {
                    setBuySellData(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const handleDeleteQbs = async (id) => {
        await AuthService.deleteQbs(id).then(async (result) => {
            if (result.success) {
                try {
                    alertSuccessMessage(result?.message)
                    handleBuysellList(activeTab);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    useEffect(() => {
        handleCoinList();
        handleBuysellList(activeTab);
    }, [activeTab]);

    const handleReset = () => {
        setBuySellSide('');
        setBaseCurrency('');
        setQuoteCurrency('');
        setPrice('');
        setMinimumPrice('');
        setMaximumPrice('');
        setBaseCurrencyId('');
        setQuoteCurrencyId('');
    }

    const columns = [
        { name: "Base Currency", selector: (row) => row?.base_currency, wrap: true },
        { name: "Quote Currency", sort: true, wrap: true, selector: (row) => row?.quote_currency || '---' },
        { name: "Price", sort: true, wrap: true, selector: (row) => parseFloat(row?.price) || '---' },
        { name: "Min Price", sort: true, wrap: true, selector: (row) => row?.quote_min || '---' },
        { name: "MAx Price", sort: true, selector: (row) => row?.quote_max || '---' },
        { name: "createdAt", sort: true, selector: (row) => row?.createdAt ? moment(row?.createdAt).format("MMM Do YY") : '---' },
        {
            name: "Action", selector: (row) =>
                <button className="btn btn-danger btn-sm me-2" type="button" onClick={() => handleDeleteQbs(row?._id)}>Delete</button>
        },
    ];

    console.log(buySellData, 'buySellData');


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between mt-4">
                                <div className="col-auto ">
                                    <h1 className="page-header-title mb-0"> 
                                        Create Orders
                                    </h1>
                                </div>
                                {/* <div className="col-auto" >
                                    <div className="header_search form-group mb-0" >
                                        <input className="form-control mb-0 " id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4">
                    <div className="row" >
                        <div className="col-xl-12">
                            <div className="card mb-4 mb-xl-0">
                                <div className="card-header" >
                                <h5 className="mb-0" >Add Buy Sell Details</h5>
                                </div>
                                <div className="card-body d-flex justify-content-center flex-column p-5"> 
                                    <form>
                                        <div className="mb-3 form-group">
                                            <label className="small mb-1" htmlFor="buySellSide">Buy Sell Side </label>
                                            <select className="form-control form-control-solid form-select" id="buySellSide" name="buySellSide" value={buySellSide} onChange={(e) => setBuySellSide(e.target.value)}>
                                                <option>Select Side</option>
                                                <option value="BUY">BUY</option>
                                                <option value="SELL">SELL</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 form-group">
                                            <label className="small mb-1" htmlFor="baseCurrency">Base Currency </label>
                                            <select className="form-control form-control-solid form-select" id="baseCurrency" name="baseCurrency" value={baseCurrency} onChange={(e) => setBaseCurrency(e.target.value)} >
                                                <option>Select Base Currency</option>
                                                {coinList.length > 0 ? coinList?.map((item, index) => (
                                                    <option key={index} value={item?.short_name}>
                                                        {item?.short_name}
                                                    </option>
                                                )) : undefined}
                                            </select>
                                        </div>
                                        <div className="mb-3 form-group">
                                            <label className="small mb-1" htmlFor="quoteCurrency">Quote Currency </label>
                                            <select className="form-control form-control-solid form-select" id="quoteCurrency" name="quoteCurrency" value={quoteCurrency} onChange={(e) => setQuoteCurrency(e.target.value)}>
                                                <option>Select Quote Currency</option>
                                                {coinList.length > 0 ? coinList?.map((item, index) => (
                                                    <option key={index} value={item?.short_name}>
                                                        {item?.short_name}
                                                    </option>
                                                )) : undefined}
                                            </select>
                                        </div>

                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1">Price</label>
                                            <input className="form-control form-control-solid" type="text" placeholder="Enter Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)} />
                                        </div>

                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1">Minimum Price</label>
                                            <input className="form-control form-control-solid" type="text" placeholder="Enter Minimum Price" name="minimumPrice" value={minimumPrice} onChange={(e) => setMinimumPrice(e.target.value)} />
                                        </div>

                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1">Maximum Price</label>
                                            <input className="form-control form-control-solid" type="text" placeholder="Enter Maximum Price" name="maximumPrice" value={maximumPrice} onChange={(e) => setMaximumPrice(e.target.value)} />
                                        </div>

                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1">Base Currency Image</label>
                                            <input
                                                className="form-control  form-control-solid"
                                                type="file"
                                                name="coin_image"
                                                onChange={(e) => { setCoinImageBase(e.target.files[0]) }}
                                            />
                                        </div>

                                        <div className="form-group  mb-3" >
                                            <label className="small mb-1">Quote Currency Image</label>
                                            <input
                                                className="form-control  form-control-solid"
                                                type="file"
                                                name="coin_image"
                                                onChange={(e) => { setCoinImageQuote(e.target.files[0]) }}
                                            />
                                        </div>


                                        <button className="btn btn-indigo btn-block w-100 mt-2" type="button" onClick={() => handleAddBuySell(buySellSide, baseCurrency, quoteCurrency, price, minimumPrice, maximumPrice, coinImageBase, coinImageQuote)}> Submit </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12" >
                            <div className="card">
                                <div className="card-header pb-0">
                                    <ul className="nav nav-tabs nav-tabs-custom" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setActiveTab('BUY')}> Buy </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={() => setActiveTab('SELL')}>Sell </button>
                                        </li>
                                    </ul>
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                            Export <CSVLink data={buySellData} />
                                        </button>
                                    </div>
                                </div>
                                <div className={`card-body ${activeTab !== "BUY" ? 'd-none' : ''}`} >
                                    <form className="row" >
                                        <div className="col-12" >
                                            <div className="table-responsive" >
                                                <DataTable columns={columns} data={buySellData} pagination />
                                            </div>
                                        </div>
                                    </form>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default NewBuysell;