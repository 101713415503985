import { ApiCallDelete, ApiCallGet, ApiCallPost, ApiCallPut } from "./ApiCall";
import { ApiConfig } from "./ApiConfig";
const TAG = "AuthService";

const AuthService = {

  login: async ({ email, password }) => {
    const { baseUrl, login } = ApiConfig;
    const url = baseUrl + login;
    const params = {
      email: email,
      password: password,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getTotaluser: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, dashboard } = ApiConfig;
    const url = baseUrl + dashboard;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getSubAdminList: async (skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, getSubAdminList } = ApiConfig;
    const url = `${baseUrl}${getSubAdminList}?skip=${skip}&limit=${limit}&search=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getAdminUserDetails: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, single_subadmin } = ApiConfig;
    const url = `${baseUrl}${single_subadmin}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  AddsubAdmin: async ({ fullName, mobileNumber, email, password, confirmPassword, permissions }) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, AddsubAdmin } = ApiConfig;
    const url = baseUrl + AddsubAdmin;
    const params = {
      full_name: fullName,
      phone: mobileNumber,
      email: email,
      password: password,
      confirm_password: confirmPassword,
      permissions: permissions,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  updateSubAdmin: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, updateSubadmin } = ApiConfig;
    const url = baseUrl + updateSubadmin;
    const params = {
      full_name: formData.userName,
      email: formData.email,
      phone: formData.mobileNumber,
      password: formData.password,
      permissions: formData.permissions,
      id: formData.userId,
      status: formData.status,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  deleteSubAdminList: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, subadmindelete } = ApiConfig;
    const url = `${baseUrl}${subadmindelete}/${userId}`;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  // Subadmin ends


  getAllTickets: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, getAllTickets } = ApiConfig;
    const url = baseUrl + getAllTickets;
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },









  // TradeList starts
  getUsersList: async () => {
    const token = sessionStorage.getItem("token");

    const { baseUrl, users_list } = ApiConfig;
    const url = baseUrl + users_list;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallGet(url, headers);
  },

  changeUsersStatus: async (_id, status) => {
    const token = sessionStorage.getItem("token");

    const { baseUrl, update_user_status } = ApiConfig;
    const url = baseUrl + update_user_status;
    const params = {
      userId: _id,
      status
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPut(url, params, headers);
  },



  // Trade Details


  getuserDetails: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'user-details'}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  transferhistory: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, transferhistory } = ApiConfig;
    const url = baseUrl + transferhistory;
    const params = {
      userId: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  userWallet: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, userWallet } = ApiConfig;
    const url = baseUrl + userWallet;
    const params = {
      userId: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getuserKycDetail: async (skip, limit, searchKey, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, user_list } = ApiConfig;
    const url = `${baseUrl}${user_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getKycDetail: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl } = ApiConfig;
    const url = `${baseUrl}${'kyc-details'}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getKycdataEntity: async (skip, limit, searchKey, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, entity_user_list } = ApiConfig;
    const url = `${baseUrl}${entity_user_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getStepUserData: async (userId, step) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, step_wise_data } = ApiConfig;
    const url = `${baseUrl}${step_wise_data}/${userId}/${step}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getuserAmlCheck: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, aml_details } = ApiConfig;
    const url = `${baseUrl}${aml_details}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getMasterKycData: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, master_kyc_data } = ApiConfig;
    const url = `${baseUrl}${master_kyc_data}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getCurrentKycStatus: async (userId, step) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, kyc_status_user } = ApiConfig;
    const url = `${baseUrl}${kyc_status_user}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  kycVerifyRemark: async (aprovalRemark, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, kycVerify_remark } = ApiConfig;
    const url = `${baseUrl}${kycVerify_remark}/${userId}`;
    const params = {
      remark: aprovalRemark
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getVerifyRemark: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, getVerify_remark } = ApiConfig;
    const url = `${baseUrl}${getVerify_remark}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getBannerList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bannerList } = ApiConfig;
    const url = baseUrl + bannerList;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },












  addNotify: async (notificationTitle, description) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, addNotify } = ApiConfig;
    const url = baseUrl + addNotify;
    const params = {
      title: notificationTitle,
      description: description,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  deleteNotify: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, deleteNotify } = ApiConfig;
    const url = `${baseUrl}${deleteNotify}/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallDelete(url, headers);
  },






  cancelOrder: async (orderID, userID) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, cancelOrder } = ApiConfig;
    const url = baseUrl + cancelOrder;
    const params = {
      order_id: orderID,
      userId: userID,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  getNotificationList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, notificationList } = ApiConfig;
    const url = baseUrl + notificationList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  handleFundDenied: async (id, status, Hash) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, transactionstatus } = ApiConfig;
    const url = baseUrl + transactionstatus;
    const params = {
      id: id,
      status: status,
      transaction_hash: Hash,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },






  addCoinWidthraw: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, setcoinwithdrawal } = ApiConfig;
    const url = baseSecure + setcoinwithdrawal;

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    };

    return ApiCallPut(url, formData, headers);
  },

  CoinCategory: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, CoinCategory } = ApiConfig;
    const url = baseUrl + CoinCategory;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },


  tradingCommission: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, tradingCommission } = ApiConfig;
    const url = baseUrl + tradingCommission;
    const params = {
      skip: skip,
      limit: limit,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },



  AddCoinPair: async (fShortName, fId, sShortName, sId, sellPrice, buyPrice, available) => {
    const token = sessionStorage.getItem("token");
    const { baseCoin, AddCoinPair } = ApiConfig;
    const url = baseCoin + AddCoinPair;
    const params = {
      base_currency: fShortName,
      quote_currency: sShortName,
      base_currency_id: fId,
      quote_currency_id: sId,
      buy_price: buyPrice,
      sell_price: sellPrice,
      available: available
    };



    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  getCurrencyPairList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseCoin, getCurrencyPairList } = ApiConfig;

    const url = baseCoin + getCurrencyPairList;

    const params = {};



    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  deleteCurrency: async (_id, status) => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, deleteCoinPair } = ApiConfig;
    const url = baseSecure + deleteCoinPair;
    const params = {
      _id: _id,
      status: status,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  botStatus: async (_id, status, gap) => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, botStatus } = ApiConfig;
    const url = baseSecure + botStatus;
    const params = {
      _id: _id,
      status: status,
      gap: gap,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  tradeHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, tradeHistory } = ApiConfig;
    const url = `${baseUrl}${tradeHistory}?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  OrderBook: async () => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, OrderBook } = ApiConfig;

    const url = baseExchange + OrderBook;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  tradeById: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseExchange, tradeById } = ApiConfig;

    const url = baseExchange + tradeById;
    const params = {
      "order_id": id
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  completeWithdrawalRequest: async () => {
    const { baseUrl, completeWithdrawalRequest } = ApiConfig;
    const url = baseUrl + completeWithdrawalRequest;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  PendingWithdrwal: async () => {
    const { baseUrl, PendingWithdrwal } = ApiConfig;
    const url = baseUrl + PendingWithdrwal;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  CancelledWithdrwal: async () => {
    const { baseUrl, CancelledWithdrwal } = ApiConfig;
    const url = baseUrl + CancelledWithdrwal;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  completeDepositRequest: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, completeDepositRequest } = ApiConfig;
    const url = baseUrl + completeDepositRequest;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },
  getPendingDeposit: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, pendingDeposits } = ApiConfig;
    const url = baseUrl + pendingDeposits;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  miscellaneousRequest: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, miscellaneousRequest } = ApiConfig;

    const url = baseUrl + miscellaneousRequest;


    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },


  getCoinList: async () => {
    const { baseCoin, getCoinList } = ApiConfig;
    const url = baseCoin + getCoinList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  createCategory: async (name) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, createCategory } = ApiConfig;
    const url = baseUrl + createCategory;
    const params = {
      name: name
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  exportApprovedList: async (dateFrom, dateTo) => {
    const token = sessionStorage.getItem("token");
    const { baseReport, verifiedtrader } = ApiConfig;
    const url = baseReport + verifiedtrader;
    const params = {
      fromDate: dateFrom,
      toDate: dateTo,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  addCoins: async (formData) => {
    const { baseCoin, addNewCoins } = ApiConfig;
    const url = baseCoin + addNewCoins;
    const params = {
    };

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return ApiCallPost(url, formData, headers);
  },



  AddBanner: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, Addbanner } = ApiConfig;
    const url = baseUrl + Addbanner;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  getBannerList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseSecure, bannerList } = ApiConfig;
    const url = baseSecure + bannerList;
    const params = {};

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  updateBannerList: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, updateBanner } = ApiConfig;
    const url = baseUrl + updateBanner;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },

  deletebannerlist: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bannerdelete } = ApiConfig;
    const url = baseUrl + bannerdelete;
    const params = {
      _id: userId,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  handleBannerStatus: async (userId, cell) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, BannerStatus } = ApiConfig;
    const url = baseUrl + BannerStatus;
    const params = {
      _id: userId,
      status: cell,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  updateTicketStatus: async (id, status) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, updateTicketStatus } = ApiConfig;
    const url = baseUrl + updateTicketStatus;
    const params = {
      id, status
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  replyTicket: async (messagerply, id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, replyTicket } = ApiConfig;
    const url = baseUrl + replyTicket;
    const params = {
      message_by: 0,
      message: messagerply,
      ticket_id: id,
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getMessageById: async (ticketId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, single_ticket } = ApiConfig;
    const url = `${baseUrl}${single_ticket}/${ticketId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallGet(url, headers);
  },



  AddQuickyBuySell: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, add_qbs } = ApiConfig;
    const url = baseUrl + add_qbs;

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    };

    return ApiCallPut(url, formData, headers);
  },




  getQbsList: async (side) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, addedqbsHistoy } = ApiConfig;
    const url = `${baseUrl}${addedqbsHistoy}?side=${side}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },

  deleteQbs: async (id) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, deleteQbsList } = ApiConfig;
    const url = `${baseUrl}${deleteQbsList}/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallDelete(url, headers);
  },

  getBuySellAllHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, allqbsHistoy } = ApiConfig;
    const url = `${baseUrl}${allqbsHistoy}?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGet(url, headers);
  },


  getBankList: async (status, skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bank_list } = ApiConfig;
    const url = `${baseUrl}${bank_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getBankUserList: async (accountNumber, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, bank_details } = ApiConfig;
    const url = `${baseUrl}${bank_details}/${userId}/${accountNumber}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  getRejectdRemark: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, get_remarks } = ApiConfig;
    const url = `${baseUrl}${get_remarks}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  verifyBankDetails: async (accountNumber, ifsc, kycName, userId) => {
    const token = sessionStorage.getItem("token");
    const { baseUrlInternal, verify_bank_details } = ApiConfig;
    const url = baseUrlInternal + verify_bank_details;
    const params = {
      accountNumber: accountNumber,
      ifsc: ifsc,
      kycName: kycName,
      userId: userId
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  changeBankStatus: async (status, accountNumber, userId, remarks) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, change_bank_status } = ApiConfig;
    const url = `${baseUrl}${change_bank_status}/${userId}`;
    const params = {
      status: status,
      accountNumber: accountNumber,
      remarks: remarks,
    };
    const headers = {
      "content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  getUpiList: async (status, skip, limit, searchKey) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, upi_list } = ApiConfig;
    const url = `${baseUrl}${upi_list}/${status}?skip=${skip}&limit=${limit}&searchParams=${searchKey}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },



  rejectUserKycStep: async (rejectedType, reasonMessage, rejectedSteps, userId,reasonNew) => {
    const token = sessionStorage.getItem("token");
    const { baseUrl, reject_user_kyc } = ApiConfig;
    const url = `${baseUrl}${reject_user_kyc}/${userId}`;
    const params = {
      type: rejectedType,
      kyc_reject_reason:reasonNew,
      ...(reasonMessage && rejectedType === "reject_all" || "rekyc" ? { arr: [{ reason: reasonMessage }] } : {}),
      ...(rejectedSteps?.length && rejectedType === "partial_reject" ? { arr: rejectedSteps } : {}),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  }



};

export default AuthService;
