const appUrl = "https://dev.api.cryptoindia.in";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "newPassword",
  dashboard: "dashboard",
  getSubAdminList: "all-subadmin",
  single_subadmin: "single-subadmin",
  subadmindelete: "remove-subadmin",
  updateSubadmin: "edit-subadmin",
  AddsubAdmin: "create-subadmin",
  users_list: "user-list",
  update_user_status: 'update-user-status',
  transferhistory: "user-trade-history",
  updateTicketStatus: "update-ticket-status",
  transactionstatus: "update-withdrawal-status",
  getverifyData: "approve-kyc-user",
  getrejectedData: "rejected-kyc-user",
  kycVerify_remark: "add-remarks",
  getVerify_remark: "get-user-remarks",
  coinlist: "coinList",
  addNotify: "add-notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  userWallet: "user-wallet",
  admintransfer: "admintransfer",
  getmasterbal: "getmasterbal",
  setcoinwithdrawal: "edit-currency",
  AddCoinPair: "create-pair",
  createCategory: "create-coin-category",
  getCurrencyPairList: "pair-list",
  completeWithdrawalRequest: "completed-withdrawals",
  PendingWithdrwal: "pending-withdrawals",
  CancelledWithdrwal: "rejected-withdrawals",
  completeDepositRequest: "completed-deposits",
  pendingDeposits: "pending-deposits",
  verifiedtrader: "verifiedtrader",
  addNewCoins: "add-currency",
  Addbanner: "add_banner",
  bannerList: "banner_list",
  updateBanner: "edit_banner",
  bannerdelete: "delete-banner",
  BannerStatus: "set_banner_status",
  deleteCoinPair: "delete-pair",
  getCoinList: "currency-list",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  getAllTickets: 'all-tickets',
  replyTicket: "ticket-admin-reply",
  single_ticket: "single-ticket",
  add_qbs: "add-admin-qbs-limits",
  addedqbsHistoy: "get-admin-qbs-limits",
  deleteQbsList: "delete-admin-qbs-limit",
  allqbsHistoy: "get-all-qbs",
  bank_list: "bank-list",
  upi_list: "get-upi-list",

  bank_details: "all-bank-data",
  verify_bank_details: "verify-bank-details",
  change_bank_status: "change-bank-status",
  get_remarks: "get-remarks",
  user_list:"user-list",
  entity_user_list:"entity-kyc-data",
  step_wise_data: "step-wise-data",
  kyc_status_user:"kyc-status-user",
  aml_details:"aml-details",
  master_kyc_data:"master-kyc-data",


  reject_user_kyc:"reject-user-kyc",
  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/v1/admin/`,
  baseUser: `${appUrl}/v1/user/`,
  baseUrlInternal: `${appUrl}/v1/admin/internal/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  uploadcurrency: `${appUrl}/`,
  appUrl: `${appUrl}/`,
};
