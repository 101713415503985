import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import {
    deleteSubAdminById,
    fetchSubAdminList,
    updateSubAdminStatus,
    getSubAdminColumns,
    handlePagination,
} from "../../../../services/Context/SubadminContext";

const SubAdminList = () => {

    const location = useLocation();
    const [subAdminList, setSubAdminList] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [totalDataLength, setTotalDataLength] = useState(0);
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || "all");


    const [skip, setSkip] = useState(0);
    const navigate = useNavigate();
    const limit = 10;

    const fetchSubAdmins = async (skip, limit, searchKey = "", status = location.state?.activeTab || "all", permissions) => {
        setActiveTab(status);
        try {
            const data = await fetchSubAdminList(skip, limit, searchKey);
            const filteredData = data?.list?.filter(subAdmin => {
                if (status === "all") return true;
                if (status === "Active") return subAdmin.status === "Active" && subAdmin.is_deleted === false;
                if (status === "Inactive") return subAdmin.status === "Inactive" && subAdmin.is_deleted === false;
                if (status === "deleted") return subAdmin.is_deleted === true;
                return true;
            });
            setSubAdminList(filteredData || []);
            setTotalDataLength(data?.totalCount || 0);
        } catch (error) {
            console.error("Error fetching sub-admins:", error);
        }
    };

    const handleDelete = async (userId) => {
        const deleted = await deleteSubAdminById(userId);
        if (deleted) {
            setActiveTab(activeTab);
            fetchSubAdmins(skip, limit, searchKey, activeTab);
        }
        // if (deleted) fetchSubAdmins(skip, limit, searchKey);
    };

    const handleChangeStatus = async (userId, status) => {
        const changeStatus = await updateSubAdminStatus(userId, status);
        if (changeStatus) {
            setActiveTab(activeTab);
            fetchSubAdmins(skip, limit, searchKey, activeTab);
        }
    };


    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };

    const showAlert = (userId, rowData) => {
        Swal.fire({
            title: "Are you sure?",
            html: `Are you sure you want to delete the sub-admin: <b>${rowData?.full_name}</b>?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#e64942",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Yes Delete!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(userId)
            }
        });
    };

    useEffect(() => {
        fetchSubAdmins(skip, limit, searchKey);
    }, [skip, limit, searchKey]);


    const columns = getSubAdminColumns(navigate, handleChangeStatus, showAlert, activeTab);

    const onTabClick = (hhh) => {
        setActiveTab(hhh);
        fetchSubAdmins(0, limit, searchKey, hhh); navigate(location.pathname, { state: {} }); setSearchKey("")
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header ">
                    <div className="container-xl px-4">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="d-flex justify-content-between">
                                    <h1 className="page-header-title">
                                        Admin's List
                                    </h1>
                                    <Link to="/add_sub_admin" className="btn btn-black" > <i className="ri-user-add-line me-2"></i> Add New </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 ">
                    <div className="card mb-4 overflow-hidden">
                        <div className="card-header pb-0">

                            <div className="header_tab_row">
                                <Tabs
                                    className="nav nav-tabs nav-tabs-custom px-2"
                                    activeTab={activeTab}
                                >

                                    <Tab className={`nav-link ${activeTab === "all" ? "rts___tab___selected" : ""}`} eventKey="all" title="all" onTabClick={() => onTabClick("all")}>
                                        All
                                    </Tab>
                                    <Tab className={`nav-link ${activeTab === "Active" ? "rts___tab___selected" : ""}`} eventKey="Active" title="Active" onTabClick={() => onTabClick("Active")}>
                                        Active
                                    </Tab>
                                    <Tab className={`nav-link ${activeTab === "Inactive" ? "rts___tab___selected" : ""}`} eventKey="Inactive" title="Inactive" onTabClick={() => onTabClick("Inactive")}>
                                        Inactive
                                    </Tab>
                                    <Tab className={`nav-link ${activeTab === "deleted" ? "rts___tab___selected" : ""}`} eventKey="deleted" title="deleted" onTabClick={() => onTabClick("deleted")}>
                                        Deleted
                                    </Tab>
                                </Tabs>


                                {/* <div className="form-group" >
                                        <select className="form-control form-select " >
                                            {options?.map((permission) => (
                                                <option onClick={() => { fetchSubAdmins(0, limit, searchKey, permission?.label); navigate(location.pathname, { state: {} }); }}> {permission?.label}</option>
                                            ))}
                                        </select>
                                    </div> */}
                            </div>

                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" type="button" >
                                    <i className="fa fa-download me-2"></i>
                                    <CSVLink data={subAdminList} className="text-white"  filename='subAdmin.csv'>
                                        Export as CSV
                                    </CSVLink>
                                </button>
                            </div>
                        </div>

                        <div className="search-header">
                            {
                                activeTab === "all" &&
                                <div className="dd_search">
                                    <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" onChange={handleSearch} />
                                    <i className="fas fa-search"></i>
                                </div>
                            }
                        </div>

                        <div className="g_start m_card overflow-hidden p-0">
                            <div className="m_table" >
                                <div className="table-responsive h-auto ">
                                    <DataTable
                                        className="table table-hover"
                                        columns={columns}
                                        data={subAdminList}
                                        noDataComponent={
                                            <p> <img src="/assets/img/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                        }
                                    />
                                    {
                                        subAdminList?.length > 0 &&
                                        <div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last', setSkip, skip, limit, totalDataLength)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>


    );
};

export default SubAdminList;
