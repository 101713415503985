import React, { useEffect, useState } from "react";
import LoaderHelper from '../../../CustomComponent/LoaderHelper/LoaderHelper';
import { alertErrorMessage } from "../../../CustomComponent/CustomAlert";
import AuthService from "../../../../services/ApiIntegration/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../../services/ApiIntegration/ApiConfig";
import { initLightboxJS, SlideshowLightbox } from 'lightbox.js-react'

import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import copy from "copy-to-clipboard";

const KycDetailsEntity = () => {

  const [activeTab, setActiveTab] = React.useState(1);
  const [copied, setCopied] = useState(false);

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  const [kycStep, setKycStep] = useState("step1");
  const [errorMessage, setErrorMessage] = useState("");

  const [userKycDetails, setUserKycDetails] = useState([]);
  const [rejectedSteps, setRejectedSteps] = useState([]);
  const [rejectRemarks, setRejectRemarks] = useState("");
  const [showModel, setShowModel] = useState(false);

  const [activeTabLiveness, setActiveTabLiveness] = useState(0);
  const [activeTabPanAttemp, setActiveTabPanAttempt] = useState(0);
  const [activeTabRejectedPan, setActiveTabRejectedPan] = useState(0);
  const [activeTabEducation, setActiveTabEducation] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { row, kycStatus } = location.state || {};

  const fetchUserDetails = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getStepUserData(row?._id, kycStep).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserKycDetails(result?.data)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    }
    );
  };


  useEffect(() => {
    fetchUserDetails()
  }, [row?._id, kycStep])


  const handleClickStep = (step) => {
    setKycStep(step);
  };

  const handleCopy = (item) => {
    copy(item);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const stepNames = {
    step1: "Proof of Identification",
    step2: "Entity Information",
    step3: "Directors List",
    step4: "UBO More Then 10%",
    step5: "Main Trader",
    step6: "Required Documents",
    step7: "Declaration and Submission",
  };

  const storeStepRejectedReason = (rejectRemarks) => {
    if (!rejectRemarks) {
      setErrorMessage("Please enter a reason")
      return;
    }
    const newRemark = {
      kycStep,
      stepName: stepNames[kycStep],
      remark: rejectRemarks,
    };
    setRejectedSteps((prev) => [...prev, newRemark]);
    setShowModel(false);
    setRejectRemarks("");
  };

  const filterSteps = rejectedSteps?.filter(step => step.kycStep == kycStep)[0];

  const selectedAttempt = userKycDetails?.otherPan?.[activeTabPanAttemp];

  const handleTabClick = (index) => {
    setActiveTabPanAttempt(index);
  };


  const rejectedPanHistory = userKycDetails?.otherPan?.filter((item) => item.isDeleted)?.sort((a, b) => new Date(b.date) - new Date(a.date));
  const rejectedPan = rejectedPanHistory?.[activeTabRejectedPan]

  const handleTabClickRejectedPan = (index) => {
    setActiveTabRejectedPan(index);
  };



  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header ">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                      <h1 className="page-header-title">
                        <span className="page-header-icon cursor-pointer" onClick={() => navigate('/entity_kyc', { state: { kycStatus: kycStatus } })}>
                          <i className="fa fa-arrow-left" ></i>
                        </span>

                        <div className="hd_u_info" >
                          <img
                            src={!row?.user_selfie ? "assets/img/illustrations/profiles/profile-5.png" : ApiConfig?.appUrl + row?.user_selfie} alt="" className="img-account-profile rounded-circle"
                            onError={(e) => {
                              e.target.src = "assets/img/illustrations/profiles/profile-5.png";
                            }}
                          />
                          <span>  {row?.name || "-------"}
                            <small>
                              User ID: {row?.userId || "-------"}
                              <i
                                className={`ms-1 link cursor-pointer ${copied ? "ri-check-fill text-success" : "ri-file-copy-fill"
                                  }`}
                                onClick={() => handleCopy(row?.userId)}
                              >
                              </i>
                            </small>
                          </span>
                        </div>
                      </h1>
                    </div>

                    {
                      kycStatus !== "not-submitted" && kycStatus !== "rejected" && kycStatus !== "re-kyc" &&
                      <div className="col-auto">
                        <div className="d-flex">
                          {
                            kycStep === "step7" &&
                            <button className="btn btn-danger btn-block btn-md" type="button" >Reject</button>
                          }
                          {
                            kycStatus !== "approved" && kycStep === "step7" &&
                            <button className="btn mx-2 btn-success btn-block btn-md" type="button">
                              Approve
                            </button>
                          }
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </header>
            <div className="container-xl px-4">
              <ul id="progressbar" className="mb-5">
                <li className={`cursor-pointer ${kycStep === "step1" && "active"}`} id="step1" onClick={() => handleClickStep("step1")}>
                  <strong>Proof of Identification</strong>
                </li>
                <li className={`cursor-pointer ${kycStep === "step2" && "active"}`} id="step2" onClick={() => handleClickStep("step2")}>
                  <strong> Entity Information </strong>
                </li>
                <li className={`cursor-pointer ${kycStep === "step3" && "active"}`} id="step3" onClick={() => handleClickStep("step3")}>
                  <strong> Directors List </strong>
                </li>
                <li className={`cursor-pointer ${kycStep === "step4" && "active"}`} id="step4" onClick={() => handleClickStep("step4")}>
                  <strong> UBO More Then 10% </strong>
                </li>
                <li className={`cursor-pointer ${kycStep === "step5" && "active"}`} id="step5" onClick={() => handleClickStep("step5")}>
                  <strong> Main Trader </strong>
                </li>
                <li className={`cursor-pointer ${kycStep === "step6" && "active"}`} id="step6" onClick={() => handleClickStep("step6")} >
                  <strong> Required Documents </strong>
                </li>
                <li className={`cursor-pointer ${kycStep === "step7" && "active"}`} id="step7" onClick={() => handleClickStep("step7")}>
                  <strong>Declaration and Submission</strong>
                </li>
              </ul>
              <div className="row">
                <div className="col-xl-12 mb-4">

                  {
                    kycStep === "step1" &&
                    <div className="step_1">
                      <div className=" card mb-4 mb-xl-0">
                        <div className=" card-body py-4  ac_body" >

                          <div className="d-flex align-items-center justify-content-between " >
                            <h5> Entity User Information  </h5>
                          </div>
                          <div className="row g-4">
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group">
                                <label>  Registration Date </label>
                                <p>
                                  {row?.createdAt
                                    ? moment(row?.createdAt).format('Do MMMM YYYY')
                                    : "-------"}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group">
                                <label>  Email Address   </label>
                                <p> {row?.email || "-------"} <i className="ri-verified-badge-fill text-success"></i> </p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group">
                                <label> Mobile Number </label>
                                <p> {row?.phone || "-------"} <i className="ri-verified-badge-fill text-success"></i> </p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                              <div className="label-group">
                                <label>  User Id </label>
                                <p>  {row?.userId || "-------"}</p>
                              </div>
                            </div>
                          </div>
                          <hr className="my-4 " />

                          <div className="row gx-4" >
                            <div className="col-lg-9" >
                              <div className="d-flex align-items-center justify-content-between " >
                                <h5>  Proof of Identification</h5>
                              </div>

                              <div className="fc_details" >
                                <h5>Pan Card Details</h5>
                                <div className="row g-4 ">
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Pan Card Number  </label>
                                      <p> {userKycDetails?.latestPan?.pan || "-------"} {" "}
                                        {
                                          userKycDetails?.latestPan?.name &&
                                          <i className={userKycDetails?.latestPan?.verified ? "ri-verified-badge-fill text-success" : "ri-error-warning-fill text-danger"}></i>

                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Full Name     </label>
                                      <p>
                                        {userKycDetails?.latestPan?.name || "-------"} {" "}
                                        {
                                          userKycDetails?.latestPan?.name &&
                                          <i className={userKycDetails?.latestPan?.verified ? "ri-verified-badge-fill text-success" : "ri-error-warning-fill text-danger"}></i>

                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Gender  </label>
                                      <p> {userKycDetails?.latestPan?.gender || "-------"}   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group">
                                      <label> Date of Birth  </label>
                                      <p> {userKycDetails?.latestPan?.dateOfBirth
                                        ? moment(userKycDetails.latestPan?.dateOfBirth).format('Do MMMM YYYY')
                                        : "-------"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Linked Aadhar Number  </label>
                                      <p> {userKycDetails?.latestPan?.maskedAadhaarNumber || "-------"} </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3 me-3">
                                    <div className="label-group">
                                      <label>  Pan Verification date  </label>
                                      <p>{userKycDetails?.latestPan?.updatedAt
                                        ? moment(userKycDetails.latestPan?.updatedAt).format('Do MMMM YYYY,h:mm a')
                                        : "-------"}  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> AML Status  </label>
                                      <p> {userKycDetails?.latestAmlData?.action || "-------"}  </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-3" >
                              <h5> Rejection Remark </h5>
                              <div className="re_row" >

                                {
                                  userKycDetails?.stepRejectedData?.length > 0 ? (
                                    userKycDetails?.stepRejectedData?.map((item, index) => (
                                      <div className="rm_col" key={index}>
                                        <div className="row">
                                          <div className="col-6">
                                            <h5>
                                              <span>{item.admin_name}</span>
                                            </h5>
                                          </div>
                                          <div className="col-6">
                                            <h5 className="text-end">
                                              <small>Date & Time</small>
                                              {item?.timestamp
                                                ? (
                                                  <>
                                                    {moment(item?.timestamp).format('Do MMMM YYYY')}
                                                    <br />
                                                    {moment(item?.timestamp).format('h:mm A')}
                                                  </>
                                                )
                                                : "-------"}
                                            </h5>
                                          </div>
                                        </div>
                                        <p>
                                          <b>Remark : </b>
                                          {item?.reason?.remarks || 'No remark provided.'}
                                        </p>
                                      </div>
                                    ))
                                  ) :
                                    <div className="d-flex justify-content-center align-items-center">
                                      <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                    </div>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="att_row mt-4">
                            <h5> Pan Verification Attempts ({userKycDetails?.otherPan?.length}) </h5>
                            <div className="att_card">
                              <div className="nav nav-tabs nav-tabs-custom px-2">
                                {userKycDetails?.otherPan?.map((attempt, index) => (
                                  <button
                                    key={attempt?._id}
                                    className={`nav-link ${activeTabPanAttemp == index ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => handleTabClick(index)}
                                  >
                                    Attempt {userKycDetails?.otherPan?.length - index}
                                  </button>
                                ))}
                              </div>
                              <div className="att_col">
                                {selectedAttempt ? (
                                  <div className="row g-4">
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Pan Card Number</label>
                                        <p>
                                          {selectedAttempt.pan || "-------"}{' '}
                                          {selectedAttempt.verified ? (
                                            <i className="ri-verified-badge-fill text-success"></i>
                                          ) : (
                                            <i className="ri-error-warning-fill text-danger"></i>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Full Name</label>
                                        <p>{selectedAttempt.name || "-------"}</p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Gender</label>
                                        <p>{selectedAttempt.gender || "-------"}</p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Date of Birth</label>
                                        <p>
                                          {new Date(selectedAttempt.dateOfBirth).toLocaleDateString() || "-------"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Address</label>
                                        <p>
                                          {selectedAttempt.address.line1 ||
                                            selectedAttempt.address.city ||
                                            selectedAttempt.address.state ||
                                            "-------"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Linked Aadhar Number</label>
                                        <p>{selectedAttempt.maskedAadhaarNumber || "-------"}</p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>Pan Verification Date</label>
                                        <p>

                                          {selectedAttempt.createdAt
                                            ? moment(selectedAttempt.createdAt).format('Do MMMM YYYY,h:mm:ss a')
                                            : "-------"
                                          }

                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>AML Status</label>
                                        <p>{selectedAttempt.verified ? 'Verified' : 'Unverified'}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="fc_details d-flex justify-content-center align-items-center">
                                    <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="att_row mt-4">
                            <h5>Rejected History</h5>
                            <div className="att_card">
                              {/* Tabs */}
                              <div className="nav nav-tabs nav-tabs-custom px-2">
                                {rejectedPanHistory?.map((attempt, index) => (
                                  <button
                                    key={index}
                                    className={`nav-link ${activeTabRejectedPan === index ? 'active' : ''}`}
                                    onClick={() => handleTabClickRejectedPan(index)}
                                    type="button"
                                  >
                                    {attempt.createdAt
                                      ? moment(attempt.createdAt).format('Do MMMM YYYY')
                                      : "-------"
                                    }
                                  </button>
                                ))}
                              </div>

                              {/* Selected Tab Details */}
                              <div className="att_col">
                                {rejectedPan ? (
                                  <>
                                    {/* Pan Card Details */}
                                    <div className="fc_details">
                                      <h5>Pan Card Details</h5>
                                      <div className="row g-4">
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Pan Card Number</label>
                                            <p>
                                              {rejectedPan.pan || "-------"}{' '}
                                              {rejectedPan.verified ? (
                                                <i className="ri-verified-badge-fill text-success"></i>
                                              ) : (
                                                <i className="ri-error-warning-fill text-danger"></i>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Full Name</label>
                                            <p>{rejectedPan.name || "-------"}</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Gender</label>
                                            <p>{rejectedPan.gender || "-------"}</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Date of Birth</label>
                                            <p>{new Date(rejectedPan.dateOfBirth).toLocaleDateString() || "-------"}</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Address</label>
                                            <p>{rejectedPan.address?.line1 || "-------"}</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Linked Aadhar Number</label>
                                            <p>{rejectedPan.maskedAadhaarNumber || "-------"}</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>Pan Verification Date</label>
                                            <p>
                                              {rejectedPan.updatedAt
                                                ? moment(rejectedPan.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                                : "-------"
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>AML Status</label>
                                            <p>{rejectedPan.verified ? 'Verified' : 'Unverified'}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="fc_details d-flex justify-content-center align-items-center">
                                    <img src="/assets/img/not_found.svg" className="img-fluid mt-5" width="200" alt="" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step2")}>
                              Next
                            </button>
                            {
                              kycStatus !== "not-submitted" &&
                              kycStatus !== "rejected" &&
                              kycStatus !== "re-kyc" &&
                              filterSteps?.kycStep !== kycStep &&
                              (
                                <button
                                  className="btn btn-md btn-danger px-4"
                                  type="button"
                                  onClick={() => setShowModel(true)}
                                >
                                  Reamrk
                                </button>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    kycStep === "step2" &&
                    <div className="step_2">
                      <div className=" card mb-4 mb-xl-0">
                        <div className="card-body py-4  ac_body " >

                          <div className="d-flex align-items-center justify-content-between mb-4" >
                            <h5 className="mb-0" >  Entity Information </h5>
                            <div className="d-flex align-items-center gap-2" >
                              <button className="btn btn-sm btn-success " >  Fatch Entity Details <i className="ri-checkbox-circle-fill ms-2 fw-normal"></i> </button>
                              <button className="btn btn-sm btn-dark " > Fatch GST Details </button>
                            </div>
                          </div>

                          <div className="row " >
                            <div className="col-lg-6 " >
                              <div className="row g-4">
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label> Entity Name   </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>   Entity Type </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>   Registerd Country </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label> Registration Number   </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>    Date Of Incorporation   </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Website (Optional) </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-12" ><hr className="my-0" /></div>
                                <div className="col-12" >
                                  <h5>  Registerd Address  </h5>
                                  <div className="row" >
                                    <div className="col-lg-6 col-xl-6">
                                      <div className="label-group">
                                        <label>   Address</label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label>   City</label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label>  State </label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label>  Pin Code </label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12" >
                                  <h5>  Opertational Address  </h5>
                                  <div className="row" >
                                    <div className="col-lg-6 col-xl-6">
                                      <div className="label-group">
                                        <label>   Address</label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label>   City</label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label>  State </label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label>  Pin Code </label>
                                        <p> -------- </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12" ><hr className="my-0" /></div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Email id   </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Mobile Number    </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Purpose of Application    </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Annual Trunover (INR)    </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Source of Capital     </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  Total Assets Value (INR)     </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  GST Number      </label>
                                    <p> -------- <i className="ri-checkbox-circle-fill text-success"></i> </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group">
                                    <label>  FIU RE. No     </label>
                                    <p> -------- </p>
                                  </div>
                                </div>
                              </div>

                              {/* GST information fatch */}
                              <div className="fc_details fc_det_en">
                                <h5>  <small>GST Information</small> </h5>
                                <div className="row g-3">
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Entity Name   </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>   Trade Type </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>    GST Status  </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-xl-12">
                                    <div className="label-group">
                                      <label>  Registered Address    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>   Date of GST Registration      </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>  Tax Payer Type     </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>   Nature of Business     </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>   State Jurisdiction Code  </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>

                                  <div className="col-12" ><hr className="my-0" /></div>
                                  <div className="col-12" >
                                    <h5> <small> Additional Place of Business </small> </h5>
                                    <div className="row" >
                                      <div className="col-lg-12 col-xl-12">
                                        <div className="label-group">
                                          <label> Full Address 1 </label>
                                          <p> -------- </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-xl-12">
                                        <div className="label-group">
                                          <label> Full Address 2 </label>
                                          <p> -------- </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="col-lg-6" >
                              <div className="fc_details fc_det_en">
                                <h5>  <small>Entity Information</small> </h5>
                                <div className="row g-3">
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Registration Number   </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Entity Name   </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>   Entity Type </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>    Date Of Incorporation   </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>  Email Id   </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>

                                  <div className="col-12" ><hr className="my-0" /></div>
                                  <div className="col-12" >
                                    <h5> <small>Registerd Address</small> </h5>
                                    <div className="row" >
                                      <div className="col-lg-12 col-xl-12">
                                        <div className="label-group">
                                          <label>   Address</label>
                                          <p> -------- </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12" ><hr className="my-0" /></div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Company Subcategory    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Active Compliance    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>  Whether Listed    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>  Paid Up Capital    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Suspended At Stock Exchange    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label> Date Of Last AGM    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>  ROC Code    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-xl-4">
                                    <div className="label-group">
                                      <label>  Date of Balance Sheet    </label>
                                      <p> -------- </p>
                                    </div>
                                  </div>

                                  <div className="col-12" ><hr className="my-0" /></div>
                                  <div className="col-12" >
                                    <h5 className="mb-2" > <small>  Directors (2) </small> </h5>

                                    <div className="table-responsive m_table">
                                      <table className="table table-hover">
                                        <thead>
                                          <tr>
                                            <th scope="col" className=""> DIN/PND No </th>
                                            <th scope="col" className=""> Name </th>
                                            <th scope="col" className=""> Join Date  </th>
                                            <th scope="col" className=""> Designation  </th>
                                            <th scope="col" className=""> Resign Date </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                            <td className="">
                                              <span> 11-12-2024 </span>
                                            </td>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                            <td className="">
                                              <span> 11-12-2024 </span>
                                            </td>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                            <td className="">
                                              <span> ----  </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                  </div>







                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row lns_row  mt-5" >
                            <div className="col-lg-9" >

                              {/*  Liveness Attempts */}
                              <div className="att_row mt-0" >
                                <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                  <h5 className="mb-0" > Attempts (12) </h5>
                                  <span className="btn btn-icon  "> <i className="ri-arrow-up-s-line"></i> </span>
                                </div>
                                {/* tab */}
                                <div className="att_card " >
                                  <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                    <Tab className="nav-link" > Attempt 12 </Tab>
                                    <Tab className="nav-link" >Attempt 11</Tab>
                                    <Tab className="nav-link" >Attempt 10</Tab>
                                    <Tab className="nav-link" >Attempt 9</Tab>
                                    <Tab className="nav-link" >Attempt 8</Tab>
                                    <Tab className="nav-link" >Attempt 7</Tab>
                                    <Tab className="nav-link" >Attempt 6</Tab>
                                    <Tab className="nav-link" >Attempt 5</Tab>
                                    <Tab className="nav-link" >Attempt 4</Tab>
                                    <Tab className="nav-link" >Attempt 3</Tab>
                                    <Tab className="nav-link" >Attempt 2</Tab>
                                    <Tab className="nav-link" >Attempt 1</Tab>
                                  </Tabs>
                                  <div className="att_col">
                                    <div className="fc_details fc_det_en">
                                      <h5>  <small>Entity Information</small> </h5>
                                      <div className="row g-3">
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label> Entity Name   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>   Entity Type </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>   Registerd Country </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label> Registration Number   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>    Date Of Incorporation   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Website (Optional) </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-12" ><hr className="my-0" /></div>
                                        <div className="col-12" >
                                          <h5> <small>Registerd Address</small> </h5>
                                          <div className="row" >
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group">
                                                <label>   Address</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>   City</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  State </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  Pin Code </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12" >
                                          <h5> <small> Opertational Address </small> </h5>
                                          <div className="row" >
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group">
                                                <label>   Address</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>   City</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  State </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  Pin Code </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12" ><hr className="my-0" /></div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Email id   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Mobile Number    </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Purpose of Application    </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Annual Trunover (INR)    </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Source of Capital     </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Total Assets Value (INR)     </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  GST Number      </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  FIU RE. No     </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Rejected History */}
                              <div className="att_row mt-4" >
                                <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                  <h5 className="mb-0" > Rejected History</h5>
                                  <span className="btn btn-icon  "> <i className="ri-arrow-up-s-line"></i> </span>
                                </div>
                                {/* tab */}
                                <div className="att_card" >

                                  <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                    <Tab className="nav-link" > 12 Jul 2024 </Tab>
                                    <Tab className="nav-link" >10 Nov 2024</Tab>
                                    <Tab className="nav-link" >11 Nov 2024</Tab>
                                    <Tab className="nav-link" >12 Nov 2024</Tab>
                                    <Tab className="nav-link" >13 Nov 2024</Tab>
                                    <Tab className="nav-link" >14 Nov 2024</Tab>
                                    <Tab className="nav-link" >15 Nov 2024</Tab>
                                    <Tab className="nav-link" >16 Nov 202416</Tab>
                                    <Tab className="nav-link" >17 Nov 2024</Tab>
                                  </Tabs>
                                  <div className="att_col " >
                                    <div className="fc_details fc_det_en">
                                      <h5>  <small>Entity Information</small> </h5>
                                      <div className="row g-3">
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label> Entity Name   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>   Entity Type </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>   Registerd Country </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label> Registration Number   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>    Date Of Incorporation   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Website (Optional) </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-12" ><hr className="my-0" /></div>
                                        <div className="col-12" >
                                          <h5> <small>Registerd Address</small> </h5>
                                          <div className="row" >
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group">
                                                <label>   Address</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>   City</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  State </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  Pin Code </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12" >
                                          <h5> <small> Opertational Address </small> </h5>
                                          <div className="row" >
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group">
                                                <label>   Address</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>   City</label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  State </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-2">
                                              <div className="label-group">
                                                <label>  Pin Code </label>
                                                <p> -------- </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12" ><hr className="my-0" /></div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Email id   </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Mobile Number    </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Purpose of Application    </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Annual Trunover (INR)    </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Source of Capital     </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  Total Assets Value (INR)     </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  GST Number      </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group">
                                            <label>  FIU RE. No     </label>
                                            <p> -------- </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Rejection Remark */}
                            <div className="col-lg-3" >
                              <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                <h5 className="mb-0" > Rejection Remark</h5>
                                <span className="btn btn-icon  "> <i className="ri-arrow-up-s-line"></i> </span>
                              </div>
                              {/* tab */}
                              <div className="re_row" >
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>


                              </div>
                            </div>

                          </div>

                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-gray px-4" onClick={() => handleClickStep("step1")}>
                              Prev.
                            </button>
                            <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step3")}>
                              Next
                            </button>
                            {
                              kycStatus !== "not-submitted" &&
                              kycStatus !== "rejected" &&
                              kycStatus !== "re-kyc" &&
                              filterSteps?.kycStep !== kycStep &&
                              (
                                <button
                                  className="btn btn-md btn-danger px-4"
                                  type="button"
                                  onClick={() => setShowModel(true)}
                                >
                                  Reamrk
                                </button>
                              )
                            }
                          </div>


                        </div>
                      </div>
                    </div>
                  }

                  {
                    kycStep === "step3" &&
                    <div className="step_3">
                      <div className=" card mb-4 mb-xl-0">
                        <div className="card-header pb-0" >
                          <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                            <Tab className="nav-link" >  Director 1 </Tab>
                            <Tab className="nav-link" >  Director 2 </Tab>
                            <Tab className="nav-link" >  Director 3 </Tab>
                            <Tab className="nav-link" >  Director 4</Tab>
                            <Tab className="nav-link" >  Director 5</Tab>
                            <Tab className="nav-link" >  Director 6</Tab>
                            <Tab className="nav-link" >  Director 7</Tab>
                          </Tabs>
                        </div>

                        <div className="card-body py-4  ac_body " >

                          <div className="row g-4">
                            <div className="col-lg-6 col-xl-4">
                              <div className="label-group">
                                <label>  Name on Pan Or Aadhar</label>
                                <p>  ----- </p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                              <div className="label-group">
                                <label>  Email Address   </label>
                                <p> {row?.name || row?.email || '-------'} <i className="ri-verified-badge-fill text-success"></i> </p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                              <div className="label-group">
                                <label> Mobile Number </label>
                                <p> {row?.name || row?.phone || '-------'} <i className="ri-verified-badge-fill text-success"></i> </p>
                              </div>
                            </div>
                          </div>
                          <hr className="my-4 " />

                          <div className="row gx-4" >
                            <div className="col-lg-9" >
                              <div className="d-flex align-items-center justify-content-between" >
                                <h5>  Proof of Identification</h5>
                              </div>

                              {/* Pan card details */}
                              <div className="fc_details" >
                                <h5>Pan Card Details</h5>
                                <div className="row g-4 ">
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Pan Card Number  </label>
                                      <p> 00000000000 <i className="ri-error-warning-fill text-danger"></i> </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Full Name     </label>
                                      <p> Unknown User <i className="ri-verified-badge-fill text-success"></i> </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Gender  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group">
                                      <label> Date of Birth  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Address  </label>
                                      <p> ---  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Linked Aadhar Number  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label>  Pan Verification date  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> AML Status  </label>
                                      <p> ---  </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* aadhar card details */}
                              <div className="fc_details mt-2" >
                                <div className="row  align-items-end">
                                  <div className="col-lg-9" >
                                    <div className="d-flex align-items-center justify-content-between " >
                                      <h5> Aadhar Card Details</h5>
                                    </div>
                                    <div className="row g-4 ">
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Masked Aadhar Number  </label>
                                          <p> 00000000000 <i className="ri-error-warning-fill text-danger"></i> </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Full Name     </label>
                                          <p> Unknown User <i className="ri-verified-badge-fill text-success"></i> </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Father's Name  </label>
                                          <p> ---   </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Gender  </label>
                                          <p> ---   </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label> Date of Birth  </label>
                                          <p> ---   </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-6">
                                        <div className="label-group">
                                          <label> Address  </label>
                                          <p> ---  </p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xl-3">
                                        <div className="label-group">
                                          <label>  Aadhar Verification date  </label>
                                          <p> ---   </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3" >
                                    <div className="ud_img">
                                      <div className="img p-1">
                                        <SlideshowLightbox className="ratio ratio-16x9">
                                          <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                        </SlideshowLightbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div className="col-lg-3" >
                              <h5> Rejection Remark </h5>
                              <div className="re_row" >
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>


                              </div>
                            </div>
                          </div>

                          {/* Step action  */}
                          <div className="puk_row " >
                            <button className="btn btn-md btn-gray px-4" type="button">
                              Prev. Director
                            </button>
                            <button className="btn btn-md btn-black px-4" >
                              Next Director
                            </button>
                            <button className="btn btn-md btn-danger px-4" >
                              Reject Director
                            </button>
                          </div>



                          <div className="att_row mt-4" >
                            <h5> Pan Verification Attempts (12) </h5>
                            <div className="att_card" >

                              <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                <Tab className="nav-link" > Attempt 12 </Tab>
                                <Tab className="nav-link" >Attempt 11</Tab>
                                <Tab className="nav-link" >Attempt 10</Tab>
                                <Tab className="nav-link" >Attempt 9</Tab>
                                <Tab className="nav-link" >Attempt 8</Tab>
                                <Tab className="nav-link" >Attempt 7</Tab>
                                <Tab className="nav-link" >Attempt 6</Tab>
                                <Tab className="nav-link" >Attempt 5</Tab>
                                <Tab className="nav-link" >Attempt 4</Tab>
                                <Tab className="nav-link" >Attempt 3</Tab>
                                <Tab className="nav-link" >Attempt 2</Tab>
                                <Tab className="nav-link" >Attempt 1</Tab>
                              </Tabs>
                              <div className="att_col" >
                                <div className="row g-4 ">
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Pan Card Number  </label>
                                      <p> 00000000000 <i className="ri-error-warning-fill text-danger"></i> </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Full Name     </label>
                                      <p> Unknown User <i className="ri-verified-badge-fill text-success"></i> </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Gender  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-2">
                                    <div className="label-group">
                                      <label> Date of Birth  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Address  </label>
                                      <p> ---  </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label> Linked Aadhar Number  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label>  Pan Verification date  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xl-3">
                                    <div className="label-group">
                                      <label>      AML Status  </label>
                                      <p> ---   </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="att_row mt-4" >
                            <h5> Rejected History</h5>
                            <div className="att_card" >

                              <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                <Tab className="nav-link" > 12 Jul 2024 </Tab>
                                <Tab className="nav-link" >10 Nov 2024</Tab>
                                <Tab className="nav-link" >11 Nov 2024</Tab>
                                <Tab className="nav-link" >12 Nov 2024</Tab>
                                <Tab className="nav-link" >13 Nov 2024</Tab>
                                <Tab className="nav-link" >14 Nov 2024</Tab>
                                <Tab className="nav-link" >15 Nov 2024</Tab>
                                <Tab className="nav-link" >16 Nov 202416</Tab>
                                <Tab className="nav-link" >17 Nov 2024</Tab>
                              </Tabs>
                              <div className="att_col" >

                                <div className="fc_details" >
                                  <h5>Pan Card Details</h5>
                                  <div className="row g-4 ">
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Pan Card Number  </label>
                                        <p> 00000000000 <i className="ri-error-warning-fill text-danger"></i> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Full Name     </label>
                                        <p> Unknown User <i className="ri-verified-badge-fill text-success"></i> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Gender  </label>
                                        <p> ---   </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-2">
                                      <div className="label-group">
                                        <label> Date of Birth  </label>
                                        <p> ---   </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Address  </label>
                                        <p> ---  </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label> Linked Aadhar Number  </label>
                                        <p> --- </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>  Pan Verification date  </label>
                                        <p> ---   </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group">
                                        <label>      AML Status  </label>
                                        <p> ---   </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* aadhar card details */}
                                <div className="fc_details mt-2" >
                                  <div className="row  align-items-start">
                                    <div className="col-lg-9" >
                                      <h5> Aadhar Card Details</h5>
                                      <div className="row g-4 ">
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Masked Aadhar Number  </label>
                                            <p> 00000000000 <i className="ri-error-warning-fill text-danger"></i> </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Full Name     </label>
                                            <p> Unknown User <i className="ri-verified-badge-fill text-success"></i> </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Father's Name  </label>
                                            <p> ---   </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Gender  </label>
                                            <p> ---   </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label> Date of Birth  </label>
                                            <p> ---   </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6">
                                          <div className="label-group">
                                            <label> Address  </label>
                                            <p> ---  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                          <div className="label-group">
                                            <label>  Aadhar Verification date  </label>
                                            <p> ---   </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-3" >
                                      <div className="ud_img">
                                        <div className="img p-1">
                                          <SlideshowLightbox className="ratio ratio-16x9">
                                            <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                          </SlideshowLightbox>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          {/* Step action  */}
                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-gray px-4" onClick={() => handleClickStep("step2")}>
                              Prev.
                            </button>
                            <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step4")}>
                              Next
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  }

                  {
                    kycStep === "step4" &&
                    <div className="step_4">
                      <div className=" card mb-4 mb-xl-0">
                        <div className=" card-body py-4  ac_body" >
                          <div className="d-flex align-items-center justify-content-between " >
                            <h5>  UBO More Then 10% (12) </h5>
                          </div>

                          <div className="table-responsive m_table">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th scope="col" className=""> #   </th>
                                  <th scope="col" className=""> Director Name </th>
                                  <th scope="col" className=""> Aadhar Card No. </th>
                                  <th scope="col" className="">  Pan Card No.</th>
                                  <th scope="col" className="">  Mobile No. </th>
                                  <th scope="col" className="">  Email  </th>
                                  <th scope="col" className=""> UBO % </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="">
                                    <span> 1</span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="">
                                    <span> 2</span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                  <td className="">
                                    <span> ---- </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>


                          {/* Step action  */}
                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-gray px-4" onClick={() => handleClickStep("step3")}>
                              Prev.
                            </button>
                            <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step5")}>
                              Next
                            </button>
                            {
                              kycStatus !== "not-submitted" &&
                              kycStatus !== "rejected" &&
                              kycStatus !== "re-kyc" &&
                              filterSteps?.kycStep !== kycStep &&
                              (
                                <button
                                  className="btn btn-md btn-danger px-4"
                                  type="button"
                                  onClick={() => setShowModel(true)}
                                >
                                  Reamrk
                                </button>
                              )
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  }

                  {
                    kycStep === "step5" &&
                    <div className="step_5 ">
                      <div className=" card mb-4 mb-xl-0">
                        <div className=" card-body py-4  ac_body" >
                          <div className="d-flex align-items-center justify-content-between " >
                            <h5>   Main Trader </h5>
                          </div>
                          <div className="fc_details" >
                            <div className="row g-4">
                              <div className="col-lg-6 col-xl-2">
                                <div className="label-group">
                                  <label>    Director Name </label>
                                  <p>   ---- </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-2">
                                <div className="label-group">
                                  <label>    Aadhar Card No.  </label>
                                  <p>   ---- <i className="ri-checkbox-circle-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-2">
                                <div className="label-group">
                                  <label>    Pan Card No.  </label>
                                  <p>   ---- <i className="ri-checkbox-circle-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-2">
                                <div className="label-group">
                                  <label>    	Mobile No  </label>
                                  <p>   ---- <i className="ri-checkbox-circle-fill text-success"></i> </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-2">
                                <div className="label-group">
                                  <label>    	Email  </label>
                                  <p>   ----   </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-2">
                                <div className="label-group">
                                  <label>    	UBO %  </label>
                                  <p>   ---- </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="ud_details">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="ud_img">
                                  <label> Aadhar Card Image </label>
                                  <div className="img">
                                    <SlideshowLightbox className="ratio ratio-16x9">
                                      <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                    </SlideshowLightbox>
                                    <h6>
                                      <small> Update on:  </small>12 Nov. 2024, 12:45 pm
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="ud_img">
                                  <label> Liveness Selfie </label>
                                  <div className="img">
                                    <SlideshowLightbox className="ratio ratio-16x9">
                                      <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                    </SlideshowLightbox>
                                    <h6>
                                      <small> Update on </small> 12 Nov 2024, 12:45 pm
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="fc_details mt-4" >
                              <div className="d-flex align-items-center justify-content-between " >
                                <h5>   Liveness Details  </h5>
                                <div className="label-group d-flex align-items-center">
                                  <label className="me-2" > IP Address: </label>
                                  <p> <small className="d-flex align-items-center" > 192.16.1554.131:30000  <i className="ri-file-copy-fill ms-1 link cursor-pointer"></i>  </small>  </p>
                                </div>
                              </div>
                              <div className="row gx-5 ">
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Live Face Status </label> <p> <small className="text-success" >     Yes  <i className="ri-checkbox-circle-fill fw-normal"></i> </small> </p>
                                  </div>
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                  </div>
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Score   </label> <p> <small> 50% </small> </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Multiple Face Status </label> <p> <small> YES/NO</small> </p>
                                  </div>
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label>   Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                  </div>
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Multiple Score   </label> <p> <small> 50% </small> </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Blur  Status </label> <p> <small> YES/NO</small> </p>
                                  </div>
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Blur  Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                  </div>
                                  <div className="label-group d-flex align-items-center justify-content-between my-1">
                                    <label> Blur Score   </label> <p> <small> 50% </small> </p>
                                  </div>
                                </div>
                              </div>

                              <hr />
                              <h5 className="mb-3" > geo Details</h5>
                              <div className="row g-4 ">
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group  ">
                                    <label> Latitude & Longitude </label>
                                    <p> <small> 5641654654654,5616546521654  </small></p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-2">
                                  <div className="label-group  ">
                                    <label> Country </label>
                                    <p>  <small>------</small>  </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-2">
                                  <div className="label-group  ">
                                    <label> State </label>
                                    <p>  <small>------</small>  </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-2">
                                  <div className="label-group  ">
                                    <label>  City</label>
                                    <p>  <small>------</small>  </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-2">
                                  <div className="label-group  ">
                                    <label>  ISP</label>
                                    <p>  <small>------</small>  </p>
                                  </div>
                                </div>
                              </div>



                            </div>

                            <div className="fc_details mt-4" >
                              <div className="d-flex align-items-center justify-content-between " >
                                <h5>   Face Match Details  </h5>

                              </div>
                              <div className="row gx-5 ">
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group d-flex align-items-center justify-content-between">
                                    <label> Face Match Status </label> <p> <small className="text-success" >   Yes  <i className="ri-checkbox-circle-fill fw-normal"></i>   </small> </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group d-flex align-items-center justify-content-between">
                                    <label>   Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xl-4">
                                  <div className="label-group d-flex align-items-center justify-content-between">
                                    <label>   Score   </label> <p> <small>  50% </small> </p>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>

                          <div className="row lns_row  mt-5" >
                            <div className="col-lg-9" >

                              {/*  Liveness Attempts */}
                              <div className="att_row mt-0" >
                                <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                  <h5 className="mb-0" > Liveness Attempts (12) </h5>
                                  <span className="btn btn-icon  "> <i className="ri-arrow-up-s-line"></i> </span>
                                </div>
                                {/* tab */}
                                <div className="att_card " >
                                  <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                    <Tab className="nav-link" > Attempt 12 </Tab>
                                    <Tab className="nav-link" >Attempt 11</Tab>
                                    <Tab className="nav-link" >Attempt 10</Tab>
                                    <Tab className="nav-link" >Attempt 9</Tab>
                                    <Tab className="nav-link" >Attempt 8</Tab>
                                    <Tab className="nav-link" >Attempt 7</Tab>
                                    <Tab className="nav-link" >Attempt 6</Tab>
                                    <Tab className="nav-link" >Attempt 5</Tab>
                                    <Tab className="nav-link" >Attempt 4</Tab>
                                    <Tab className="nav-link" >Attempt 3</Tab>
                                    <Tab className="nav-link" >Attempt 2</Tab>
                                    <Tab className="nav-link" >Attempt 1</Tab>
                                  </Tabs>
                                  <div className="att_col " >
                                    <div className="fc_details " >
                                      <div className="d-flex align-items-center justify-content-between ">
                                        <h5 className="mb-0" >   Liveness Details  </h5>
                                        <div className="label-group d-flex align-items-center">
                                          <label className="me-2"> IP Address: </label>
                                          <p>
                                            <small className="d-flex align-items-center"> 192.16.1554.131:30000  <i className="ri-file-copy-fill ms-1 link cursor-pointer"></i>  </small>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row g-4 align-items-center">
                                        <div className="col-lg-8" >
                                          <div className="row gx-4 ">
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group my-3">
                                                <label> Live Face Status </label> <p> <small className=" text-success" > Yes <i className="ri-checkbox-circle-fill"></i> </small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Live Face Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Live Face Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group my-3">
                                                <label> Multiple Face Status </label> <p> <small> YES/NO</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label>  Multiple Face Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Multiple Face Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group my-3">
                                                <label> Blur   Status </label> <p> <small> YES/NO</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Blur  Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Blur Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4" >
                                          <div className="ud_img">
                                            <label className="mb-1 text-xs mt-2" > Liveness Selfie </label>
                                            <div className="img p-1">
                                              <SlideshowLightbox className="ratio ratio-16x9">
                                                <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                              </SlideshowLightbox>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                      <h5 className="mb-3" > geo Details</h5>
                                      <div className="row g-4 ">
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group  ">
                                            <label> Latitude & Longitude </label>
                                            <p> <small> 5641654654654,5616546521654  </small></p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label> Country </label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label> State </label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label>  City</label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label>  ISP</label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="fc_details mt-2" >
                                      <div className="row  align-items-start">
                                        <div className="col-lg-8" >
                                          <div className="d-flex align-items-center justify-content-between " >
                                            <h5>   Face Match Details  </h5>
                                          </div>
                                          <div className="row g-4   ">
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group ">
                                                <label> Face Match Status </label> <p> <small className=" text-success" > Yes <i className="ri-checkbox-circle-fill"></i> </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group">
                                                <label> Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group  ">
                                                <label> Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4" >
                                          <div className="ud_img">
                                            <label className="mb-1 text-xs" > Face Match Image (Aadhar) </label>
                                            <div className="img p-1">
                                              <SlideshowLightbox className="ratio ratio-16x9">
                                                <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                              </SlideshowLightbox>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>




                                  </div>
                                </div>
                              </div>

                              {/* Rejected History */}
                              <div className="att_row mt-4" >
                                <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                  <h5 className="mb-0" > Rejected History</h5>
                                  <span className="btn btn-icon  "> <i className="ri-arrow-up-s-line"></i> </span>
                                </div>
                                {/* tab */}
                                <div className="att_card" >

                                  <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                    <Tab className="nav-link" > 12 Jul 2024 </Tab>
                                    <Tab className="nav-link" >10 Nov 2024</Tab>
                                    <Tab className="nav-link" >11 Nov 2024</Tab>
                                    <Tab className="nav-link" >12 Nov 2024</Tab>
                                    <Tab className="nav-link" >13 Nov 2024</Tab>
                                    <Tab className="nav-link" >14 Nov 2024</Tab>
                                    <Tab className="nav-link" >15 Nov 2024</Tab>
                                    <Tab className="nav-link" >16 Nov 202416</Tab>
                                    <Tab className="nav-link" >17 Nov 2024</Tab>
                                  </Tabs>
                                  <div className="att_col " >
                                    <div className="fc_details " >
                                      <div className="d-flex align-items-center justify-content-between ">
                                        <h5 className="mb-0" >   Liveness Details  </h5>
                                        <div className="label-group d-flex align-items-center">
                                          <label className="me-2"> IP Address: </label>
                                          <p>
                                            <small className="d-flex align-items-center"> 192.16.1554.131:30000  <i className="ri-file-copy-fill ms-1 link cursor-pointer"></i>  </small>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row g-4 align-items-center">
                                        <div className="col-lg-8" >
                                          <div className="row gx-4 ">
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group my-3">
                                                <label> Live Face Status </label> <p> <small className=" text-success" > Yes <i className="ri-checkbox-circle-fill"></i> </small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Live Face Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Live Face Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group my-3">
                                                <label> Multiple Face Status </label> <p> <small> YES/NO</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label>  Multiple Face Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Multiple Face Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-4">
                                              <div className="label-group my-3">
                                                <label> Blur   Status </label> <p> <small> YES/NO</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Blur  Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                              <div className="label-group my-3">
                                                <label> Blur Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4" >
                                          <div className="ud_img">
                                            <label className="mb-1 text-xs mt-2" > Liveness Selfie </label>
                                            <div className="img p-1">
                                              <SlideshowLightbox className="ratio ratio-16x9">
                                                <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                              </SlideshowLightbox>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                      <h5 className="mb-3" > geo Details</h5>
                                      <div className="row g-4 ">
                                        <div className="col-lg-6 col-xl-4">
                                          <div className="label-group  ">
                                            <label> Latitude & Longitude </label>
                                            <p> <small> 5641654654654,5616546521654  </small></p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label> Country </label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label> State </label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label>  City</label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-2">
                                          <div className="label-group  ">
                                            <label>  ISP</label>
                                            <p>  <small>------</small>  </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="fc_details mt-2" >
                                      <div className="row  align-items-start">
                                        <div className="col-lg-8" >
                                          <div className="d-flex align-items-center justify-content-between " >
                                            <h5>   Face Match Details  </h5>
                                          </div>
                                          <div className="row g-4   ">
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group ">
                                                <label> Face Match Status </label> <p> <small className=" text-success" > Yes <i className="ri-checkbox-circle-fill"></i> </small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group">
                                                <label> Confidance   </label> <p> <small> High/Medium/Low</small> </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-6">
                                              <div className="label-group  ">
                                                <label> Score   </label> <p> <small> 50% </small> </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4" >
                                          <div className="ud_img">
                                            <label className="mb-1 text-xs" > Face Match Image (Aadhar) </label>
                                            <div className="img p-1">
                                              <SlideshowLightbox className="ratio ratio-16x9">
                                                <img src="assets/img/blank_img.png" className="img-fluid w-100" alt="" />
                                              </SlideshowLightbox>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Rejection Remark */}
                            <div className="col-lg-3" >
                              <div className="d-flex align-items-center justify-content-between manul_accordian cursor-pointer" >
                                <h5 className="mb-0" > Rejection Remark</h5>
                                <span className="btn btn-icon  "> <i className="ri-arrow-up-s-line"></i> </span>
                              </div>
                              {/* tab */}
                              <div className="re_row" >
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>
                                {/* list */}
                                <div className="rm_col" >
                                  <div className="row" >
                                    <div className="col-6" >
                                      <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                    </div>
                                    <div className="col-6" >
                                      <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                    </div>
                                  </div>
                                  <p>
                                    <b>Remark : </b>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                  </p>
                                </div>


                              </div>
                            </div>

                          </div>


                          {/* Step action  */}
                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-gray px-4" onClick={() => handleClickStep("step4")}>
                              Prev.
                            </button>
                            <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step6")}>
                              Next
                            </button>
                            {
                              kycStatus !== "not-submitted" &&
                              kycStatus !== "rejected" &&
                              kycStatus !== "re-kyc" &&
                              filterSteps?.kycStep !== kycStep &&
                              (
                                <button
                                  className="btn btn-md btn-danger px-4"
                                  type="button"
                                  onClick={() => setShowModel(true)}
                                >
                                  Reamrk
                                </button>
                              )
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  }

                  {
                    kycStep === "step6" &&
                    <div className="step_6">
                      <div className=" card mb-4 mb-xl-0">
                        <div className=" card-body py-4  ac_body" >
                          <div className="d-flex align-items-center justify-content-between " >
                            <h5> Required Documents </h5>
                          </div>

                          <div className="custom-tab-2 ">
                            <ul className="nav nav-pills justify-content-start mb-4" role="tablist">
                              <li className="nav-item" role="presentation">
                                <a href="#EntityDocuments" id="EntityDocuments-tab" className="nav-link active" data-bs-toggle="tab" aria-expanded="true">   Entity Documents </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a href="#DirectorsDocuments" id="DirectorsDocuments-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1"> Directors Documents </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a href="#OtherDocuments" id="OtherDocuments-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">   Other Documents </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a href="#adminDocuments" id="adminDocuments-tab" className="nav-link" data-bs-toggle="tab" aria-expanded="false" role="tab" tabindex="-1">   Other Documents </a>
                              </li>
                            </ul>
                          </div>

                          <div className="tab-content">

                            {/* crypto Tab  */}
                            <div className="tab-pane active" id="EntityDocuments" role="tabpanel" aria-labelledby="EntityDocuments-tab">

                              <div className="ac_body">

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >   Certificate of InCorporation (COI) </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                  <hr className="mb-1" />
                                  <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                      <thead>
                                        <tr>
                                          <th scope="col" className=""> Rejected Date </th>
                                          <th scope="col" className=""> Submitted Date </th>
                                          <th scope="col" className=""> Remark </th>
                                          <th scope="col" className=""> Remark By</th>
                                          <th scope="col" className=""> Action </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="">
                                            <span> ----- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View Doc </button> </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="">
                                            <span> ----- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View Doc </button> </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Memorandum of Association (MOA)  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >Articles of association (AOA)  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Bank Statement  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >Source Of Capital  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > AML / CFT Policy  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Letter of Authorization (LOA) <small> <a href="#" className="btn-link link-primary" > Download Sample Form  </a></small>  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Sanctions Questionnaires    <small> <a href="#" className="btn-link link-primary" > Download Sample Form  </a></small> </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >INR / Crypto Assets Declaration  <small> <a href="#" className="btn-link link-primary" > Download Sample Form  </a></small> </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Video Declaration  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-dark" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>

                            {/* crypto Tab  */}
                            <div className="tab-pane" id="DirectorsDocuments" role="tabpanel" aria-labelledby="DirectorsDocuments-tab">

                              <div className="ac_body">

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >  <small className="sm_label" >Director 1</small> <br /> Unknown Name </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>

                                  <div className="row gx-5 mt-3 ">
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Pan Card </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Director' Photo </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Aadhar Front </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Aadhar Back </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                  </div>



                                  <hr className="mb-2" />
                                  <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                      <thead>
                                        <tr>
                                          <th scope="col" className=""> Rejected Date </th>
                                          <th scope="col" className=""> Submitted Date </th>
                                          <th scope="col" className=""> Remark </th>
                                          <th scope="col" className=""> Remark By</th>
                                          <th scope="col" className=""> Pan Card </th>
                                          <th scope="col" className=""> Aadhar Front </th>
                                          <th scope="col" className=""> Aadhar Back </th>
                                          <th scope="col" className="">   Photo </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>

                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View  </button> </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View  </button> </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View  </button> </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View  </button> </span>
                                          </td>
                                        </tr>


                                      </tbody>
                                    </table>
                                  </div>

                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >  <small className="sm_label" >Director 1</small> <br /> Unknown Name </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>

                                  <div className="row gx-5 mt-3 ">
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Pan Card </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Director' Photo </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Aadhar Front </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-3">
                                      <div className="label-group d-flex align-items-center justify-content-between">
                                        <label> Aadhar Back </label> <p>  <button className="btn btn-xs btn-primary" > Preview </button> </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                            {/* crypto Tab  */}
                            <div className="tab-pane" id="OtherDocuments" role="tabpanel" aria-labelledby="OtherDocuments-tab">

                              <div className="ac_body">
                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >  Other Document 1 </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                  <hr className="mb-1" />
                                  <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                      <thead>
                                        <tr>
                                          <th scope="col" className=""> Rejected Date </th>
                                          <th scope="col" className=""> Submitted Date </th>
                                          <th scope="col" className=""> Remark </th>
                                          <th scope="col" className=""> Remark By</th>
                                          <th scope="col" className=""> Action </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="">
                                            <span> ----- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View Doc </button> </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>



                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Other Document 2  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                      <button className="btn btn-md btn-danger" >
                                        Reject Document
                                      </button>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>

                            {/* crypto Tab  */}
                            <div className="tab-pane" id="adminDocuments" role="tabpanel" aria-labelledby="adminDocuments-tab">


                              <div className="ac_body">


                                <div className="   ">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Upload Document   </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-warning" >
                                        Upload Document
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <hr className="mb-5" />


                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" >  Admin Document 1 </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                    </div>
                                  </div>
                                  <hr className="mb-1" />
                                  <div className="table-responsive m_table">
                                    <table className="table table-hover">
                                      <thead>
                                        <tr>
                                          <th scope="col" className=""> Submitted Date </th>
                                          <th scope="col" className=""> Remark </th>
                                          <th scope="col" className=""> Remark By</th>
                                          <th scope="col" className=""> Action </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> ---- </span>
                                          </td>
                                          <td className="">
                                            <span> <button className="btn btn-xs btn-dark " > View Doc </button> </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>



                                </div>

                                <div className="fc_details p-3  mb-2">
                                  <div className="d-flex align-items-center justify-content-between " >
                                    <h5 className="mb-0" > Other Document 2  </h5>
                                    <div className="d-flex align-items-center gap-2" >
                                      <button className="btn btn-md btn-primary" >
                                        View Document
                                      </button>
                                    </div>
                                  </div>
                                </div>



                              </div>
                            </div>

                          </div>



                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-gray px-4" onClick={() => handleClickStep("step5")}>
                              Prev.
                            </button>
                            <button className="btn btn-md btn-black px-4" type="button" onClick={() => handleClickStep("step7")}>
                              Next
                            </button>
                            {
                              kycStatus !== "not-submitted" &&
                              kycStatus !== "rejected" &&
                              kycStatus !== "re-kyc" &&
                              filterSteps?.kycStep !== kycStep &&
                              (
                                <button
                                  className="btn btn-md btn-danger px-4"
                                  type="button"
                                  onClick={() => setShowModel(true)}
                                >
                                  Reamrk
                                </button>
                              )
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  }

                  {
                    kycStep === "step7" &&
                    <div className="step_7">
                      <div className=" card mb-4 mb-xl-0">
                        <div className=" card-body py-4  ac_body" >

                          <div className="profile_data w-100  ac_body px-4 pt-4 " >
                            <h4 className=" " > <small>Declaration of Source of Funds </small>  </h4>
                            <ul className="desc mb-3">
                              <p className="" > <small>In submitting and returning the above information/documents, I fully understand and confirm for and on behalf of the Company that:</small></p>
                              <li className="mb-2">I have full power and authority to open the Binance Entity Account and to enter into the associated terms and conditions and representations for and on behalf of the applicant company.</li>
                              <li className="mb-2">I have read, understood, and agreed to be bound by the applicable terms and conditions as published on Binance, including but not limited to the terms and conditions and the Privacy Policy.</li>
                              <li className="mb-2">The information provided in this Entity Account Application is to the best of my/our knowledge true, accurate, and complete.</li>
                              <li>I will notify Binance, or where applicable its affiliates, of any changes to the information provided to Binance promptly, but no later than 7 business days following the occurrence of such changes.</li>
                            </ul>

                            <p> <small>Declared on: &nbsp; <b className="text-dark" > 12/12/2024, 2:28:05 PM  </b> <i className="ri-checkbox-circle-fill text-success"></i> </small>  </p>




                            <div className="row hns_row_two  mt-5" >
                              <div className="col-lg-6">

                                <div className="att_row mt-0" >
                                  <h5> Rejected History</h5>
                                  <div className="att_card" >

                                    <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                      <Tab className="nav-link" > 12 Jul 2024 </Tab>
                                      <Tab className="nav-link" >10 Nov 2024</Tab>
                                      <Tab className="nav-link" >11 Nov 2024</Tab>
                                      <Tab className="nav-link" >12 Nov 2024</Tab>
                                      <Tab className="nav-link" >13 Nov 2024</Tab>
                                      <Tab className="nav-link" >14 Nov 2024</Tab>
                                      <Tab className="nav-link" >15 Nov 2024</Tab>
                                      <Tab className="nav-link" >16 Nov 202416</Tab>
                                      <Tab className="nav-link" >17 Nov 2024</Tab>
                                    </Tabs>
                                    <div className="att_col" >
                                      <div className="row g-4 align-items-center">
                                        <div className="col-lg-6 col-xl-6">
                                          <div className="label-group">
                                            <label> Declared Date & Time </label> <p> <small> ----- </small> </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6">
                                          <div className="label-group">
                                            <label> Full Name   </label> <p> <small> -----  </small> </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6">
                                          <div className="label-group">
                                            <label>    Aadhar Number  </label> <p> <small> -----  </small> </p>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6">
                                          <div className="label-group">
                                            <label>  Pan Number   </label> <p> <small> -----  </small> </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="col-lg-3" >
                                <h5> Rejection Remark </h5>
                                <div className="re_row" >
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>


                                </div>
                              </div>

                              <div className="col-lg-3" >
                                <h5> Approved Remark </h5>
                                <div className="re_row ap_row" >
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                  {/* list */}
                                  <div className="rm_col" >
                                    <div className="row" >
                                      <div className="col-6" >
                                        <h5> <span> Unknown Admin <small>#12354534</small> </span> </h5>
                                      </div>
                                      <div className="col-6" >
                                        <h5 className="text-end" ><small>Date & Time</small> 12 Dec 2024, 11:12 AM </h5>
                                      </div>
                                    </div>
                                    <p>
                                      <b>Remark : </b>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Step action  */}
                          <div className="puk_row puk_row_fixed" >
                            <button className="btn btn-md btn-gray px-4" onClick={() => handleClickStep("step6")}>
                              Prev.
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>

      {showModel && (
        <>
          <div className="modal show d-block" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content pb-3">
                <div className="modal-header">
                  <h5 className="modal-title" > Are you sure you want to reject the {kycStep} details?</h5>
                </div>
                <div className="modal-body ">
                  <div className="form-group mb-4 ">
                    <label className="small mb-1"> Enter Reason/Message </label>
                    <textarea className="form-control py-3" rows="4" placeholder="Enter your message.." name="rejectRemarks" value={rejectRemarks}
                      onChange={(e) => {
                        setRejectRemarks(e.target.value);
                        setErrorMessage("");
                      }}>
                    </textarea>
                    {errorMessage && (
                      <div className="text-danger mt-2 small">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <button className="btn btn-danger  btn-block w-100" type="button" onClick={() => storeStepRejectedReason(rejectRemarks)}>
                  <span> Submit </span>
                </button>
                <button className="btn btn-secondry  btn-block w-100 mt-1" type="button" onClick={() => setShowModel(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}

    </>

  );
};

export default KycDetailsEntity;
