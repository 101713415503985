import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/ApiIntegration/AuthService";
import { alertErrorMessage } from "../../CustomComponent/CustomAlert";


const Dashboard = () => {

    const [dashboardData, setDashboardData] = useState([]);

    const dashboardAPI = async () => {
        try {
            const result = await AuthService.getTotaluser();
            if (result.success) {
                setDashboardData(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error.message);
        }
    };

    useEffect(() => {
        dashboardAPI();
    }, []);

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header">
                    <div className="container-fluid px-4">
                        <div className="page-header-content ">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto ">
                                    <h1 className="page-header-title">
                                        Dashboard
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-fluid px-4">
                    <div className="row g-3 g-lg-4">

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="/trade_list" className="dhs_cards bg_card_1 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-group-2-line"></i>  All Users </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span>Individual</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                        <div className="bc_div" >
                                            <span>Corporate</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a> 
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_2 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-shield-user-line"></i>  Individual KYC   </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span> Pending</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                        <div className="bc_div" >
                                            <span>  Not Submitted</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_3 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-briefcase-4-fill"></i>  Corporate   KYC </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span>Pending</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                        <div className="bc_div" >
                                            <span>  Not Submitted</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_4 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-wallet-3-fill"></i>   Main Balance </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span> Available </span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                        <div className="bc_div" >
                                            <span>Locked </span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_5 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-money-rupee-circle-fill"></i>  INR Wallet   </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span> Total Deposit</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                        <div className="bc_div" >
                                            <span>  Total Withdraw</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_6 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-arrow-up-line"></i>  Total Deposits    </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span>Total INR Deposits</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div> 
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_7 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-arrow-down-line"></i>    Total Withdrawals </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span>Total INR Withdrawals</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div> 
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-xl-3 ">
                            <a href="#" className="dhs_cards bg_card_8 text-white h-100" >
                                <div className="c_left" >
                                    <div className="c_top" >
                                        <span> <i className="ri-arrow-up-down-line"></i>  Total INR Buy Sell Requests </span>
                                        <h5>{dashboardData?.total_traders || 0}</h5>
                                    </div>
                                    <div className="c_bottom" >
                                        <div className="bc_div" >
                                            <span>All Sell Orders</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                        <div className="bc_div" >
                                            <span>  Pending Buy Orders</span>
                                            <p className="mb-0" > 3,654 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="c_right" >
                                    <img src="/assets/img/demo_chart.svg" />
                                </div>
                            </a>
                        </div> 

                        <div className="col-lg-4 col-xl-4 ">
                            <div className="card ds_card h-100" >
                                <div className="card-header">
                                    <h5>  User Support</h5>
                                    <a href="/support" className="btn btn-link" > View all <i className="ri-arrow-right-s-line"></i> </a>
                                </div>
                                <div className="card-body" >
                                    <div className="us_row" >
                                        <a href="#" className="us_col" >
                                            <p> Level 1 Open Tickets <span className="badge bg-danger-light" >1 New</span> </p>
                                            <strong>12</strong>
                                        </a>
                                        <a href="#" className="us_col" >
                                            <p>Level 2 Open Tickets</p>
                                            <strong>12</strong>
                                        </a>
                                        <a href="#" className="us_col" >
                                            <p>Level 3 Open Tickets</p>
                                            <strong>2,000</strong>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-xl-4 ">
                            <div className="card ds_card h-100" >
                                <div className="card-header">
                                    <h5>    Market Maker Program</h5>
                                    <a href="#" className="btn btn-link" > View all <i className="ri-arrow-right-s-line"></i> </a>
                                </div>
                                <div className="card-body table_body" >
                                    <table className="table" >
                                        <thead className="" >
                                            <tr>
                                                <th>
                                                    Name
                                                </th>
                                                <th>
                                                    Ac Type
                                                </th>
                                                <th className="text-end" >
                                                    Preferred Trading Pair
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>  Unknown User  </td>
                                                <td>   Individual   </td>
                                                <td className="text-end" >      Bitcoin  </td>
                                            </tr>
                                            <tr>
                                                <td>  Unknown User  </td>
                                                <td>   Individual   </td>
                                                <td className="text-end" >      Bitcoin  </td>
                                            </tr>
                                            <tr>
                                                <td>  Unknown User  </td>
                                                <td>   Individual   </td>
                                                <td className="text-end" >      Bitcoin  </td>
                                            </tr>
                                            <tr>
                                                <td>  Unknown User  </td>
                                                <td>   Individual   </td>
                                                <td className="text-end" >      Bitcoin  </td>
                                            </tr>
                                            <tr>
                                                <td>  Unknown User  </td>
                                                <td>   Individual   </td>
                                                <td className="text-end" >      Bitcoin  </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-xl-4 ">
                            <div className="row g-3 g-lg-4">
                                <div className="col-lg-12 ">
                                    <div className="card ds_card hover_card h-100" >
                                        <div className="card-body" >
                                            <div className="cl_row" >
                                                <div className="cl_data">
                                                    <span> Coin Listing Requests </span>
                                                    <h5>50,0000</h5>
                                                    <a href="#" className="btn btn-link mt-4"> View all <i className="ri-arrow-right-s-line"></i> </a>
                                                </div> 
                                                <img src="/assets/img/coin_listing.svg" className="img-fluid" alt="" /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 ">
                                    <div className="card ds_card hover_card h-100" >
                                        <div className="card-body" >
                                            <div className="cl_row" > 
                                                <div className="cl_data">
                                                    <span>  Partnership Requests </span>
                                                    <h5>2,000</h5>
                                                    <a href="#" className="btn btn-link mt-4"> View all <i className="ri-arrow-right-s-line"></i> </a>
                                                </div> 
                                                <img src="/assets/img/partnership.svg" className="img-fluid" alt="" /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </div >
    )
}
export default Dashboard;