import React from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const UserWallets = () => {



    const [activeTab, setActiveTab] = React.useState(1);

    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <form className="form-data" >
                        <header className="page-header ">
                            <div className="container-xl px-4">
                                <div className="page-header-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h1 className="page-header-title">
                                                User Wallets
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4" >
                                    <div className="row g-4">
                                        <div className="col-lg-6 col-xl-3">
                                            <div className="label-group ow_row">
                                                <label>  Estimated Balance </label>
                                                <h5 className=""> 0000.000 </h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                            <div className="label-group ow_row">
                                                <label>     Total Locked Balance </label>
                                                <h5 className=""> 0000.000 </h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                            <div className="label-group ow_row">
                                                <label>     Total Hold Balance </label>
                                                <h5 className=""> 0000.000 </h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-3">
                                            <div className="label-group ow_row">
                                                <label>     Total Available Balance </label>
                                                <h5 className=""> 0000.000 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card" >
                                    <div className="card-header pb-0 ps-0">
                                        <Tabs className="nav nav-tabs nav-tabs-custom px-2" activeTab={activeTab} onTabClick={onTabClick} >
                                            <Tab className="nav-link" >   Overview  </Tab>
                                            <Tab className="nav-link" > Funding   </Tab>
                                            <Tab className="nav-link" >    Spot Wallet  </Tab>
                                            <Tab className="nav-link" >   Future Wallet </Tab>
                                        </Tabs>
                                    </div>

                                    <div className="card-body" >
                                        <div>
                                            <div className="filter_bar search-header px-0 g-2"> 
                                                <div className="dd_search">
                                                    <input className="" id="inputLastName" type="search" placeholder="Search here..." name="search" />
                                                    <i className="fas fa-search"></i>
                                                </div>

                                            </div>
                                            <div className="table-responsive m_table">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Coin Name</th>
                                                            <th scope="col" className="text-end">Available Balance</th>
                                                            <th scope="col" className="text-end">Locked Balance</th>
                                                            <th scope="col" className="text-end">Hold Balance</th>
                                                            <th scope="col" className="text-end">Total Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="c_view">
                                                                    <img src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" className="img-fluid" alt="" />
                                                                    <span>USDT <br />
                                                                        <small>Tether</small>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="c_view justify-content-end">
                                                                    <span>0.00000 <br />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="c_view justify-content-end">
                                                                    <span>0.00000 <br />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <span> 0.00000 </span>
                                                            </td>
                                                            <td className="text-end">
                                                                <span> 0.00 </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="c_view">
                                                                    <img src="assets/img/nocoin.png" className="img-fluid" alt="" />
                                                                    <span>INR <br />
                                                                        <small>INR</small>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="c_view justify-content-end">
                                                                    <span>0.00000 <br />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="c_view justify-content-end">
                                                                    <span>0.00000 <br />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <span> 0.00000 </span>
                                                            </td>
                                                            <td className="text-end">
                                                                <span> 0.00 </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>





                            </div>
                        </header>

                    </form>
                </main>
            </div>
        </>

    )


}

export default UserWallets;