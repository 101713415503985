import moment from "moment";
import AuthService from "../../ApiIntegration/AuthService";
import { alertErrorMessage } from "../../../Components/CustomComponent/CustomAlert";

export const fetchSubAdminList = async (skip, limit, searchKey) => {
  try {
    const result = await AuthService.getSubAdminList(skip, limit, searchKey);
    if (result?.success) {
      return result?.data;
    } else {
      alertErrorMessage(result?.message);
      return [];
    }
  } catch (error) {
    alertErrorMessage(error?.message);
    return [];
  }
};

export const updateSubAdminStatus = async (userId, status) => {
  const formData = {
    userId,
    status
  };
  try {
    const result = await AuthService.updateSubAdmin(formData);
    if (result?.success) {
      return true;
    } else {
      alertErrorMessage(result?.message);
      return false;
    }
  } catch (error) {
    alertErrorMessage(error?.message);
    return false;
  }
};

export const deleteSubAdminById = async (userId) => {
  try {
    const result = await AuthService.deleteSubAdminList(userId);
    if (result?.success) {
      return true;
    } else {
      alertErrorMessage(result.message);
      return false;
    }
  } catch (error) {
    alertErrorMessage(error?.message);
    return false;
  }
};

export const handleChange = (e, formData, setFormData, setError, setApiResponse) => {
  if (e && e.target) {
    const { name, value } = e.target;

    if (name === "mobileNumber" && /[^0-9]/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  } else {
    setFormData({ ...formData, permissions: e || [] });
    setError((prevErrors) => ({
      ...prevErrors,
      permissions: '',
    }));
  }
  setApiResponse("");
};


export const validationAddSubAdmin = (formData) => {
  if (!formData.fullName) {
    return { field: 'fullName', message: 'Full Name is required' };
  }

  if (!formData.mobileNumber) {
    return { field: 'mobileNumber', message: 'Mobile Number is required' };
  } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
    return { field: 'mobileNumber', message: 'Invalid Mobile Number. It should be 10 digits' };
  }

  if (!formData.email) {
    return { field: 'email', message: 'Email is required' };
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
    return { field: 'email', message: 'Invalid email address' };
  }

  if (!formData.password) {
    return { field: 'password', message: 'Password is required' };
  }

  if (!formData.confirmPassword) {
    return { field: 'confirmPassword', message: 'Confirm Password is required' };
  } else if (formData.confirmPassword !== formData.password) {
    return { field: 'confirmPassword', message: 'Passwords do not match' };
  }

  if (!formData.permissions.length) {
    return { field: 'permissions', message: 'At least one permission must be selected' };
  }
  return null;
};

export const handleAddSubAdmin = async (formData, setError, navigate, setApiResponse,setIsLoading) => {
  const validationError = validationAddSubAdmin(formData);
  if (validationError) {
    setError({ [validationError.field]: validationError.message });
    return;
  }
  try {
  setIsLoading(true);
    const result = await AuthService.AddsubAdmin(formData);
    if (result?.success) {
      navigate('/sub_admin_list');
    } else {
      setApiResponse(result || 'An unexpected error occurred.');
    }
  } catch (error) {
    setApiResponse(error?.message || 'An unexpected error occurred.');
  } finally {
    setIsLoading(false);
  }
};

export const handleUpdateSubAdmin = async (navigate, formData, setApiResponse, setIsLoading) => {
  setIsLoading(true);
  try {
    const filteredFormData = { ...formData };
    if (!filteredFormData.password) {
      delete filteredFormData.password;
    }
    const result = await AuthService.updateSubAdmin(filteredFormData);
    setIsLoading(false);
    if (result?.success) {
      navigate('/sub_admin_list')
    } else {
      setApiResponse(result);
    }
  } catch (error) {
    setApiResponse(error?.message);
  }
  finally { setIsLoading(false) };
};

export const fetchSubAdminDetails = async (userId) => {
  try {
    const result = await AuthService.getAdminUserDetails(userId);
    if (result?.success) {
      return result?.data;
    } else {
      alertErrorMessage(result?.message);
      return [];
    }
  } catch (error) {
    alertErrorMessage(error?.message);
    return [];
  }
};
export const handleChangeEditSubAdmin = (e, formData, setFormData, setApiResponse) => {
  if (e && e.target) {
    const { name, value } = e.target;
    if (name === "mobileNumber" && /[^0-9]/.test(value)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  } else {
    setFormData({
      ...formData,
      permissions: e || [],
    });
  }
  setApiResponse("");
};

export const getSubAdminColumns = (navigate, handleChangeStatus, showAlert, activeTab) => [
  {
    name: "Date/Time",
    cell: (row) => (
      <div className="justify-content-start">
        <span>
          {moment(row.createdAt).format("DD/MM/YYYY")} <br />
          <small>{moment(row.createdAt).format("HH:mm:ss")}</small>
        </span>
      </div>
    ),
    sortable: false,
  },
  {
    name: "User ID",
    selector: (row) => row._id,
    wrap: true,
    sortable: true,
  },
  {
    name: "User Name",
    selector: (row) => row.full_name,
    wrap: true,
    sortable: true,
  },
  {
    name: "Mobile Number",
    selector: (row) => row.phone,
    wrap: true,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    wrap: true,
    sortable: true,
  },

  ...(activeTab !== "deleted" && activeTab !== "all"
    ? [
      {
        name: "Status",
        cell: (row) => (
          <button
            className={`btn btn-sm ${row?.status === "Active" ? "btn-success" : "btn-danger"}`}
            style={{ marginLeft: "20px" }}
            onClick={() =>
              handleChangeStatus(row?._id, row?.status === "Inactive" ? "Active" : "Inactive")
            }
          >
            {row?.status === "Active" ? "Active" : "Inactive"}
          </button>
        ),
      },
    ]
    : []),

  {
    name: "Action",
    cell: (row) => (
      <>
        <button
          className="btn btn-dark btn-sm me-2"
          title="Edit"
          onClick={() => navigate('/view_sub_admin', { state: { row, activeTab } })}
        >
          View
        </button>
        {activeTab !== "deleted" && activeTab !== "all" && (
          <button
            className="btn btn-danger btn-sm"
            title="Delete"
            onClick={() => showAlert(row?._id, row)}
          >
            <i className="ri-delete-bin-line"></i>
          </button>
        )}
      </>
    ),
  },
];


export const handlePagination = (action, setSkip, skip, limit, totalDataLength) => {
  switch (action) {
    case "prev":
      if (skip - limit >= 0) setSkip(skip - limit);
      break;
    case "next":
      if (skip + limit < totalDataLength) setSkip(skip + limit);
      break;
    case "first":
      setSkip(0);
      break;
    case "last":
      setSkip(Math.max(totalDataLength - limit, 0));
      break;
    default:
      break;
  }
};

export const options = [
  { value: 1, label: 'Users' },
  { value: 2, label: 'KYC Manager' },
  { value: 3, label: 'Currency Management' },
  { value: 4, label: 'Currency Pair Management' },
  { value: 5, label: 'Fund Deposit Management' },
  { value: 6, label: 'Fund Withdrawal Management' },
  { value: 7, label: "Exchange Profit" },
  { value: 8, label: "Trading Report" },
  { value: 9, label: 'OrderBook' },
  { value: 10, label: 'Support' },
  { value: 11, label: 'Notification Management' },
  { value: 12, label: 'Banner Management' },
];