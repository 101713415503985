import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { fetchSubAdminDetails } from '../../../../services/Context/SubadminContext';

const ViewSubadmin = () => {

  const location = useLocation()
  const navigate = useNavigate();
  const { row, activeTab } = location.state || {};
  const [isActive, setIsActive] = useState('All');
  const [selectedOption, setSelectedOption] = useState('');
  const [subAdminDetails, setSubAdminDetails] = useState("");

  const handleSelectChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    setIsActive(selected);
  };

  const fetchSubAdmins = async () => {
    const data = await fetchSubAdminDetails(row?._id);
    setSubAdminDetails(data);
  };

  useEffect(() => {
    fetchSubAdmins();
  }, []);

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header ">
          <div className="container-xl px-4">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h1 className="page-header-title">

                    <span className="page-header-icon cursor-pointer" onClick={() => navigate('/sub_admin_list', { state: { activeTab: activeTab } })}>
                      <i className="fa fa-arrow-left"></i>
                    </span>

                    Admin Details
                  </h1>
                </div>
                <div className="col-auto">
                  <button type='button' className="btn btn-black" onClick={() => navigate('/edit_sub_admin', { state: subAdminDetails })}>
                    <i className="ri-edit-line me-2"></i> Edit Admin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4">
          <div className='row' >
            <div className='col-lg-4' >
              <div className="card mb-4">
                <div className='card-header' >
                  <h5 className='mb-0' >Admin Details</h5>
                </div>
                <div className="card-body row justify-content-center py-4 py-lg-5">
                  <form className='col-lg-12' >
                    <div className="row gy-3 d-flex justify-content-evenly">
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputFirstName">User Id</label>
                        <input className="form-control  form-control-solid" Value={subAdminDetails?._id} disabled />
                      </div>
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputFirstName">Full Name</label>
                        <input className="form-control  form-control-solid" Value={subAdminDetails?.full_name} disabled />
                      </div>
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputNumber">Mobile Number</label>
                        <input className="form-control form-control-solid" Value={subAdminDetails?.phone} disabled />
                      </div>

                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                        <input className="form-control form-control-solid" Value={subAdminDetails?.email} disabled />
                      </div>
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputEmailAddress">Created Date</label>
                        <input className="form-control form-control-solid" Value={moment(subAdminDetails.createdAt).format('Do MMMM YYYY, h:mm:ss a')} disabled />
                      </div>
                      <div className="col-md-12">
                        <label className="small mb-1" htmlFor="inputLocation">Permissions</label>
                        <div className='permissions_row'>
                          {subAdminDetails?.permissions?.map((permission) => (
                            <span key={permission} className='badge badge-lg bg-green-lite'>
                              <i className="ri-check-line me-2"></i> {permission?.label}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className="card mb-4">
                <div className='card-header'>
                  <h5 className='mb-0'> Admin Activity </h5>
                </div>
                <div className="card-body py-4">
                  <div className='custom-tab-1'>
                    <ul className="nav nav-pills justify-content-start mb-4" role="tablist">

                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          className={`nav-link ${isActive === 'All' ? 'active' : ''}`}
                          onClick={() => setIsActive('All')}
                        >
                          All
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          className={`nav-link ${isActive === 'KYC Management' ? 'active' : ''}`}
                          onClick={() => setIsActive('KYC Management')}
                        >
                          KYC Management
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          className={`nav-link ${isActive === 'Currency Management' ? 'active' : ''}`}
                          onClick={() => setIsActive('Currency Management')}
                        >
                          Currency Management
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          type="button"
                          className={`nav-link ${isActive === 'Currency Pair' ? 'active' : ''}`}
                          onClick={() => setIsActive('Currency Pair')}
                        >
                          Currency Pair
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <div className="form-group">
                          <select
                            className="form-control form-select"
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            <option value="">More...</option>
                            <option value="Fund Deposit">Fund Deposit</option>
                            <option value="Fund Withdraw">Fund Withdraw</option>
                            <option value="Accounting">Accounting</option>
                            <option value="Orderbook">Orderbook</option>
                            <option value="User Support L.1">User Support L.1</option>
                            <option value="Nodal Officer">Nodal Officer</option>
                            <option value="CEO Desk">CEO Desk</option>
                          </select>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-responsive-md">
                      <thead>
                        <tr>
                          <th><strong>#</strong></th>
                          <th><strong>PATIENT</strong></th>
                          <th><strong>DR NAME</strong></th>
                          <th><strong>DATE</strong></th>
                          <th><strong>STATUS</strong></th>
                          <th><strong>PRICE</strong></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><strong>01</strong></td>
                          <td>Mr. Bobby</td>
                          <td>Dr. Jackson</td>
                          <td>01 August 2020</td>
                          <td><span className="badge light badge-success">Successful</span></td>
                          <td>$21.56</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ViewSubadmin