import React from "react";

const MarketMakerProgram = () => {
    return (
        <div id="layoutSidenav_content">
            <main>
                <form className="form-data" >
                    <header className="page-header ">
                        <div className="container-xl px-4">
                            <div className="page-header-content">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <h1 className="page-header-title">
                                        MarketMakerProgram
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                </form>
            </main>
        </div>
    )
}

export default MarketMakerProgram;